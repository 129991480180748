.list {
  display: grid;
  grid-template-columns: 1fr;
  transition: opacity 175ms ease;
  opacity: 1;

  &--hidden {
    opacity: 0;
  }

  &-item {
    text-align: left;
    padding: 12px 32px 12px 12px;
    position: relative;

    &:nth-child(even) {
      background-color: #f2f2f2;
    }

    &__remove {
      width: 14px;
      height: 14px;
      background-image: url('~@img/svg/delete.svg');
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      display: inline-block;
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      opacity: 0;
      pointer-events: none;
      transition: opacity 175ms ease;
    }

    &:hover {
      .list-item__remove {
        opacity: 1;
        pointer-events: all;
      }
    }
  }
}
