.sub-nav {
  position: fixed;
  left: $navigation-width;
  z-index: 1000;
  width: 400px;
  box-shadow: 4px 0px 6px 0px rgba(0, 0, 0, 0.2);

  > .nav-list {
    left: 0px;
  }

  &__inner {
    height: 100vh;
    background-color: $brand-white;
  }

  &__submit {
    width: 100%;
    height: 50px;
    position: absolute;
    bottom: 0px;
    background-color: $brand-primary;
    color: $brand-white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &:disabled {
      cursor: not-allowed;
      background-color: #f199a4;
    }

    &-text {
      @include font-title();
      text-transform: uppercase;
      font-size: 14px;
    }
  }
}
