.alignment-modal {
  position: relative;

  .alignment-overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    display: block;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 100;
  }

  .alignment-grid {
    display: block;
    position: fixed;
    left: 50%;
    top: 50%;
    height: auto;
    transform: translate(-50%, -50%);
    z-index: 101;

    &-inner {
      display: flex;
      background-color: $brand-white;
      padding: 20px;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      max-width: 850px;
      min-width: 600px;
      width: 100%;
      box-shadow: 0px 0px 1px 1px $brand-white;
      border-radius: 2px;
      justify-content: center;

      .demo-section {
        display: flex;
        flex-direction: column;
        width: 100%;

        .alignment-demo {
          margin: 15px;
          padding: 15px;
          display: flex;
          flex-direction: column;
          background-color: $brand-grey--near-white;
          user-select: none;
          transition: background-color 0.2s ease-in-out;
          max-height: 500px;

          &:hover {
            background-color: #ebe9e9;
          }

          span {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 15px;
            padding: 10px 0px;
            cursor: pointer;

            svg {
              width: 1.25em;
              height: 1.25em;
            }

            h3 {
              text-align: center;
              padding: 10px 0px;
              font-size: 18px;
            }
          }

          &--open {
            .alignment-content {
              overflow: visible !important;
              opacity: 1 !important;
            }
          }

          .alignment-content {
            height: 0px;
            overflow: hidden;
            margin: auto;
            text-align: center;
            display: flex;
            gap: 10px;
            align-items: center;
            transition: height 0.5s ease-in-out, opacity 0.9s ease-in-out;
            opacity: 0;

            p {
              flex-basis: 50%;
            }

            .alignment-content-image {
              height: 300px;
              flex-basis: 50%;
              align-items: center;
              display: flex;

              img {
                border: 1px solid $brand-grey--light;
                border-radius: 5px;
                display: inline-block;
              }
            }
          }
        }
      }
    }

    &-close {
      position: absolute;
      top: 10px;
      right: 10px;
      display: block;
      z-index: 102;
      width: 15px;
      height: 15px;
      background-image: url('~img/svg/plus--black.svg');
      transform: rotate(45deg);
      background-position: center center;
      background-size: cover;
      cursor: pointer;
    }
  }
}
