.flag {
  display: block;
  height: 25px;
  background: url('~img/flags.png') 0 0 no-repeat;
  background-size: 36px;
  width: 36px;
  min-width: 36px;

  &.cs-CZ {
    background-position: 0 -30px;
  }

  &.da-DK {
    background-position: 0 -60px;
  }

  &.de-DE {
    background-position: 0 -90px;
  }

  &.en-GB {
    background-position: 0 -120px;
  }

  &.es-ES {
    background-position: 0 -150px;
  }

  &.fi-FI {
    background-position: 0 -180px;
  }

  &.fr-FR {
    background-position: 0 -210px;
  }

  &.hu-HU {
    background-position: 0 -240px;
  }

  &.it-IT {
    background-position: 0 -270px;
  }

  &.nl-NL {
    background-position: 0 -300px;
  }

  &.nn-NO {
    background-position: 0 -330px;
  }

  &.pl-PL {
    background-position: 0 -360px;
  }

  &.ru-RU {
    background-position: 0 -390px;
  }

  &.sv-SE {
    background-position: 0 -420px;
  }

  &.be-BE,
  &.nl-BE,
  &.fr-BE {
    background-position: 0 -450px;
  }

  &.lt-LT {
    background-position: 0 -480px;
  }

  &.lv-LV {
    background-position: 0 -510px;
  }

  &.et-EE {
    background-position: 0 -540px;
  }

  &.de-CH {
    background-position: 0 -569px;
  }

  &.ar-AE {
    background-position: 0 -599px;
  }

  &.en-ZA {
    background-position: 0 -629px;
  }

  &.pt-PT {
    background-position: 0 -659px;
  }

  &.sk-SK {
    background-position: 0px -688px;
  }

  &.tr-TR {
    background-position: 0 -721px;
  }

  &.de-AT {
    background-position: 0 -750px;
  }

  &.fr-LU {
    background-position: 0 -780px;
  }

  &.ro-RO {
    background-position: 0 -810px;
  }
}
