.nav-list {
  min-width: 375px;
  max-width: 425px;
  height: auto;
  max-height: calc(100vh - #{$navigation-logo-height} - #{$navigation-logo-height});
  overflow-y: auto;
  overflow-x: hidden;
  background-color: $brand-grey--dark;
  color: $brand-white;
  position: absolute;
  left: 200px;
  top: 0px;

  &__header {
    padding: 20px 8px 8px 8px;

    h5 {
      margin-bottom: 20px;
    }
  }

  ul {
    margin: 0;
    padding: 5px;
    list-style: none;

    li {
      @include font-sub-title();
      font-size: 12px;
      padding: 20px 40px 20px 20px;
      position: relative;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      cursor: pointer;
      user-select: none;

      &.has-icon {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      &:hover {
        background-color: $brand-black;
      }

      .icon {
        margin-right: 15px;
        display: inline-block;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
      }

      .icon--inline {
        display: block;
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        margin-right: 0px;
      }

      .icon--remove {
        background-image: url('~@img/svg/remove.svg');
        width: 14px;
        height: 14px;
      }

      .icon--plus {
        background-image: url('~@img/svg/plus.svg');
        width: 18px;
        height: 18px;
      }

      .icon--search {
        background-image: url('~@img/svg/search.svg');
        width: 20px;
        height: 20px;
      }

      .icon--page {
        background-image: url('~@img/svg/page.svg');
        width: 24px;
        height: 24px;
      }

      .icon--bars {
        background-image: url('~@img/svg/icon--bars.svg');
        width: 24px;
        height: 24px;
      }

      .icon--custom-bars {
        background-image: url('~@img/svg/icon--custom-bars.svg');
        width: 24px;
        height: 24px;
      }

      .icon--placeholder {
        background-image: url('~@img/svg/icon--aspect-ratio.svg');
        width: 24px;
        height: 24px;
      }

      .icon--chevron {
        background-image: url('~@img/svg/chevron.svg');
        width: 16px;
        height: 16px;
      }

      .icon--back {
        background-image: url('~@img/svg/chevron.svg');
        width: 16px;
        height: 16px;
        transform: rotate(180deg);
      }
    }
  }
}
