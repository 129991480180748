.accordion {
  border-top: 1px solid $brand-grey--light;

  &:last-of-type {
    margin-bottom: 20px;
    border-bottom: 1px solid $brand-grey--light;
  }

  &__title {
    position: relative;
    @include font-header();
    font-size: 16px;
    padding-right: 32px;
    color: $brand-black;
    padding: 16px 0;
    text-transform: uppercase;
    cursor: pointer;
    transition: opacity 150ms ease-in-out;
    user-select: none;

    .accordion--active & {
      color: $brand-primary;
      transition: none;
    }

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      right: 16px;
      transform: translateY(-50%);
      width: 12px;
      height: 6px;
      background: url('~img/svg/caret.svg') no-repeat center;

      .accordion--active & {
        background: url('~img/svg/caret--red.svg') no-repeat center;
      }
    }
  }

  &__content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 300ms ease-in-out;

    .accordion--active & {
      max-height: 5000px;
    }

    .form__section:first-child {
      padding-top: 24px;
    }

    .form__title {
      @include font-header();
      font-size: 12px;
      text-transform: uppercase;
      padding: 0 21px;
      margin: 0;
    }
  }

  &:not(.accordion--active):hover .accordion__title {
    opacity: 0.6;
  }
}
