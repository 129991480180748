@import './settings/settings.variables';
@import './settings/settings.fonts';

@import './components/component-form';
@import './components/component-loader';
@import './components/component-btn';

.passport-main {
  padding: 0px;
  width: 100%;
  background-color: $brand-grey;
  overflow-x: hidden;
  min-height: 100vh;

  &--full-screen {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.passport-site-wide {
  max-width: $site-wide;
  margin: 0 auto;
  padding: 0 25px;
}

.align--center {
  text-align: center;
}
