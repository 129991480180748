.product-search {
  height: 100vh;
  position: relative;

  &__header {
    background: $brand-grey--dark;
    padding: 10px;
    height: 61px;
    display: flex;

    input {
      border-radius: 4px;
      width: 95%;
      padding: 12px;
      @include font-sub-title;
      font-size: 14px;
      color: $brand-black;
      border: none;
      outline: none;
    }
  }

  &__utils {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 10px;

    label {
      display: block;
    }
  }

  &__util {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: $brand-white;
  }

  &__message {
    padding: 20px;
    word-break: break-all;
  }

  &__items {
    background-color: $brand-white;
    height: calc(100% - 61px - 50px);
    overflow-y: auto;
    overflow-x: hidden;
  }

  &__item {
    text-align: left;
    padding: 20px;
    background-color: $brand-grey--near-white;
    border-bottom: 2px solid $brand-white;
    user-select: none;
    cursor: pointer;
    display: flex;
    align-items: center;

    &:hover,
    &.active {
      background-color: $brand-grey--light;
    }

    h2 {
      @include font-sub-title;
      font-size: 12px;
      margin-bottom: 3px;
      overflow: hidden;
      text-overflow: ellipsis;
      text-transform: uppercase;
      font-style: normal;
    }

    h5 {
      color: $brand-primary;
    }

    div.product-search__details {
      flex-basis: 70%;
    }

    div.product-search__quantity {
      flex-basis: 30%;
    }
  }
}
