.pdf-cell {
  position: relative;
  grid-column: span 1;
  grid-row: span 1;
  cursor: grab;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1;
  font-size: 8px;
  perspective: 400px;
  transform-style: preserve-3d;

  &__bg {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
    background-image: url('~@img/svg/logo.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 0;

    &--border {
      border: 1px solid white;
    }
  }

  &--bar {
    background: none;
  }

  &--placeholder {
    background-color: rgba(60, 60, 60, 0.2) !important;
  }

  &__corners {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    span {
      position: absolute;
      background-image: url('~@img/svg/icon--corner.svg');

      width: 20px;
      height: 20px;

      &:nth-child(1) {
        top: 0;
        left: 0;
      }
      &:nth-child(2) {
        top: 0;
        right: 0;
        transform: rotate(90deg);
      }
      &:nth-child(3) {
        bottom: 0;
        right: 0;
        transform: rotate(180deg);
      }
      &:nth-child(4) {
        bottom: 0;
        left: 0;
        transform: rotate(-90deg);
      }
    }
  }

  &.level--0 {
    border: none;
  }
}
