.tabs {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-top: 40px;
  margin-bottom: 40px;
  border-bottom: 1px solid rgba($brand-black, 0.1);

  .tab {
    @include font-sub-title();
    font-size: 14px;
    margin: 0px 40px;
    padding: 10px 0px;
    position: relative;

    &.active:after {
      content: '';
      width: 100%;
      position: absolute;
      bottom: -1px;
      height: 1px;
      background-color: $brand-black;
    }
  }

  &__title {
    text-align: center;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
}
