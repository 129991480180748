.project-list {
  display: grid;
  grid-template-columns: repeat(5, minmax(100px, 1fr));
  grid-gap: 20px;
  margin-bottom: 40px;

  a {
    text-decoration: none;
    color: inherit;
  }

  &__loader {
    position: relative;
    height: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.project-item {
  text-align: left;
  align-self: flex-start;
  cursor: pointer;

  &__img-wrapper {
    background-color: rgba($brand-black, 0.05);
    height: 0;
    padding-bottom: 100%;
    position: relative;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  &--square .project-item__img-wrapper {
    height: 0;
    padding-bottom: 100%;
  }

  &__placeholder,
  &__img {
    width: 60px;
    height: 60px;
    background-image: url('~@img/svg/logo.svg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    background-color: $brand-primary;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__img {
    width: 100%;
    height: 100%;
    background-color: transparent;
  }

  &__new {
    background-color: rgba($brand-black, 0.05);
    height: 0;
    padding-bottom: 100%;
    position: relative;
    cursor: pointer;

    &-inner {
      width: 150px;
      min-height: 60px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &-text {
      @include font-title();
      font-size: 14px;
      text-align: center;
    }

    &-plus {
      width: 20px;
      height: 20px;
      margin: 0 auto 5px auto;
      background-image: url('~@img/svg/plus--black.svg');
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  &__details {
    padding: 10px 0px;
  }

  &__title {
    @include font-title();
    font-size: 14px;
    color: $brand-black;
    margin-bottom: 5px;
    margin-top: 0px;
    text-transform: unset;
  }

  &__date {
    margin-bottom: 10px;
  }

  &__remove {
    @include font-sub-title();
    color: $brand-primary;
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
  }
  &__remove-icon {
    width: 14px;
    height: 14px;
    background-image: url('~@img/svg/delete.svg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    margin-right: 7px;
  }
}
