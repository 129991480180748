.customer-details {
  padding-top: cm(0.6);

  &__split {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: cm(0.2);
  }

  &__half {
    width: 50%;
  }

  &__title {
    margin-bottom: 0 !important;
  }

  &__order {
    margin-bottom: cm(0.12) !important;
  }

  &__number {
    @include font-sub-title();
    font-size: 9px;
    color: $brand-grey--medium;
  }

  .customer__label {
    @include font-header();
    font-size: 9px;
    text-transform: uppercase;
    margin-right: cm(0.1);
    line-height: 9px;
  }

  .customer__value {
    @include font-sub-title();
    font-size: 9px;
    color: $brand-grey--medium;
    line-height: 9px;
  }

  li {
    margin-bottom: cm(0.2);
  }
}

.product-item {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  padding-bottom: cm(0.3);

  &__article {
    @include font-sub-title();
    margin-right: cm(0.05);
    font-size: 9px;
    line-height: cm(0.12);
    color: $brand-grey--medium;
  }

  &__name {
    @include font-header();
    font-size: 9px;
    text-transform: uppercase;
    margin-bottom: cm(0.1);
    line-height: cm(0.12);
  }

  &__quantity {
    @include font-sub-title();
    font-size: 9px;
    color: $brand-grey--medium;
  }
}
