.grid-bar {
  &__modal {
    position: fixed;
    top: 0;
    left: $navigation-width;
    width: calc(100% - #{$navigation-width});
    height: 100vh;
    z-index: 999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.4);
    }
  }

  &__form {
    background-color: $brand-white;
    padding: 20px;

    input[type='text'] {
      width: 380px;
    }

    &--split {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
    }

    &-half {
      width: 50%;
    }
  }

  &__inner {
    display: inline-block;
    position: relative;
  }

  &__tabs {
    position: absolute;
    top: 80px;
    left: 100%;
  }

  &__tab {
    @include font-sub-title;
    width: 36px;
    height: 36px;
    background: $brand-primary--dark;
    color: $brand-white;

    &.active {
      background: $brand-primary;
    }
  }

  &__footer {
    background-color: $brand-white;
    position: relative;
    margin-top: -5px;
    padding: 5px;
    text-align: center;

    .form-errors {
      margin-bottom: 10px;
      color: $brand-primary;
    }

    .btn {
      margin-left: 20px;
    }

    .btn:nth-child(1) {
      margin-left: 0px;
    }
  }

  .grid {
    display: block;

    &__wrapper {
      text-align: center;
    }

    &__container {
      border: 1px solid $brand-primary;
    }
  }
}
