.bay {
  &__header {
    height: 200px;
    background-color: $brand-primary;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;

    &--shadow {
      box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.64);
    }

    img {
      width: 300px;
      position: relative;
      display: block;
    }
  }

  &__footer {
    height: 100px;
    background-color: $brand-primary;
    display: flex;
    justify-content: center;
    align-items: center;

    &--shadow {
      box-shadow: 0px -3px 4px 0px rgba(0, 0, 0, 0.64);
    }

    img {
      max-width: 500px;
    }
  }

  &__border {
    border-right: 4px dashed rgba($brand-black, 0.28);
    border-left: 4px dashed rgba($brand-black, 0.28);
  }

  &__border-inner {
    background-color: $brand-primary--dark;
    padding: 5px;
  }
}
