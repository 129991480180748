form {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  padding: 40px 25px;
  background-color: $brand-white;

  &.no-padding {
    padding: 0;
  }

  &.max-300 {
    max-width: 300px;
  }

  &.min-400 {
    min-width: 400px;
  }

  &.loader-container {
    min-width: 300px;
    min-height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &.form__border {
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.5);
  }

  .form__section {
    margin-bottom: 36px;
  }

  .form__title {
    margin-bottom: 10px;
    text-align: center;
    width: 100%;

    &--left {
      text-align: left;
    }
  }

  .form__row {
    padding: 5px 0px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    margin-bottom: 15px;
    position: relative;
  }

  .form__split {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .form__row {
      width: calc(50% - (25px / 2));
    }
  }

  .form__product {
    display: flex;
    padding: 21px;

    &:nth-child(odd) {
      background: #f8f8f8;
    }

    .form__product-name {
      flex: 1 0 calc(100% - 125px);
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .form__product-number {
      flex: 0 0 125px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;

      &--label {
        justify-content: center;
        padding-left: 22px;
      }
    }

    .form__product-quantity {
      text-align: center;
      width: 100%;
    }
  }

  .form__bold {
    @include font-header();
    font-size: 12px;
  }

  .form__sub {
    margin: 5px 0px;
    font-size: 12px;
    color: $brand-grey--medium;
  }

  .form__row-wrapper {
    margin-bottom: 15px;
  }

  .form__footer {
    text-align: center;
    margin-top: 20px;

    .btn {
      margin-left: 40px;

      &:first-child {
        margin-left: 0px;
      }
    }
  }

  label {
    @include font-sub-title();
    position: absolute;
    left: 8px;
    top: 10px;
    display: block;
    margin: 0;
    font-size: 11px;
    line-height: 20px;
    color: $brand-grey--dark;
    transition: opacity 0.3s ease, transform 0.3s ease;
    z-index: 1;
    user-select: none;
    opacity: 0;
    transform: translateY(0);

    &.is-active {
      opacity: 1;
      transform: translateY(-100%);
    }

    &.no-anim {
      opacity: 1;
      transform: none;
    }
  }

  input[type='checkbox'] {
    display: none;

    + label {
      @include font-title();
      position: relative;
      top: 0;
      left: 0;
      opacity: 1;
      padding-left: 28px;
      color: $brand-black;
      margin-bottom: 6px;

      &:before {
        cursor: pointer;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 18px;
        height: 18px;
        border: 1px solid $brand-grey--dark;
        border-radius: 2px;
      }
    }

    &:checked + label:before {
      background: url('~img/svg/tick.svg') no-repeat center;
      background-size: 14px 14px;
    }
  }

  input[type='text']:not(:placeholder-shown) + label,
  textarea:not(:placeholder-shown) + label {
    opacity: 1;
    transform: translateY(-100%);
  }

  input[type='text'],
  textarea {
    display: block;
    height: 45px;
    width: 100%;
    padding: 10px 8px 9px;
    border: none;
    background-color: transparent;
    border-bottom: 1px solid $brand-black;
    appearance: none;
    border-radius: 0;
    font-size: 14px;
    color: $brand-black;
    transition: opacity 0.3s ease;
    position: relative;
    z-index: 2;
    @include font-title();

    &::placeholder {
      color: $brand-grey--medium;
    }

    &:focus {
      border-bottom: 1px solid $brand-black;
    }

    &:-webkit-autofill {
      transition: background-color 5000s ease-in-out 0s;
      -webkit-text-fill-color: $brand-black;
    }
  }

  textarea {
    max-width: 100%;
    min-width: 100%;
    // border-radius: 4px;
    // padding: 12px;
    // @include font-sub-title;
    // font-size: 14px;
    // color: $brand-black;
    // border: none;
    // background-color: rgba($brand-black, 0.1);
    // resize: vertical;
    // width: 600px;
    // height: 200px;
  }

  label .btn-group {
    margin-left: 8px;
  }

  .btn-group {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: nowrap;

    &__btn {
      padding: 5px 10px;
      background-color: $brand-primary--dark;
      color: $brand-white;

      &.active {
        background-color: $brand-primary;
      }
    }
  }
}

.form {
  &__inventory {
    max-width: 600px;
    margin: 0 auto;

    &-section {
      margin-bottom: 40px;

      h3 + p {
        margin-top: -10px;
      }
    }
  }

  &__left {
    .form__title {
      text-align: left;
    }
  }
}
