// Preferred Headline
@mixin font-header() {
  font-family: 'Helvetica Neue LT W01_93 BlkEx', Helvetica, sans-serif;
  @include culture-fallbacks();
}

@mixin font-header-heavy() {
  font-family: 'Helvetica Neue LT W05_85 Heavy', Helvetica, sans-serif;
  @include culture-fallbacks();
}

// Preferred Sub-Headline
@mixin font-title() {
  font-family: 'Helvetica Neue LT W05_63 Md Ex', Helvetica, sans-serif;
  @include culture-fallbacks();
}

@mixin font-sub-title() {
  font-family: 'Helvetica Neue LT W05_53 Ext', Helvetica, sans-serif;
  @include culture-fallbacks();
}

// Preferred Small Body
@mixin font-normal() {
  font-family: 'Helvetica Neue LT W01_55 Roman', Helvetica, sans-serif;
  @include culture-fallbacks();
}

@mixin culture-fallbacks() {
  .ru-ru &,
  .pl-pl &,
  .hu-hu &,
  .cs-cz &,
  .et-ee &,
  .lv-lv &,
  .lt-lt &,
  [lang^='ru'] &,
  [lang^='pl'] &,
  [lang^='hu'] &,
  [lang^='cs'] &,
  [lang^='et'] &,
  [lang^='lv'] &,
  [lang^='lt'] & {
    font-weight: bold;
    font-family: 'Arimo', sans-serif !important;

    strong {
      font-weight: bold;
      font-family: 'Arimo', sans-serif !important;
    }
    b {
      font-weight: bold;
      font-family: 'Arimo', sans-serif !important;
    }
  }
}

h1 {
  @include font-header();
  margin-top: 0;
  text-transform: uppercase;
  font-size: 40px;
  font-style: italic;
}

h3,
h4,
h5 {
  @include font-header-heavy();
  margin-top: 0;
  margin-bottom: 0;
}

h2 {
  @include font-title();
  font-size: 32px;
}

p,
body {
  @include font-normal();
}

.align--left {
  text-align: left;
}

.align--right {
  text-align: right;
}

.align--center {
  text-align: center;
}

p.error {
  color: $brand-primary;
}
