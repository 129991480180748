.language-selector {
  position: relative;

  .current-language {
    cursor: pointer;
  }

  .language-overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    display: block;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 100;
  }

  .language-grid {
    display: block;
    position: fixed;
    left: 50%;
    top: 50%;
    height: auto;
    transform: translate(-50%, -50%);
    z-index: 101;

    &-inner {
      display: flex;
      background-color: $brand-white;
      padding: 20px;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      width: 600px;
      box-shadow: 0px 0px 1px 1px $brand-white;
      border-radius: 2px;
    }

    &-item {
      width: 50%;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      padding: 20px;
      cursor: pointer;

      &:hover .flag-title {
        color: $brand-primary;
      }
    }

    &-close {
      position: absolute;
      top: 10px;
      right: 10px;
      display: block;
      z-index: 102;
      width: 15px;
      height: 15px;
      background-image: url('~img/svg/plus--black.svg');
      transform: rotate(45deg);
      background-position: center center;
      background-size: cover;
      cursor: pointer;
    }
  }

  .flag-title {
    @include font-normal();
    display: block;
    font-size: 14px;
    text-align: left;
    padding: 0px 10px;
    color: $brand-black;
  }
}
