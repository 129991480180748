.navigation {
  width: $navigation-width;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: $brand-grey--dark;
  display: flex;
  flex-direction: column;
  z-index: 1000;

  &-logo {
    width: 100%;
    height: 60px;
    background-color: $brand-primary;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: nowrap;

    img {
      height: 30px;
      width: 70px;
      margin: 0 auto;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    padding-top: 5px;
    position: relative;

    &--bottom {
      margin-top: auto;
    }
  }

  &__flag {
    width: 100%;
    height: 50px;
    // margin: 0 auto;
    cursor: pointer;
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 10px;

    &:hover {
      button > p {
        opacity: 1;
      }
    }

    button {
      width: 100%;

      p {
        transition: opacity 375ms ease;
        opacity: 0.3;
      }
    }

    p {
      width: 100%;
      padding-left: 50px;
      text-align: left;
      color: $brand-white;
      margin: 0;
      opacity: 0.3;
    }
  }

  &__icon {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 15px;
    // width: 50px;
    width: 100%;
    height: 50px;
    // margin: 0 auto;
    transition: opacity 375ms ease;
    opacity: 0.3;
    cursor: pointer;

    &:disabled {
      opacity: 0.2;
      cursor: not-allowed;

      &:hover {
        opacity: 0.2;
      }
    }

    p {
      width: 100%;
      padding-left: 50px;
      text-align: left;
      color: $brand-white;
      margin: 0;
    }

    &:hover,
    &.active {
      opacity: 1;
    }

    &--plus {
      background-image: url('~@img/svg/plus.svg');
      background-size: 18px 18px;
    }

    &--list {
      background-image: url('~@img/svg/list.svg');
      background-size: 20px 14px;
    }

    &--custom-bars {
      background-image: url('~@img/svg/icon--custom-bars.svg');
      background-size: 27px;
    }

    &--background {
      background-image: url('~@img/svg/icon--bg-no.svg');
      background-size: 25px;
      background-position: 13px;

      &:hover {
        opacity: 1 !important;
      }

      &.active {
        background-image: url('~@img/svg/icon--bg.svg');
        opacity: 0.3;
      }
    }

    &--bin {
      background-image: url('~@img/svg/bin.svg');
      background-size: 15px 20px;
    }

    &--help {
      background-image: url('~@img/svg/help.svg');
      background-size: 15px 20px;
    }

    &--save {
      background-image: url('~@img/svg/save.svg');
      background-size: 20px 20px;
    }

    &--print {
      background-image: url('~@img/svg/icon--printer.svg');
      background-size: 26px 26px;
    }

    &--grid {
      background-image: url('~@img/svg/icon--grid.svg');
      background-size: 20px 20px;

      &.active {
        background-image: url('~@img/svg/icon--no-grid.svg');
      }
    }

    &--pdf {
      background-image: url('~@img/svg/icon--pdf.svg');
      background-size: 20px 20px;
    }

    &--image {
      background-image: url('~@img/svg/icon--no-image.svg');
      background-size: 20px 20px;

      &:hover {
        opacity: 1 !important;
      }

      &.active {
        background-image: url('~@img/svg/icon--image.svg');
        opacity: 0.3;
      }
    }

    &--undo {
      background-image: url('~@img/svg/icon--undo.svg');
      background-size: 20px 20px;
    }

    &--redo {
      background-image: url('~@img/svg/icon--redo.svg');
      background-size: 20px 20px;
    }

    &--zoom-in {
      background-image: url('~@img/svg/icon--zoom-in.svg');
      background-size: 25px 25px;
    }

    &--zoom-out {
      background-image: url('~@img/svg/icon--zoom-out.svg');
      background-size: 25px 25px;
    }

    &--sign-out {
      background-image: url('~@img/svg/icon--sign-out.svg');
      background-size: 25px 25px;
    }

    &--modal {
      background-image: url('~@img/svg/help.svg');
      background-size: 26px 20px;
    }
  }

  &__tooltip {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;

    &:hover .navigation__tooltip-copy {
      border-left: 10px solid mix($brand-grey--dark, #000);
      opacity: 1;
    }

    &-copy {
      position: absolute;
      left: 100%;
      top: 50%;
      transform: translateY(-50%);
      padding: 0px 10px;
      color: $brand-white;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      font-size: 12px;
      height: 95%;
      display: flex;
      align-items: center;
      pointer-events: none;
      white-space: nowrap;
      z-index: -1;
      background-color: $brand-grey--dark;
      border-left: 5px solid mix($brand-grey--dark, #000);
      opacity: 0.65;
      transition: border 350ms ease, opacity 350ms ease;
    }
  }
}
