@font-face {
  font-family: 'Helvetica Neue LT W01_45 Light';
  font-display: swap;
  font-stretch: normal;
  font-style: normal;
  font-weight: 300;
  src: url('~@fonts/milwaukee/752e6c6e-dc02-4393-b814-68a65b594fac.woff2') format('woff2'),
    url('~@fonts/milwaukee/f1892c57-7a6d-457f-9029-d3938c1e80e1.woff') format('woff');
}

@font-face {
  font-family: 'Helvetica Neue LT W01_46 Lt It';
  font-display: swap;
  font-stretch: normal;
  font-style: italic;
  font-weight: 300;
  src: url('~@fonts/milwaukee/710b2e3b-d27e-4b19-9ee9-9598d9c5368a.woff2') format('woff2'),
    url('~@fonts/milwaukee/cabf5256-713c-4838-9aaa-a0a2385d3dec.woff') format('woff');
}

@font-face {
  font-family: 'Helvetica Neue LT W05_53 Ext';
  font-display: swap;
  font-stretch: semi-expanded;
  font-style: normal;
  font-weight: 400;
  src: url('~@fonts/milwaukee/0504481e-774c-4e57-a055-c94bb5303380.woff2') format('woff2'),
    url('~@fonts/milwaukee/bf166391-2bf9-47fd-8382-193fffb422c4.woff') format('woff');
}

@font-face {
  font-family: 'Helvetica Neue LT W05_53 Ex Ob';
  font-display: swap;
  font-stretch: semi-expanded;
  font-style: oblique;
  font-weight: 400;
  src: url('~@fonts/milwaukee/d829f114-8f21-4a35-a303-5fc44e646b98.woff2') format('woff2'),
    url('~@fonts/milwaukee/12be0a3b-503f-4ef3-a94b-0fcce636cd9c.woff') format('woff');
}

@font-face {
  font-family: 'Helvetica Neue LT W01_55 Roman';
  font-display: swap;
  font-stretch: normal;
  font-style: normal;
  font-weight: 400;
  src: url('~@fonts/milwaukee/02963ef0-dbb8-4ec9-a06d-939c6f252739.woff2') format('woff2'),
    url('~@fonts/milwaukee/411f40ef-ca35-4f60-93ce-b394d5485b62.woff') format('woff');
}

@font-face {
  font-family: 'Helvetica Neue LT W05_63 Md Ex';
  font-display: swap;
  font-style: normal;
  src: url('~@fonts/milwaukee/5069b541-02d6-4f13-9ec2-07e07e9d683f.woff2') format('woff2'),
    url('~@fonts/milwaukee/cef4a236-7e60-42cd-997c-99487ff6bb0a.woff') format('woff');
}

@font-face {
  font-family: 'Helvetica Neue LT W01_73 Bd Ex';
  font-display: swap;
  font-stretch: semi-expanded;
  font-style: normal;
  font-weight: 700;
  src: url('~@fonts/milwaukee/b73a7721-ce68-4068-be2f-e354675b54c0.woff2') format('woff2'),
    url('~@fonts/milwaukee/1be79393-9ab6-4f5e-8ff8-786d00be4238.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-style: normal;
  font-family: 'Helvetica Neue LT W05_83 Hv Ex';
  src: url('~@fonts/milwaukee/37cade8a-4499-42b4-b79f-99143dd3a764.woff2') format('woff2'),
    url('~@fonts/milwaukee/6823dde3-d344-4bb4-b5a4-add12527140d.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-style: oblique;
  font-family: 'Helvetica Neue LT W01_83HvExObl';
  src: url('~@fonts/milwaukee/e420593e-9067-41d8-a5be-2ee34c8b2296.woff2') format('woff2'),
    url('~@fonts/milwaukee/2bd00047-167c-4b95-84ea-08fb1ff916a9.woff') format('woff');
}

@font-face {
  font-family: 'Helvetica Neue LT W05_85 Heavy';
  src: url('~@fonts/milwaukee/60d2a64e-8fb1-484e-99db-676419c3aeea.woff2') format('woff2');
}

@font-face {
  font-family: 'Helvetica Neue LT W01_93 BlkEx';
  font-display: swap;
  font-stretch: semi-expanded;
  font-style: normal;
  font-weight: 900;
  src: url('~@fonts/milwaukee/7de87fb1-de1f-45c3-adef-b60ed40c7beb.woff2') format('woff2'),
    url('~@fonts/milwaukee/86c987f4-96f9-4452-bdac-b02d0d3dd276.woff') format('woff');
}

@font-face {
  font-family: 'Helvetica Neue LT W0193BlkExObl';
  font-display: swap;
  font-stretch: semi-expanded;
  font-style: oblique;
  font-weight: 900;
  src: url('~@fonts/milwaukee/16d5d0fd-f35b-444e-bf60-08c684343755.woff2') format('woff2'),
    url('~@fonts/milwaukee/e679b185-6745-4fba-a93e-7b9ed4a5decb.woff') format('woff');
}
