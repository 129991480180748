.tooltip {
  position: fixed;
  z-index: 1000;
  transform: translate(-60px, 10px);
  background-color: rgba($brand-white, 0.9);
  border-radius: 2px;
  border: 1px solid black;
  width: 300px;
  text-align: left;
  will-change: left, top;
  z-index: 100;

  &__inner {
    padding: 15px;

    h3 {
      font-size: 10px;
      margin-bottom: 10px;
    }

    h4 {
      font-size: 10px;
    }

    p {
      font-size: 10px;
      margin-bottom: 0px;
    }
  }
}
