body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $brand-grey;
  min-height: 100vh;
  overflow-x: hidden;
}

.body__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 200px);
  height: 100%;
  left: 200px;
  opacity: 0.15;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.2));
  z-index: 0;
  pointer-events: none;
  background-image: url('~img/svg/logo.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  position: fixed;

  img {
    width: 100%;
    height: 100%;
  }
}

*,
:after,
:before {
  box-sizing: border-box;
}

*:focus {
  border: none;
  outline: none;
}

*:after,
*:before {
  display: block;
}

.hidden {
  display: none;
  visibility: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.center {
  text-align: center;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

img {
  max-width: 100%;
  display: block;
}

button {
  outline: none;
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
}

main {
  @include scrollbar();
  scroll-behavior: smooth;
  width: calc(100% - #{$navigation-width});
  margin-left: auto;
  position: relative;
  overflow-x: auto;
  max-height: 100vh;

  &.homepage {
    overflow-y: scroll;
  }
}

.site-wide {
  max-width: $site-wide;
  margin: 0 auto;
  padding: 0 25px;
}

hr {
  width: 100%;
}

.react-transform-wrapper {
  width: 100% !important;
}
