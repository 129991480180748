.inventory {
  padding-bottom: 50px;

  .form .form__title {
    text-align: left;
  }

  h2 {
    text-transform: uppercase;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  h3 {
    margin-bottom: 25px;
  }

  ul {
    margin-left: 10px;
    margin-bottom: 25px;
    li {
      margin-bottom: 5px;
    }
  }

  label {
    padding-right: 5px;
  }

  input {
    border: none;
    padding: 5px;
  }
}
