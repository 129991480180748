.pdf-product {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 100%;

  &__name {
    @include font-header;
    font-size: 15px;
    text-transform: uppercase;
  }

  &__article {
    @include font-sub-title;
    font-size: 9px;
    color: $brand-primary;
    margin-bottom: cm(0.25);
  }

  &__hero {
    margin-bottom: cm(0.25);
  }

  &__promo {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__info {
    width: 50%;
    height: 100%;
    background-color: $brand-grey;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__features-title {
    @include font-header;
    font-size: 9px;
    text-transform: uppercase;
    margin-bottom: cm(0.2);
  }

  &__features {
    li {
      font-size: 9px;
      margin-bottom: cm(0.2);
    }
  }
}
