.grids-wrapper {
  text-align: center;
  position: relative;
  @include fade-in(500ms, 100ms, ease);
  // transform: scale(var(--zoom));
  // transform-origin: 50% 50%;

  min-width: 100%;
  display: inline-grid;
  grid-auto-flow: column;
  grid-gap: 250px;
  margin: 0px 250px 48px 48px;

  &[data-reduce-space='true'] {
    // Shrink the space between panels, add gap between //
    // every 4th and 5th item to split into chunks of 4 //
    grid-gap: 0px;

    .grid-outer:nth-child(4n) {
      margin-right: 150px;
    }
  }
}

.grid {
  position: relative;
  z-index: 2;
  display: inline-block;
  transform: translateZ(1px);

  &--shadow {
    box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.35);
  }

  &__wrapper {
    padding: 10px;
    background-color: #fff;
  }

  &__name-wrapper {
    position: absolute;
    z-index: 100;
    top: -15px;
    transform: translateY(-80%);
    display: flex;
    padding: 0px 40px;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  &__name {
    @include font-header-heavy();
    margin: 0px 10px;

    &:before {
      content: '';
      width: 23px;
      height: 23px;
      background-image: url('~@img/svg/icon--edit.svg');
      background-repeat: no-repeat;
      background-size: contain;
      display: inline-block;
      vertical-align: bottom;
      margin-right: 5px;
    }
  }

  &__delete {
    width: 15px;
    height: 20px;
    background-image: url('~@img/svg/bin--black.svg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
    position: absolute;
    right: 10px;
    opacity: 0.5;
    transition: opacity 275ms ease;

    &:hover {
      opacity: 1;
    }
  }

  &__loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
  }

  &__spinner {
    position: sticky;
    top: 50%;
    left: 50%;
    transform: translateY(-50%);
  }

  &__container {
    display: inline-block;
    background-repeat: repeat;
    position: relative;

    &.show-dots {
      background-image: url('~@img/svg/dot.svg');
      background-size: calc(var(--grid-pitch) * 1px);
    }
  }

  /* Calcs to convert integers (used in JS so kept as int) to px. */
  &__cells {
    position: relative;
    z-index: 2;
    display: grid;
    grid-template-columns: repeat(var(--grid-steps-x), calc(var(--grid-pitch) * 1px));
    grid-template-rows: repeat(var(--grid-steps-y), calc(var(--grid-pitch) * 1px));
    grid-auto-flow: dense;
    grid-gap: calc(var(--grid-gap) * 1px);

    &--absolute {
      margin-top: calc(((var(--grid-pitch) * 1px) * 2));
      position: absolute;
      height: calc(100% - (((var(--grid-pitch) * 1px) * 2)));
      width: 100%;
      display: block;
      grid-template-columns: none;
      grid-template-rows: none;
      overflow: none;
    }

    &--overflow {
      overflow-y: auto;
    }
  }

  &__drop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
}
