@charset "UTF-8";
.passport h1 {
  font-family: "Helvetica Neue LT W01_93 BlkEx", Helvetica, sans-serif;
  text-transform: uppercase;
  font-size: 26px;
  font-style: italic;
  margin: 0px 0px 20px 0px;
}
.ru-ru .passport h1, .pl-pl .passport h1, .hu-hu .passport h1, .cs-cz .passport h1, .et-ee .passport h1, .lv-lv .passport h1, .lt-lt .passport h1, [lang^=ru] .passport h1, [lang^=pl] .passport h1, [lang^=hu] .passport h1, [lang^=cs] .passport h1, [lang^=et] .passport h1, [lang^=lv] .passport h1, [lang^=lt] .passport h1 {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru .passport h1 strong, .pl-pl .passport h1 strong, .hu-hu .passport h1 strong, .cs-cz .passport h1 strong, .et-ee .passport h1 strong, .lv-lv .passport h1 strong, .lt-lt .passport h1 strong, [lang^=ru] .passport h1 strong, [lang^=pl] .passport h1 strong, [lang^=hu] .passport h1 strong, [lang^=cs] .passport h1 strong, [lang^=et] .passport h1 strong, [lang^=lv] .passport h1 strong, [lang^=lt] .passport h1 strong {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru .passport h1 b, .pl-pl .passport h1 b, .hu-hu .passport h1 b, .cs-cz .passport h1 b, .et-ee .passport h1 b, .lv-lv .passport h1 b, .lt-lt .passport h1 b, [lang^=ru] .passport h1 b, [lang^=pl] .passport h1 b, [lang^=hu] .passport h1 b, [lang^=cs] .passport h1 b, [lang^=et] .passport h1 b, [lang^=lv] .passport h1 b, [lang^=lt] .passport h1 b {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.passport h2 {
  font-family: "Helvetica Neue LT W05_83 Hv Ex", Helvetica, sans-serif;
  text-transform: uppercase;
  font-size: 16px;
  margin: 0px 0px 20px 0px;
}
.ru-ru .passport h2, .pl-pl .passport h2, .hu-hu .passport h2, .cs-cz .passport h2, .et-ee .passport h2, .lv-lv .passport h2, .lt-lt .passport h2, [lang^=ru] .passport h2, [lang^=pl] .passport h2, [lang^=hu] .passport h2, [lang^=cs] .passport h2, [lang^=et] .passport h2, [lang^=lv] .passport h2, [lang^=lt] .passport h2 {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru .passport h2 strong, .pl-pl .passport h2 strong, .hu-hu .passport h2 strong, .cs-cz .passport h2 strong, .et-ee .passport h2 strong, .lv-lv .passport h2 strong, .lt-lt .passport h2 strong, [lang^=ru] .passport h2 strong, [lang^=pl] .passport h2 strong, [lang^=hu] .passport h2 strong, [lang^=cs] .passport h2 strong, [lang^=et] .passport h2 strong, [lang^=lv] .passport h2 strong, [lang^=lt] .passport h2 strong {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru .passport h2 b, .pl-pl .passport h2 b, .hu-hu .passport h2 b, .cs-cz .passport h2 b, .et-ee .passport h2 b, .lv-lv .passport h2 b, .lt-lt .passport h2 b, [lang^=ru] .passport h2 b, [lang^=pl] .passport h2 b, [lang^=hu] .passport h2 b, [lang^=cs] .passport h2 b, [lang^=et] .passport h2 b, [lang^=lv] .passport h2 b, [lang^=lt] .passport h2 b {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.passport h3,
.passport h4,
.passport h5 {
  font-family: "Helvetica Neue LT W05_83 Hv Ex", Helvetica, sans-serif;
  font-size: 15px;
  margin: 0px 0px 20px 0px;
  text-transform: uppercase;
}
.ru-ru .passport h3, .pl-pl .passport h3, .hu-hu .passport h3, .cs-cz .passport h3, .et-ee .passport h3, .lv-lv .passport h3, .lt-lt .passport h3, [lang^=ru] .passport h3, [lang^=pl] .passport h3, [lang^=hu] .passport h3, [lang^=cs] .passport h3, [lang^=et] .passport h3, [lang^=lv] .passport h3, [lang^=lt] .passport h3,
.ru-ru .passport h4,
.pl-pl .passport h4,
.hu-hu .passport h4,
.cs-cz .passport h4,
.et-ee .passport h4,
.lv-lv .passport h4,
.lt-lt .passport h4,
[lang^=ru] .passport h4,
[lang^=pl] .passport h4,
[lang^=hu] .passport h4,
[lang^=cs] .passport h4,
[lang^=et] .passport h4,
[lang^=lv] .passport h4,
[lang^=lt] .passport h4,
.ru-ru .passport h5,
.pl-pl .passport h5,
.hu-hu .passport h5,
.cs-cz .passport h5,
.et-ee .passport h5,
.lv-lv .passport h5,
.lt-lt .passport h5,
[lang^=ru] .passport h5,
[lang^=pl] .passport h5,
[lang^=hu] .passport h5,
[lang^=cs] .passport h5,
[lang^=et] .passport h5,
[lang^=lv] .passport h5,
[lang^=lt] .passport h5 {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru .passport h3 strong, .pl-pl .passport h3 strong, .hu-hu .passport h3 strong, .cs-cz .passport h3 strong, .et-ee .passport h3 strong, .lv-lv .passport h3 strong, .lt-lt .passport h3 strong, [lang^=ru] .passport h3 strong, [lang^=pl] .passport h3 strong, [lang^=hu] .passport h3 strong, [lang^=cs] .passport h3 strong, [lang^=et] .passport h3 strong, [lang^=lv] .passport h3 strong, [lang^=lt] .passport h3 strong,
.ru-ru .passport h4 strong,
.pl-pl .passport h4 strong,
.hu-hu .passport h4 strong,
.cs-cz .passport h4 strong,
.et-ee .passport h4 strong,
.lv-lv .passport h4 strong,
.lt-lt .passport h4 strong,
[lang^=ru] .passport h4 strong,
[lang^=pl] .passport h4 strong,
[lang^=hu] .passport h4 strong,
[lang^=cs] .passport h4 strong,
[lang^=et] .passport h4 strong,
[lang^=lv] .passport h4 strong,
[lang^=lt] .passport h4 strong,
.ru-ru .passport h5 strong,
.pl-pl .passport h5 strong,
.hu-hu .passport h5 strong,
.cs-cz .passport h5 strong,
.et-ee .passport h5 strong,
.lv-lv .passport h5 strong,
.lt-lt .passport h5 strong,
[lang^=ru] .passport h5 strong,
[lang^=pl] .passport h5 strong,
[lang^=hu] .passport h5 strong,
[lang^=cs] .passport h5 strong,
[lang^=et] .passport h5 strong,
[lang^=lv] .passport h5 strong,
[lang^=lt] .passport h5 strong {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru .passport h3 b, .pl-pl .passport h3 b, .hu-hu .passport h3 b, .cs-cz .passport h3 b, .et-ee .passport h3 b, .lv-lv .passport h3 b, .lt-lt .passport h3 b, [lang^=ru] .passport h3 b, [lang^=pl] .passport h3 b, [lang^=hu] .passport h3 b, [lang^=cs] .passport h3 b, [lang^=et] .passport h3 b, [lang^=lv] .passport h3 b, [lang^=lt] .passport h3 b,
.ru-ru .passport h4 b,
.pl-pl .passport h4 b,
.hu-hu .passport h4 b,
.cs-cz .passport h4 b,
.et-ee .passport h4 b,
.lv-lv .passport h4 b,
.lt-lt .passport h4 b,
[lang^=ru] .passport h4 b,
[lang^=pl] .passport h4 b,
[lang^=hu] .passport h4 b,
[lang^=cs] .passport h4 b,
[lang^=et] .passport h4 b,
[lang^=lv] .passport h4 b,
[lang^=lt] .passport h4 b,
.ru-ru .passport h5 b,
.pl-pl .passport h5 b,
.hu-hu .passport h5 b,
.cs-cz .passport h5 b,
.et-ee .passport h5 b,
.lv-lv .passport h5 b,
.lt-lt .passport h5 b,
[lang^=ru] .passport h5 b,
[lang^=pl] .passport h5 b,
[lang^=hu] .passport h5 b,
[lang^=cs] .passport h5 b,
[lang^=et] .passport h5 b,
[lang^=lv] .passport h5 b,
[lang^=lt] .passport h5 b {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.passport p {
  margin: 0px 0px 20px 0px;
}
.passport p,
.passport body {
  font-family: "Helvetica Neue LT W01_55 Roman", Helvetica, sans-serif;
  font-size: 14px;
}
.ru-ru .passport p, .pl-pl .passport p, .hu-hu .passport p, .cs-cz .passport p, .et-ee .passport p, .lv-lv .passport p, .lt-lt .passport p, [lang^=ru] .passport p, [lang^=pl] .passport p, [lang^=hu] .passport p, [lang^=cs] .passport p, [lang^=et] .passport p, [lang^=lv] .passport p, [lang^=lt] .passport p,
.ru-ru .passport body,
.pl-pl .passport body,
.hu-hu .passport body,
.cs-cz .passport body,
.et-ee .passport body,
.lv-lv .passport body,
.lt-lt .passport body,
[lang^=ru] .passport body,
[lang^=pl] .passport body,
[lang^=hu] .passport body,
[lang^=cs] .passport body,
[lang^=et] .passport body,
[lang^=lv] .passport body,
[lang^=lt] .passport body {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru .passport p strong, .pl-pl .passport p strong, .hu-hu .passport p strong, .cs-cz .passport p strong, .et-ee .passport p strong, .lv-lv .passport p strong, .lt-lt .passport p strong, [lang^=ru] .passport p strong, [lang^=pl] .passport p strong, [lang^=hu] .passport p strong, [lang^=cs] .passport p strong, [lang^=et] .passport p strong, [lang^=lv] .passport p strong, [lang^=lt] .passport p strong,
.ru-ru .passport body strong,
.pl-pl .passport body strong,
.hu-hu .passport body strong,
.cs-cz .passport body strong,
.et-ee .passport body strong,
.lv-lv .passport body strong,
.lt-lt .passport body strong,
[lang^=ru] .passport body strong,
[lang^=pl] .passport body strong,
[lang^=hu] .passport body strong,
[lang^=cs] .passport body strong,
[lang^=et] .passport body strong,
[lang^=lv] .passport body strong,
[lang^=lt] .passport body strong {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru .passport p b, .pl-pl .passport p b, .hu-hu .passport p b, .cs-cz .passport p b, .et-ee .passport p b, .lv-lv .passport p b, .lt-lt .passport p b, [lang^=ru] .passport p b, [lang^=pl] .passport p b, [lang^=hu] .passport p b, [lang^=cs] .passport p b, [lang^=et] .passport p b, [lang^=lv] .passport p b, [lang^=lt] .passport p b,
.ru-ru .passport body b,
.pl-pl .passport body b,
.hu-hu .passport body b,
.cs-cz .passport body b,
.et-ee .passport body b,
.lv-lv .passport body b,
.lt-lt .passport body b,
[lang^=ru] .passport body b,
[lang^=pl] .passport body b,
[lang^=hu] .passport body b,
[lang^=cs] .passport body b,
[lang^=et] .passport body b,
[lang^=lv] .passport body b,
[lang^=lt] .passport body b {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.passport .align--left {
  text-align: left;
}
.passport .align--right {
  text-align: right;
}
.passport .align--center {
  text-align: center;
}

.passport-form {
  width: 100%;
  margin: 0 auto;
  position: relative;
  min-width: 300px;
}
@media (min-width: 768px) {
  .passport-form {
    width: 475px;
    min-width: auto;
  }
}
.passport-form__close {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 0;
  right: 0;
  color: #fff;
  background-color: #db011c;
  z-index: 1;
  cursor: pointer;
  user-select: none;
}
.passport-form__close svg {
  width: 100%;
  height: 100%;
}
.passport-form__inner {
  padding: 30px;
  background-color: #fff;
}
.passport-form__inner--confirmation {
  border-bottom: 4px solid #db011c;
}
.passport-form__inner .confirmation {
  text-align: center;
}
.passport-form__inner .confirmation__title {
  margin-top: 10px;
}
.passport-form__inner .confirmation__text {
  padding: 0 30px;
}
@media (min-width: 768px) {
  .passport-form__inner .confirmation__text {
    padding: 0 60px;
  }
}
.passport-form__row {
  width: 100%;
  position: relative;
  display: inline-block;
  margin-top: 20px;
  margin-bottom: 10px;
}
.passport-form__row p.error {
  margin-top: 15px;
}
.passport-form__label {
  position: absolute;
  left: 20px;
  top: 0;
  display: block;
  margin: 0;
  font-size: 11px;
  line-height: 20px;
  opacity: 1;
  transform: translateY(-100%);
  transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  z-index: 1;
  user-select: none;
}
.passport-form__control:not([type=checkbox]):not([type=radio]):placeholder-shown + .passport-form__label {
  opacity: 0;
  transform: translateY(0);
}
.passport-form__control:not([type=checkbox]):not([type=radio]):not(:placeholder-shown) + .passport-form__label {
  opacity: 1;
  transform: translateY(-100%);
}
.passport-form__control {
  width: 100%;
  position: relative;
  display: block;
  height: 40px;
  padding: 10px 20px 9px;
  border: 0;
  border-bottom: 2px solid #231f20;
  background: #fff;
  box-shadow: none;
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 20px;
  outline: 0;
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  z-index: 2;
}
.passport-form__control .passport-form__control:focus {
  background: #fbfbfb;
  box-shadow: none;
  outline: 0;
}
.invalid .passport-form__control {
  border-bottom-color: #db011c;
}
.invalid .passport-form__control::placeholder {
  color: #db011c;
}
input[type=checkbox] .passport-form__control, input[type=radio] .passport-form__control {
  display: none;
}
.passport-form__control .passport-form__select-wrapper {
  position: relative;
}
.passport-form__control .passport-form__select-wrapper:after {
  content: "";
  right: 10px;
  top: 50%;
  transform: translate(-50%, -50%);
}
select .passport-form__control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
textarea .passport-form__control {
  height: auto;
  padding: 10px 19px 9px;
  border: 1px solid fadeout(#323232, 50%);
  border-radius: 5px;
  min-height: 120px;
}
.invalid textarea .passport-form__control {
  border-color: #db011c;
}
input[type=text]:read-only .passport-form__control {
  cursor: not-allowed;
  user-select: none;
  color: fade(#000, 50%);
}
.passport-form__dropdown {
  cursor: pointer;
  z-index: 3;
}
.passport-form__dropdown-values {
  display: none;
  background-color: #fbfbfb;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 2;
  max-height: 280px;
  overflow-y: auto;
}
.passport-form__dropdown-selected {
  position: relative;
  z-index: 1;
}
.invalid .passport-form__dropdown-selected {
  color: #db011c;
}
.passport-form__dropdown-selected:after {
  content: "";
  z-index: 2;
  top: 50%;
  right: 0px;
  width: 12px;
  height: 6px;
  position: absolute;
  background-image: url("data:image/svg;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSI2IiB2aWV3Qm94PSIwIDAgMTIgNiI+PHBhdGggZD0iTTAsMEgxMkw2LDZaIi8+PC9zdmc+");
  background-size: contain;
  background-repeat: no-repeat;
}
.passport-form__dropdown-value {
  padding: 10px 20px 9px;
  user-select: none;
}
.passport-form__dropdown-value:hover {
  background-color: #f4f4f4;
}
.passport-form__dropdown.active {
  background-color: #fbfbfb;
  z-index: 5;
}
.passport-form__dropdown.active .passport-form__dropdown-values {
  display: block;
}
.passport-form__checkbox {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  cursor: pointer;
  user-select: none;
}
.passport-form__checkbox.active:before {
  background-image: url("data:image/svg;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMCIgaGVpZ2h0PSIxMCIgdmlld0JveD0iMCAwIDEwIDEwIj48cGF0aCBkPSJNMi43MzQsMy45MzIsNC40NTMsNS42NTEsOS4yMTkuODg1LDEwLDEuNjY3LDQuNDUzLDcuMjE0bC0yLjUtMi41Wk04Ljg4LDguODhWNC40NTNIMTBWOC44OEExLjE1OCwxLjE1OCwwLDAsMSw4Ljg4LDEwSDEuMTJhMS4wNzMsMS4wNzMsMCwwLDEtLjc4MS0uMzM5QTEuMDczLDEuMDczLDAsMCwxLDAsOC44OFYxLjEyQTEuMDczLDEuMDczLDAsMCwxLC4zMzkuMzM5LDEuMDczLDEuMDczLDAsMCwxLDEuMTIsMEg2LjY2N1YxLjEySDEuMTJWOC44OFpNMTAsMTAiIGZpbGw9IiMzMjMyMzIiLz48L3N2Zz4=");
}
.passport-form__checkbox:before {
  content: "";
  position: relative;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-image: url("data:image/svg;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMCIgaGVpZ2h0PSIxMCIgdmlld0JveD0iMCAwIDEwIDEwIj48ZyBmaWxsPSJub25lIiBzdHJva2U9IiMzMjMyMzIiIHN0cm9rZS13aWR0aD0iMSI+PHJlY3Qgd2lkdGg9IjEwIiBoZWlnaHQ9IjEwIiByeD0iMiIgc3Ryb2tlPSJub25lIi8+PHJlY3QgeD0iMC41IiB5PSIwLjUiIHdpZHRoPSI5IiBoZWlnaHQ9IjkiIHJ4PSIxLjUiIGZpbGw9Im5vbmUiLz48L2c+PC9zdmc+");
}
.passport-form__forgotten-password {
  font-family: "Helvetica Neue LT W01_55 Roman", Helvetica, sans-serif;
  margin: 5px 0;
  font-size: 13px;
}
.ru-ru .passport-form__forgotten-password, .pl-pl .passport-form__forgotten-password, .hu-hu .passport-form__forgotten-password, .cs-cz .passport-form__forgotten-password, .et-ee .passport-form__forgotten-password, .lv-lv .passport-form__forgotten-password, .lt-lt .passport-form__forgotten-password, [lang^=ru] .passport-form__forgotten-password, [lang^=pl] .passport-form__forgotten-password, [lang^=hu] .passport-form__forgotten-password, [lang^=cs] .passport-form__forgotten-password, [lang^=et] .passport-form__forgotten-password, [lang^=lv] .passport-form__forgotten-password, [lang^=lt] .passport-form__forgotten-password {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru .passport-form__forgotten-password strong, .pl-pl .passport-form__forgotten-password strong, .hu-hu .passport-form__forgotten-password strong, .cs-cz .passport-form__forgotten-password strong, .et-ee .passport-form__forgotten-password strong, .lv-lv .passport-form__forgotten-password strong, .lt-lt .passport-form__forgotten-password strong, [lang^=ru] .passport-form__forgotten-password strong, [lang^=pl] .passport-form__forgotten-password strong, [lang^=hu] .passport-form__forgotten-password strong, [lang^=cs] .passport-form__forgotten-password strong, [lang^=et] .passport-form__forgotten-password strong, [lang^=lv] .passport-form__forgotten-password strong, [lang^=lt] .passport-form__forgotten-password strong {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru .passport-form__forgotten-password b, .pl-pl .passport-form__forgotten-password b, .hu-hu .passport-form__forgotten-password b, .cs-cz .passport-form__forgotten-password b, .et-ee .passport-form__forgotten-password b, .lv-lv .passport-form__forgotten-password b, .lt-lt .passport-form__forgotten-password b, [lang^=ru] .passport-form__forgotten-password b, [lang^=pl] .passport-form__forgotten-password b, [lang^=hu] .passport-form__forgotten-password b, [lang^=cs] .passport-form__forgotten-password b, [lang^=et] .passport-form__forgotten-password b, [lang^=lv] .passport-form__forgotten-password b, [lang^=lt] .passport-form__forgotten-password b {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.passport-form__forgotten-password a {
  font-family: "Helvetica Neue LT W05_63 Md Ex", sans-serif;
  font-style: normal;
  font-weight: normal;
  color: #db011c;
  margin-left: 5px;
}
.ru-ru .passport-form__forgotten-password a, .pl-pl .passport-form__forgotten-password a, .hu-hu .passport-form__forgotten-password a, .cs-cz .passport-form__forgotten-password a, .et-ee .passport-form__forgotten-password a, .lv-lv .passport-form__forgotten-password a, .lt-lt .passport-form__forgotten-password a, [lang^=ru] .passport-form__forgotten-password a, [lang^=pl] .passport-form__forgotten-password a, [lang^=hu] .passport-form__forgotten-password a, [lang^=cs] .passport-form__forgotten-password a, [lang^=et] .passport-form__forgotten-password a, [lang^=lv] .passport-form__forgotten-password a, [lang^=lt] .passport-form__forgotten-password a {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru .passport-form__forgotten-password a strong, .pl-pl .passport-form__forgotten-password a strong, .hu-hu .passport-form__forgotten-password a strong, .cs-cz .passport-form__forgotten-password a strong, .et-ee .passport-form__forgotten-password a strong, .lv-lv .passport-form__forgotten-password a strong, .lt-lt .passport-form__forgotten-password a strong, [lang^=ru] .passport-form__forgotten-password a strong, [lang^=pl] .passport-form__forgotten-password a strong, [lang^=hu] .passport-form__forgotten-password a strong, [lang^=cs] .passport-form__forgotten-password a strong, [lang^=et] .passport-form__forgotten-password a strong, [lang^=lv] .passport-form__forgotten-password a strong, [lang^=lt] .passport-form__forgotten-password a strong {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru .passport-form__forgotten-password a b, .pl-pl .passport-form__forgotten-password a b, .hu-hu .passport-form__forgotten-password a b, .cs-cz .passport-form__forgotten-password a b, .et-ee .passport-form__forgotten-password a b, .lv-lv .passport-form__forgotten-password a b, .lt-lt .passport-form__forgotten-password a b, [lang^=ru] .passport-form__forgotten-password a b, [lang^=pl] .passport-form__forgotten-password a b, [lang^=hu] .passport-form__forgotten-password a b, [lang^=cs] .passport-form__forgotten-password a b, [lang^=et] .passport-form__forgotten-password a b, [lang^=lv] .passport-form__forgotten-password a b, [lang^=lt] .passport-form__forgotten-password a b {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}

.passport-loader {
  width: 40px;
  height: 40px;
  margin: 100px auto;
  background-color: #db011c;
  border-radius: 100%;
  animation: sk-scaleout 1.6s infinite ease-in-out;
}

@keyframes sk-scaleout {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}
.passport-btn {
  font-family: "Helvetica Neue LT W05_83 Hv Ex", Helvetica, sans-serif;
  text-transform: uppercase;
  font-size: 16px;
  padding: 14px 20px;
  color: #fff;
  background-color: #db011c;
  display: block;
  width: 100%;
  text-decoration: none;
}
.ru-ru .passport-btn, .pl-pl .passport-btn, .hu-hu .passport-btn, .cs-cz .passport-btn, .et-ee .passport-btn, .lv-lv .passport-btn, .lt-lt .passport-btn, [lang^=ru] .passport-btn, [lang^=pl] .passport-btn, [lang^=hu] .passport-btn, [lang^=cs] .passport-btn, [lang^=et] .passport-btn, [lang^=lv] .passport-btn, [lang^=lt] .passport-btn {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru .passport-btn strong, .pl-pl .passport-btn strong, .hu-hu .passport-btn strong, .cs-cz .passport-btn strong, .et-ee .passport-btn strong, .lv-lv .passport-btn strong, .lt-lt .passport-btn strong, [lang^=ru] .passport-btn strong, [lang^=pl] .passport-btn strong, [lang^=hu] .passport-btn strong, [lang^=cs] .passport-btn strong, [lang^=et] .passport-btn strong, [lang^=lv] .passport-btn strong, [lang^=lt] .passport-btn strong {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru .passport-btn b, .pl-pl .passport-btn b, .hu-hu .passport-btn b, .cs-cz .passport-btn b, .et-ee .passport-btn b, .lv-lv .passport-btn b, .lt-lt .passport-btn b, [lang^=ru] .passport-btn b, [lang^=pl] .passport-btn b, [lang^=hu] .passport-btn b, [lang^=cs] .passport-btn b, [lang^=et] .passport-btn b, [lang^=lv] .passport-btn b, [lang^=lt] .passport-btn b {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.tabs__create .passport-btn {
  font-size: 14px;
  padding: 8px 15px;
}
.passport-btn--link {
  font-family: "Helvetica Neue LT W01_55 Roman", Helvetica, sans-serif;
  font-style: normal;
  font-weight: normal;
  color: #db011c;
  margin-left: 5px;
  padding: 0;
  font-size: 14px;
  background-color: transparent;
  text-decoration: underline;
  text-transform: none;
  display: inline;
  width: auto;
  margin: 0;
}
.ru-ru .passport-btn--link, .pl-pl .passport-btn--link, .hu-hu .passport-btn--link, .cs-cz .passport-btn--link, .et-ee .passport-btn--link, .lv-lv .passport-btn--link, .lt-lt .passport-btn--link, [lang^=ru] .passport-btn--link, [lang^=pl] .passport-btn--link, [lang^=hu] .passport-btn--link, [lang^=cs] .passport-btn--link, [lang^=et] .passport-btn--link, [lang^=lv] .passport-btn--link, [lang^=lt] .passport-btn--link {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru .passport-btn--link strong, .pl-pl .passport-btn--link strong, .hu-hu .passport-btn--link strong, .cs-cz .passport-btn--link strong, .et-ee .passport-btn--link strong, .lv-lv .passport-btn--link strong, .lt-lt .passport-btn--link strong, [lang^=ru] .passport-btn--link strong, [lang^=pl] .passport-btn--link strong, [lang^=hu] .passport-btn--link strong, [lang^=cs] .passport-btn--link strong, [lang^=et] .passport-btn--link strong, [lang^=lv] .passport-btn--link strong, [lang^=lt] .passport-btn--link strong {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru .passport-btn--link b, .pl-pl .passport-btn--link b, .hu-hu .passport-btn--link b, .cs-cz .passport-btn--link b, .et-ee .passport-btn--link b, .lv-lv .passport-btn--link b, .lt-lt .passport-btn--link b, [lang^=ru] .passport-btn--link b, [lang^=pl] .passport-btn--link b, [lang^=hu] .passport-btn--link b, [lang^=cs] .passport-btn--link b, [lang^=et] .passport-btn--link b, [lang^=lv] .passport-btn--link b, [lang^=lt] .passport-btn--link b {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.passport-btn--inline {
  display: inline-block;
  width: auto;
}
.passport-btn--black {
  background-color: #000;
}
.passport-btn--dark-grey {
  background-color: #383739;
}
.passport-btn--grey {
  background-color: #b5b5b5;
}
.passport-btn--grey:active, .passport-btn--grey.active {
  background-color: #db011c;
}
.passport-btn--disabled {
  cursor: not-allowed;
  background-color: #b5b5b5;
}
.passport-btn--disabled:active, .passport-btn--disabled.active {
  background-color: #b5b5b5;
}

.passport-main {
  padding: 0px;
  width: 100%;
  background-color: #e4e4e4;
  overflow-x: hidden;
  min-height: 100vh;
}
.passport-main--full-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.passport-site-wide {
  max-width: 1525px;
  margin: 0 auto;
  padding: 0 25px;
}

.align--center {
  text-align: center;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #e4e4e4;
  min-height: 100vh;
  overflow-x: hidden;
}

.body__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 200px);
  height: 100%;
  left: 200px;
  opacity: 0.15;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.2));
  z-index: 0;
  pointer-events: none;
  background-image: url("~img/svg/logo.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  position: fixed;
}
.body__bg img {
  width: 100%;
  height: 100%;
}

*,
:after,
:before {
  box-sizing: border-box;
}

*:focus {
  border: none;
  outline: none;
}

*:after,
*:before {
  display: block;
}

.hidden {
  display: none;
  visibility: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.center {
  text-align: center;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

img {
  max-width: 100%;
  display: block;
}

button {
  outline: none;
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
}

main {
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
  scroll-behavior: smooth;
  width: calc(100% - 200px);
  margin-left: auto;
  position: relative;
  overflow-x: auto;
  max-height: 100vh;
}
main::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
main::-webkit-scrollbar-track {
  background: #f1f1f1;
}
main::-webkit-scrollbar-thumb {
  background: #b5b5b5;
}
main::-webkit-scrollbar-thumb:hover {
  background: #818483;
}
main.homepage {
  overflow-y: scroll;
}

.site-wide {
  max-width: 1525px;
  margin: 0 auto;
  padding: 0 25px;
}

hr {
  width: 100%;
}

.react-transform-wrapper {
  width: 100% !important;
}

h1 {
  font-family: "Helvetica Neue LT W01_93 BlkEx", Helvetica, sans-serif;
  margin-top: 0;
  text-transform: uppercase;
  font-size: 40px;
  font-style: italic;
}
.ru-ru h1, .pl-pl h1, .hu-hu h1, .cs-cz h1, .et-ee h1, .lv-lv h1, .lt-lt h1, [lang^=ru] h1, [lang^=pl] h1, [lang^=hu] h1, [lang^=cs] h1, [lang^=et] h1, [lang^=lv] h1, [lang^=lt] h1 {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru h1 strong, .pl-pl h1 strong, .hu-hu h1 strong, .cs-cz h1 strong, .et-ee h1 strong, .lv-lv h1 strong, .lt-lt h1 strong, [lang^=ru] h1 strong, [lang^=pl] h1 strong, [lang^=hu] h1 strong, [lang^=cs] h1 strong, [lang^=et] h1 strong, [lang^=lv] h1 strong, [lang^=lt] h1 strong {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru h1 b, .pl-pl h1 b, .hu-hu h1 b, .cs-cz h1 b, .et-ee h1 b, .lv-lv h1 b, .lt-lt h1 b, [lang^=ru] h1 b, [lang^=pl] h1 b, [lang^=hu] h1 b, [lang^=cs] h1 b, [lang^=et] h1 b, [lang^=lv] h1 b, [lang^=lt] h1 b {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}

h3,
h4,
h5 {
  font-family: "Helvetica Neue LT W05_85 Heavy", Helvetica, sans-serif;
  margin-top: 0;
  margin-bottom: 0;
}
.ru-ru h3, .pl-pl h3, .hu-hu h3, .cs-cz h3, .et-ee h3, .lv-lv h3, .lt-lt h3, [lang^=ru] h3, [lang^=pl] h3, [lang^=hu] h3, [lang^=cs] h3, [lang^=et] h3, [lang^=lv] h3, [lang^=lt] h3,
.ru-ru h4,
.pl-pl h4,
.hu-hu h4,
.cs-cz h4,
.et-ee h4,
.lv-lv h4,
.lt-lt h4,
[lang^=ru] h4,
[lang^=pl] h4,
[lang^=hu] h4,
[lang^=cs] h4,
[lang^=et] h4,
[lang^=lv] h4,
[lang^=lt] h4,
.ru-ru h5,
.pl-pl h5,
.hu-hu h5,
.cs-cz h5,
.et-ee h5,
.lv-lv h5,
.lt-lt h5,
[lang^=ru] h5,
[lang^=pl] h5,
[lang^=hu] h5,
[lang^=cs] h5,
[lang^=et] h5,
[lang^=lv] h5,
[lang^=lt] h5 {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru h3 strong, .pl-pl h3 strong, .hu-hu h3 strong, .cs-cz h3 strong, .et-ee h3 strong, .lv-lv h3 strong, .lt-lt h3 strong, [lang^=ru] h3 strong, [lang^=pl] h3 strong, [lang^=hu] h3 strong, [lang^=cs] h3 strong, [lang^=et] h3 strong, [lang^=lv] h3 strong, [lang^=lt] h3 strong,
.ru-ru h4 strong,
.pl-pl h4 strong,
.hu-hu h4 strong,
.cs-cz h4 strong,
.et-ee h4 strong,
.lv-lv h4 strong,
.lt-lt h4 strong,
[lang^=ru] h4 strong,
[lang^=pl] h4 strong,
[lang^=hu] h4 strong,
[lang^=cs] h4 strong,
[lang^=et] h4 strong,
[lang^=lv] h4 strong,
[lang^=lt] h4 strong,
.ru-ru h5 strong,
.pl-pl h5 strong,
.hu-hu h5 strong,
.cs-cz h5 strong,
.et-ee h5 strong,
.lv-lv h5 strong,
.lt-lt h5 strong,
[lang^=ru] h5 strong,
[lang^=pl] h5 strong,
[lang^=hu] h5 strong,
[lang^=cs] h5 strong,
[lang^=et] h5 strong,
[lang^=lv] h5 strong,
[lang^=lt] h5 strong {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru h3 b, .pl-pl h3 b, .hu-hu h3 b, .cs-cz h3 b, .et-ee h3 b, .lv-lv h3 b, .lt-lt h3 b, [lang^=ru] h3 b, [lang^=pl] h3 b, [lang^=hu] h3 b, [lang^=cs] h3 b, [lang^=et] h3 b, [lang^=lv] h3 b, [lang^=lt] h3 b,
.ru-ru h4 b,
.pl-pl h4 b,
.hu-hu h4 b,
.cs-cz h4 b,
.et-ee h4 b,
.lv-lv h4 b,
.lt-lt h4 b,
[lang^=ru] h4 b,
[lang^=pl] h4 b,
[lang^=hu] h4 b,
[lang^=cs] h4 b,
[lang^=et] h4 b,
[lang^=lv] h4 b,
[lang^=lt] h4 b,
.ru-ru h5 b,
.pl-pl h5 b,
.hu-hu h5 b,
.cs-cz h5 b,
.et-ee h5 b,
.lv-lv h5 b,
.lt-lt h5 b,
[lang^=ru] h5 b,
[lang^=pl] h5 b,
[lang^=hu] h5 b,
[lang^=cs] h5 b,
[lang^=et] h5 b,
[lang^=lv] h5 b,
[lang^=lt] h5 b {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}

h2 {
  font-family: "Helvetica Neue LT W05_63 Md Ex", Helvetica, sans-serif;
  font-size: 32px;
}
.ru-ru h2, .pl-pl h2, .hu-hu h2, .cs-cz h2, .et-ee h2, .lv-lv h2, .lt-lt h2, [lang^=ru] h2, [lang^=pl] h2, [lang^=hu] h2, [lang^=cs] h2, [lang^=et] h2, [lang^=lv] h2, [lang^=lt] h2 {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru h2 strong, .pl-pl h2 strong, .hu-hu h2 strong, .cs-cz h2 strong, .et-ee h2 strong, .lv-lv h2 strong, .lt-lt h2 strong, [lang^=ru] h2 strong, [lang^=pl] h2 strong, [lang^=hu] h2 strong, [lang^=cs] h2 strong, [lang^=et] h2 strong, [lang^=lv] h2 strong, [lang^=lt] h2 strong {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru h2 b, .pl-pl h2 b, .hu-hu h2 b, .cs-cz h2 b, .et-ee h2 b, .lv-lv h2 b, .lt-lt h2 b, [lang^=ru] h2 b, [lang^=pl] h2 b, [lang^=hu] h2 b, [lang^=cs] h2 b, [lang^=et] h2 b, [lang^=lv] h2 b, [lang^=lt] h2 b {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}

p,
body {
  font-family: "Helvetica Neue LT W01_55 Roman", Helvetica, sans-serif;
}
.ru-ru p, .pl-pl p, .hu-hu p, .cs-cz p, .et-ee p, .lv-lv p, .lt-lt p, [lang^=ru] p, [lang^=pl] p, [lang^=hu] p, [lang^=cs] p, [lang^=et] p, [lang^=lv] p, [lang^=lt] p,
.ru-ru body,
.pl-pl body,
.hu-hu body,
.cs-cz body,
.et-ee body,
.lv-lv body,
.lt-lt body,
[lang^=ru] body,
[lang^=pl] body,
[lang^=hu] body,
[lang^=cs] body,
[lang^=et] body,
[lang^=lv] body,
[lang^=lt] body {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru p strong, .pl-pl p strong, .hu-hu p strong, .cs-cz p strong, .et-ee p strong, .lv-lv p strong, .lt-lt p strong, [lang^=ru] p strong, [lang^=pl] p strong, [lang^=hu] p strong, [lang^=cs] p strong, [lang^=et] p strong, [lang^=lv] p strong, [lang^=lt] p strong,
.ru-ru body strong,
.pl-pl body strong,
.hu-hu body strong,
.cs-cz body strong,
.et-ee body strong,
.lv-lv body strong,
.lt-lt body strong,
[lang^=ru] body strong,
[lang^=pl] body strong,
[lang^=hu] body strong,
[lang^=cs] body strong,
[lang^=et] body strong,
[lang^=lv] body strong,
[lang^=lt] body strong {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru p b, .pl-pl p b, .hu-hu p b, .cs-cz p b, .et-ee p b, .lv-lv p b, .lt-lt p b, [lang^=ru] p b, [lang^=pl] p b, [lang^=hu] p b, [lang^=cs] p b, [lang^=et] p b, [lang^=lv] p b, [lang^=lt] p b,
.ru-ru body b,
.pl-pl body b,
.hu-hu body b,
.cs-cz body b,
.et-ee body b,
.lv-lv body b,
.lt-lt body b,
[lang^=ru] body b,
[lang^=pl] body b,
[lang^=hu] body b,
[lang^=cs] body b,
[lang^=et] body b,
[lang^=lv] body b,
[lang^=lt] body b {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}

.align--left {
  text-align: left;
}

.align--right {
  text-align: right;
}

.align--center {
  text-align: center;
}

p.error {
  color: #db011c;
}

@font-face {
  font-family: "Helvetica Neue LT W01_45 Light";
  font-display: swap;
  font-stretch: normal;
  font-style: normal;
  font-weight: 300;
  src: url("~@fonts/milwaukee/752e6c6e-dc02-4393-b814-68a65b594fac.woff2") format("woff2"), url("~@fonts/milwaukee/f1892c57-7a6d-457f-9029-d3938c1e80e1.woff") format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W01_46 Lt It";
  font-display: swap;
  font-stretch: normal;
  font-style: italic;
  font-weight: 300;
  src: url("~@fonts/milwaukee/710b2e3b-d27e-4b19-9ee9-9598d9c5368a.woff2") format("woff2"), url("~@fonts/milwaukee/cabf5256-713c-4838-9aaa-a0a2385d3dec.woff") format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_53 Ext";
  font-display: swap;
  font-stretch: semi-expanded;
  font-style: normal;
  font-weight: 400;
  src: url("~@fonts/milwaukee/0504481e-774c-4e57-a055-c94bb5303380.woff2") format("woff2"), url("~@fonts/milwaukee/bf166391-2bf9-47fd-8382-193fffb422c4.woff") format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_53 Ex Ob";
  font-display: swap;
  font-stretch: semi-expanded;
  font-style: oblique;
  font-weight: 400;
  src: url("~@fonts/milwaukee/d829f114-8f21-4a35-a303-5fc44e646b98.woff2") format("woff2"), url("~@fonts/milwaukee/12be0a3b-503f-4ef3-a94b-0fcce636cd9c.woff") format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W01_55 Roman";
  font-display: swap;
  font-stretch: normal;
  font-style: normal;
  font-weight: 400;
  src: url("~@fonts/milwaukee/02963ef0-dbb8-4ec9-a06d-939c6f252739.woff2") format("woff2"), url("~@fonts/milwaukee/411f40ef-ca35-4f60-93ce-b394d5485b62.woff") format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_63 Md Ex";
  font-display: swap;
  font-style: normal;
  src: url("~@fonts/milwaukee/5069b541-02d6-4f13-9ec2-07e07e9d683f.woff2") format("woff2"), url("~@fonts/milwaukee/cef4a236-7e60-42cd-997c-99487ff6bb0a.woff") format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W01_73 Bd Ex";
  font-display: swap;
  font-stretch: semi-expanded;
  font-style: normal;
  font-weight: 700;
  src: url("~@fonts/milwaukee/b73a7721-ce68-4068-be2f-e354675b54c0.woff2") format("woff2"), url("~@fonts/milwaukee/1be79393-9ab6-4f5e-8ff8-786d00be4238.woff") format("woff");
}
@font-face {
  font-display: swap;
  font-style: normal;
  font-family: "Helvetica Neue LT W05_83 Hv Ex";
  src: url("~@fonts/milwaukee/37cade8a-4499-42b4-b79f-99143dd3a764.woff2") format("woff2"), url("~@fonts/milwaukee/6823dde3-d344-4bb4-b5a4-add12527140d.woff") format("woff");
}
@font-face {
  font-display: swap;
  font-style: oblique;
  font-family: "Helvetica Neue LT W01_83HvExObl";
  src: url("~@fonts/milwaukee/e420593e-9067-41d8-a5be-2ee34c8b2296.woff2") format("woff2"), url("~@fonts/milwaukee/2bd00047-167c-4b95-84ea-08fb1ff916a9.woff") format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_85 Heavy";
  src: url("~@fonts/milwaukee/60d2a64e-8fb1-484e-99db-676419c3aeea.woff2") format("woff2");
}
@font-face {
  font-family: "Helvetica Neue LT W01_93 BlkEx";
  font-display: swap;
  font-stretch: semi-expanded;
  font-style: normal;
  font-weight: 900;
  src: url("~@fonts/milwaukee/7de87fb1-de1f-45c3-adef-b60ed40c7beb.woff2") format("woff2"), url("~@fonts/milwaukee/86c987f4-96f9-4452-bdac-b02d0d3dd276.woff") format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W0193BlkExObl";
  font-display: swap;
  font-stretch: semi-expanded;
  font-style: oblique;
  font-weight: 900;
  src: url("~@fonts/milwaukee/16d5d0fd-f35b-444e-bf60-08c684343755.woff2") format("woff2"), url("~@fonts/milwaukee/e679b185-6745-4fba-a93e-7b9ed4a5decb.woff") format("woff");
}
/** 400-Regular */
/* latin */
@font-face {
  font-family: "Arimo";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Arimo"), url("~@fonts/arimo/latin/arimo-v12-400-subset.woff2") format("woff2"), url("~@fonts/arimo/latin/arimo-v12-400-subset.woff") format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}
/* latin-ext */
@font-face {
  font-family: "Arimo";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Arimo"), url("~@fonts/arimo/latin-ext/arimo-v12-400-subset.woff2") format("woff2"), url("~@fonts/arimo/latin-ext/arimo-v12-400-subset.woff") format("woff");
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* cyrillic */
@font-face {
  font-family: "Arimo";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Arimo"), url("~@fonts/arimo/cyrillic/arimo-v12-400-subset.woff2") format("woff2"), url("~@fonts/arimo/cyrillic/arimo-v12-400-subset.woff") format("woff");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* cyrillic-ext */
@font-face {
  font-family: "Arimo";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Arimo"), url("~@fonts/arimo/cyrillic-ext/arimo-v12-400-subset.woff2") format("woff2"), url("~@fonts/arimo/cyrillic-ext/arimo-v12-400-subset.woff") format("woff");
  unicode-range: U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F;
}
/* vietnamese */
@font-face {
  font-family: "Arimo";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Arimo"), url("~@fonts/arimo/vietnamese/arimo-v12-400-subset.woff2") format("woff2"), url("~@fonts/arimo/vietnamese/arimo-v12-400-subset.woff") format("woff");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB;
}
/** 400-Italic */
/* latin */
@font-face {
  font-family: "Arimo";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local("Arimo"), url("~@fonts/arimo/latin/arimo-v12-400italic-subset.woff2") format("woff2"), url("~@fonts/arimo/latin/arimo-v12-400italic-subset.woff") format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}
/* latin-ext */
@font-face {
  font-family: "Arimo";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local("Arimo"), url("~@fonts/arimo/latin-ext/arimo-v12-400italic-subset.woff2") format("woff2"), url("~@fonts/arimo/latin-ext/arimo-v12-400italic-subset.woff") format("woff");
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* cyrillic */
@font-face {
  font-family: "Arimo";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local("Arimo"), url("~@fonts/arimo/cyrillic/arimo-v12-400italic-subset.woff2") format("woff2"), url("~@fonts/arimo/cyrillic/arimo-v12-400italic-subset.woff") format("woff");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* cyrillic-ext */
@font-face {
  font-family: "Arimo";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local("Arimo"), url("~@fonts/arimo/cyrillic-ext/arimo-v12-400italic-subset.woff2") format("woff2"), url("~@fonts/arimo/cyrillic-ext/arimo-v12-400italic-subset.woff") format("woff");
  unicode-range: U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F;
}
/* vietnamese */
@font-face {
  font-family: "Arimo";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local("Arimo"), url("~@fonts/arimo/vietnamese/arimo-v12-400italic-subset.woff2") format("woff2"), url("~@fonts/arimo/vietnamese/arimo-v12-400italic-subset.woff") format("woff");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB;
}
/** 700-Regular */
/* latin */
@font-face {
  font-family: "Arimo";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Arimo"), url("~@fonts/arimo/latin/arimo-v12-700-subset.woff2") format("woff2"), url("~@fonts/arimo/latin/arimo-v12-700-subset.woff") format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}
/* latin-ext */
@font-face {
  font-family: "Arimo";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Arimo"), url("~@fonts/arimo/latin-ext/arimo-v12-700-subset.woff2") format("woff2"), url("~@fonts/arimo/latin-ext/arimo-v12-700-subset.woff") format("woff");
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* cyrillic */
@font-face {
  font-family: "Arimo";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Arimo"), url("~@fonts/arimo/cyrillic/arimo-v12-700-subset.woff2") format("woff2"), url("~@fonts/arimo/cyrillic/arimo-v12-700-subset.woff") format("woff");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* cyrillic-ext */
@font-face {
  font-family: "Arimo";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Arimo"), url("~@fonts/arimo/cyrillic-ext/arimo-v12-700-subset.woff2") format("woff2"), url("~@fonts/arimo/cyrillic-ext/arimo-v12-700-subset.woff") format("woff");
  unicode-range: U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F;
}
/* vietnamese */
@font-face {
  font-family: "Arimo";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Arimo"), url("~@fonts/arimo/vietnamese/arimo-v12-700-subset.woff2") format("woff2"), url("~@fonts/arimo/vietnamese/arimo-v12-700-subset.woff") format("woff");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB;
}
/** 700-Italic */
/* latin */
@font-face {
  font-family: "Arimo";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local("Arimo"), url("~@fonts/arimo/latin/arimo-v12-700italic-subset.woff2") format("woff2"), url("~@fonts/arimo/latin/arimo-v12-700italic-subset.woff") format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}
/* latin-ext */
@font-face {
  font-family: "Arimo";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local("Arimo"), url("~@fonts/arimo/latin-ext/arimo-v12-700italic-subset.woff2") format("woff2"), url("~@fonts/arimo/latin-ext/arimo-v12-700italic-subset.woff") format("woff");
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* cyrillic */
@font-face {
  font-family: "Arimo";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local("Arimo"), url("~@fonts/arimo/cyrillic/arimo-v12-700italic-subset.woff2") format("woff2"), url("~@fonts/arimo/cyrillic/arimo-v12-700italic-subset.woff") format("woff");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* cyrillic-ext */
@font-face {
  font-family: "Arimo";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local("Arimo"), url("~@fonts/arimo/cyrillic-ext/arimo-v12-700italic-subset.woff2") format("woff2"), url("~@fonts/arimo/cyrillic-ext/arimo-v12-700italic-subset.woff") format("woff");
  unicode-range: U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F;
}
/* vietnamese */
@font-face {
  font-family: "Arimo";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local("Arimo"), url("~@fonts/arimo/vietnamese/arimo-v12-700italic-subset.woff2") format("woff2"), url("~@fonts/arimo/vietnamese/arimo-v12-700italic-subset.woff") format("woff");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB;
}
/** 400-Regular */
/* latin */
@font-face {
  font-family: "Andika";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Andika"), url("~@fonts/andika/latin/andika-v10-subset.woff2") format("woff2"), url("~@fonts/andika/latin/andika-v10-subset.woff") format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}
/* latin-ext */
@font-face {
  font-family: "Andika";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Andika"), url("~@fonts/andika/latin-ext/andika-v10-subset.woff2") format("woff2"), url("~@fonts/andika/latin-ext/andika-v10-subset.woff") format("woff");
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* cyrillic */
@font-face {
  font-family: "Andika";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Andika"), url("~@fonts/andika/cyrillic/andika-v10-subset.woff2") format("woff2"), url("~@fonts/andika/cyrillic/andika-v10-subset.woff") format("woff");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* cyrillic-ext */
@font-face {
  font-family: "Andika";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Andika"), url("~@fonts/andika/cyrillic-ext/andika-v10-subset.woff2") format("woff2"), url("~@fonts/andika/cyrillic-ext/andika-v10-subset.woff") format("woff");
  unicode-range: U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F;
}
/* vietnamese */
@font-face {
  font-family: "Andika";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Andika"), url("~@fonts/andika/vietnamese/andika-v10-subset.woff2") format("woff2"), url("~@fonts/andika/vietnamese/andika-v10-subset.woff") format("woff");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB;
}
.accordion {
  border-top: 1px solid #b5b5b5;
}
.accordion:last-of-type {
  margin-bottom: 20px;
  border-bottom: 1px solid #b5b5b5;
}
.accordion__title {
  position: relative;
  font-family: "Helvetica Neue LT W01_93 BlkEx", Helvetica, sans-serif;
  font-size: 16px;
  padding-right: 32px;
  color: #000;
  padding: 16px 0;
  text-transform: uppercase;
  cursor: pointer;
  transition: opacity 150ms ease-in-out;
  user-select: none;
}
.ru-ru .accordion__title, .pl-pl .accordion__title, .hu-hu .accordion__title, .cs-cz .accordion__title, .et-ee .accordion__title, .lv-lv .accordion__title, .lt-lt .accordion__title, [lang^=ru] .accordion__title, [lang^=pl] .accordion__title, [lang^=hu] .accordion__title, [lang^=cs] .accordion__title, [lang^=et] .accordion__title, [lang^=lv] .accordion__title, [lang^=lt] .accordion__title {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru .accordion__title strong, .pl-pl .accordion__title strong, .hu-hu .accordion__title strong, .cs-cz .accordion__title strong, .et-ee .accordion__title strong, .lv-lv .accordion__title strong, .lt-lt .accordion__title strong, [lang^=ru] .accordion__title strong, [lang^=pl] .accordion__title strong, [lang^=hu] .accordion__title strong, [lang^=cs] .accordion__title strong, [lang^=et] .accordion__title strong, [lang^=lv] .accordion__title strong, [lang^=lt] .accordion__title strong {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru .accordion__title b, .pl-pl .accordion__title b, .hu-hu .accordion__title b, .cs-cz .accordion__title b, .et-ee .accordion__title b, .lv-lv .accordion__title b, .lt-lt .accordion__title b, [lang^=ru] .accordion__title b, [lang^=pl] .accordion__title b, [lang^=hu] .accordion__title b, [lang^=cs] .accordion__title b, [lang^=et] .accordion__title b, [lang^=lv] .accordion__title b, [lang^=lt] .accordion__title b {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.accordion--active .accordion__title {
  color: #db011c;
  transition: none;
}
.accordion__title:after {
  content: "";
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  width: 12px;
  height: 6px;
  background: url("~img/svg/caret.svg") no-repeat center;
}
.accordion--active .accordion__title:after {
  background: url("~img/svg/caret--red.svg") no-repeat center;
}
.accordion__content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 300ms ease-in-out;
}
.accordion--active .accordion__content {
  max-height: 5000px;
}
.accordion__content .form__section:first-child {
  padding-top: 24px;
}
.accordion__content .form__title {
  font-family: "Helvetica Neue LT W01_93 BlkEx", Helvetica, sans-serif;
  font-size: 12px;
  text-transform: uppercase;
  padding: 0 21px;
  margin: 0;
}
.ru-ru .accordion__content .form__title, .pl-pl .accordion__content .form__title, .hu-hu .accordion__content .form__title, .cs-cz .accordion__content .form__title, .et-ee .accordion__content .form__title, .lv-lv .accordion__content .form__title, .lt-lt .accordion__content .form__title, [lang^=ru] .accordion__content .form__title, [lang^=pl] .accordion__content .form__title, [lang^=hu] .accordion__content .form__title, [lang^=cs] .accordion__content .form__title, [lang^=et] .accordion__content .form__title, [lang^=lv] .accordion__content .form__title, [lang^=lt] .accordion__content .form__title {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru .accordion__content .form__title strong, .pl-pl .accordion__content .form__title strong, .hu-hu .accordion__content .form__title strong, .cs-cz .accordion__content .form__title strong, .et-ee .accordion__content .form__title strong, .lv-lv .accordion__content .form__title strong, .lt-lt .accordion__content .form__title strong, [lang^=ru] .accordion__content .form__title strong, [lang^=pl] .accordion__content .form__title strong, [lang^=hu] .accordion__content .form__title strong, [lang^=cs] .accordion__content .form__title strong, [lang^=et] .accordion__content .form__title strong, [lang^=lv] .accordion__content .form__title strong, [lang^=lt] .accordion__content .form__title strong {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru .accordion__content .form__title b, .pl-pl .accordion__content .form__title b, .hu-hu .accordion__content .form__title b, .cs-cz .accordion__content .form__title b, .et-ee .accordion__content .form__title b, .lv-lv .accordion__content .form__title b, .lt-lt .accordion__content .form__title b, [lang^=ru] .accordion__content .form__title b, [lang^=pl] .accordion__content .form__title b, [lang^=hu] .accordion__content .form__title b, [lang^=cs] .accordion__content .form__title b, [lang^=et] .accordion__content .form__title b, [lang^=lv] .accordion__content .form__title b, [lang^=lt] .accordion__content .form__title b {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.accordion:not(.accordion--active):hover .accordion__title {
  opacity: 0.6;
}

.tabs {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-top: 40px;
  margin-bottom: 40px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.tabs .tab {
  font-family: "Helvetica Neue LT W05_53 Ext", Helvetica, sans-serif;
  font-size: 14px;
  margin: 0px 40px;
  padding: 10px 0px;
  position: relative;
}
.ru-ru .tabs .tab, .pl-pl .tabs .tab, .hu-hu .tabs .tab, .cs-cz .tabs .tab, .et-ee .tabs .tab, .lv-lv .tabs .tab, .lt-lt .tabs .tab, [lang^=ru] .tabs .tab, [lang^=pl] .tabs .tab, [lang^=hu] .tabs .tab, [lang^=cs] .tabs .tab, [lang^=et] .tabs .tab, [lang^=lv] .tabs .tab, [lang^=lt] .tabs .tab {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru .tabs .tab strong, .pl-pl .tabs .tab strong, .hu-hu .tabs .tab strong, .cs-cz .tabs .tab strong, .et-ee .tabs .tab strong, .lv-lv .tabs .tab strong, .lt-lt .tabs .tab strong, [lang^=ru] .tabs .tab strong, [lang^=pl] .tabs .tab strong, [lang^=hu] .tabs .tab strong, [lang^=cs] .tabs .tab strong, [lang^=et] .tabs .tab strong, [lang^=lv] .tabs .tab strong, [lang^=lt] .tabs .tab strong {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru .tabs .tab b, .pl-pl .tabs .tab b, .hu-hu .tabs .tab b, .cs-cz .tabs .tab b, .et-ee .tabs .tab b, .lv-lv .tabs .tab b, .lt-lt .tabs .tab b, [lang^=ru] .tabs .tab b, [lang^=pl] .tabs .tab b, [lang^=hu] .tabs .tab b, [lang^=cs] .tabs .tab b, [lang^=et] .tabs .tab b, [lang^=lv] .tabs .tab b, [lang^=lt] .tabs .tab b {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.tabs .tab.active:after {
  content: "";
  width: 100%;
  position: absolute;
  bottom: -1px;
  height: 1px;
  background-color: #000;
}
.tabs__title {
  text-align: center;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.btn {
  font-family: "Helvetica Neue LT W01_93 BlkEx", Helvetica, sans-serif;
  text-transform: uppercase;
  font-size: 12px;
  padding: 10px 20px;
  color: #fff;
  background-color: #000;
  border-radius: 4px;
  letter-spacing: 0.7px;
  cursor: pointer;
  user-select: none;
  text-decoration: none;
}
.ru-ru .btn, .pl-pl .btn, .hu-hu .btn, .cs-cz .btn, .et-ee .btn, .lv-lv .btn, .lt-lt .btn, [lang^=ru] .btn, [lang^=pl] .btn, [lang^=hu] .btn, [lang^=cs] .btn, [lang^=et] .btn, [lang^=lv] .btn, [lang^=lt] .btn {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru .btn strong, .pl-pl .btn strong, .hu-hu .btn strong, .cs-cz .btn strong, .et-ee .btn strong, .lv-lv .btn strong, .lt-lt .btn strong, [lang^=ru] .btn strong, [lang^=pl] .btn strong, [lang^=hu] .btn strong, [lang^=cs] .btn strong, [lang^=et] .btn strong, [lang^=lv] .btn strong, [lang^=lt] .btn strong {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru .btn b, .pl-pl .btn b, .hu-hu .btn b, .cs-cz .btn b, .et-ee .btn b, .lv-lv .btn b, .lt-lt .btn b, [lang^=ru] .btn b, [lang^=pl] .btn b, [lang^=hu] .btn b, [lang^=cs] .btn b, [lang^=et] .btn b, [lang^=lv] .btn b, [lang^=lt] .btn b {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.btn[disabled] {
  background-color: grey;
  pointer-events: none;
}
.btn--inline {
  display: inline-flex;
  align-self: flex-start;
}
.btn--primary {
  background-color: #db011c;
}
.btn--grey {
  background-color: #b5b5b5;
}
.btn--grey:active, .btn--grey.active {
  background-color: #383739;
}
.btn--download {
  padding-right: 46px;
  background-image: url("~@img/svg/download.svg");
  background-repeat: no-repeat;
  background-position: right 16px center;
}
.btn--link {
  text-decoration: none;
}

.modal {
  position: fixed;
  z-index: 10001;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.modal__close {
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
  background-color: #db011c;
  cursor: pointer;
}
.modal__close:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  background-image: url("~@img/svg/plus.svg");
  width: 18px;
  height: 18px;
  transform: translate(-50%, -50%) rotate(45deg);
}
.modal__inner {
  background-color: #fff;
  border-radius: 4px;
  padding: 50px;
  min-width: 300px;
  max-height: 90vh;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
}
.modal__inner h5 {
  margin-bottom: 20px;
}
.modal__min {
  min-width: 800px;
}

.toast-track {
  position: fixed;
  top: 8px;
  right: 8px;
  width: 360px;
  z-index: 5000;
}
.toast-track .toast {
  position: relative;
  border-radius: 4px;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.175);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-bottom: 8px;
  transform: translateX(200%);
  transition: transform 220ms cubic-bezier(0.2, 0, 0, 1), opacity 220ms;
}
.toast-track .toast.animated-enter-done {
  transform: translateX(0%);
}
.toast-track .toast__icon {
  width: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.toast-track .toast__inner {
  padding: 10px;
  font-family: "Helvetica Neue LT W01_55 Roman", Helvetica, sans-serif;
  font-size: 14px;
}
.ru-ru .toast-track .toast__inner, .pl-pl .toast-track .toast__inner, .hu-hu .toast-track .toast__inner, .cs-cz .toast-track .toast__inner, .et-ee .toast-track .toast__inner, .lv-lv .toast-track .toast__inner, .lt-lt .toast-track .toast__inner, [lang^=ru] .toast-track .toast__inner, [lang^=pl] .toast-track .toast__inner, [lang^=hu] .toast-track .toast__inner, [lang^=cs] .toast-track .toast__inner, [lang^=et] .toast-track .toast__inner, [lang^=lv] .toast-track .toast__inner, [lang^=lt] .toast-track .toast__inner {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru .toast-track .toast__inner strong, .pl-pl .toast-track .toast__inner strong, .hu-hu .toast-track .toast__inner strong, .cs-cz .toast-track .toast__inner strong, .et-ee .toast-track .toast__inner strong, .lv-lv .toast-track .toast__inner strong, .lt-lt .toast-track .toast__inner strong, [lang^=ru] .toast-track .toast__inner strong, [lang^=pl] .toast-track .toast__inner strong, [lang^=hu] .toast-track .toast__inner strong, [lang^=cs] .toast-track .toast__inner strong, [lang^=et] .toast-track .toast__inner strong, [lang^=lv] .toast-track .toast__inner strong, [lang^=lt] .toast-track .toast__inner strong {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru .toast-track .toast__inner b, .pl-pl .toast-track .toast__inner b, .hu-hu .toast-track .toast__inner b, .cs-cz .toast-track .toast__inner b, .et-ee .toast-track .toast__inner b, .lv-lv .toast-track .toast__inner b, .lt-lt .toast-track .toast__inner b, [lang^=ru] .toast-track .toast__inner b, [lang^=pl] .toast-track .toast__inner b, [lang^=hu] .toast-track .toast__inner b, [lang^=cs] .toast-track .toast__inner b, [lang^=et] .toast-track .toast__inner b, [lang^=lv] .toast-track .toast__inner b, [lang^=lt] .toast-track .toast__inner b {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.toast-track .toast__dismiss {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}
.toast-track .toast--info {
  background-color: #fff;
}
.toast-track .toast--info .toast__icon {
  color: #fff;
  background-color: #2684ff;
}
.toast-track .toast--success {
  background-color: #e3fcef;
  color: #006644;
}
.toast-track .toast--success .toast__icon {
  background-color: #006644;
  color: #e3fcef;
}
.toast-track .toast--warning {
  background-color: #fffae6;
  color: #ff8b00;
}
.toast-track .toast--warning .toast__icon {
  background-color: #ff8b00;
  color: #fffae6;
}
.toast-track .toast--error {
  background-color: #ffebe6;
  color: #bf2600;
}
.toast-track .toast--error .toast__icon {
  background-color: #bf2600;
  color: #ffebe6;
}

.full-loader-container {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loader-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.loader-container--300 {
  height: 300px;
}

.loader {
  width: 40px;
  height: 40px;
  background-color: #db011c;
  margin: 0 auto;
  border-radius: 100%;
  animation: sk-scaleout 1s infinite ease-in-out;
}

@keyframes sk-scaleout {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}
.error-404 {
  height: 300px;
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  text-align: center;
}

form {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  padding: 40px 25px;
  background-color: #fff;
}
form.no-padding {
  padding: 0;
}
form.max-300 {
  max-width: 300px;
}
form.min-400 {
  min-width: 400px;
}
form.loader-container {
  min-width: 300px;
  min-height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
form.form__border {
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.5);
}
form .form__section {
  margin-bottom: 36px;
}
form .form__title {
  margin-bottom: 10px;
  text-align: center;
  width: 100%;
}
form .form__title--left {
  text-align: left;
}
form .form__row {
  padding: 5px 0px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  margin-bottom: 15px;
  position: relative;
}
form .form__split {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
form .form__split .form__row {
  width: calc(50% - (25px / 2));
}
form .form__product {
  display: flex;
  padding: 21px;
}
form .form__product:nth-child(odd) {
  background: #f8f8f8;
}
form .form__product .form__product-name {
  flex: 1 0 calc(100% - 125px);
  display: flex;
  flex-direction: column;
  justify-content: center;
}
form .form__product .form__product-number {
  flex: 0 0 125px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
form .form__product .form__product-number--label {
  justify-content: center;
  padding-left: 22px;
}
form .form__product .form__product-quantity {
  text-align: center;
  width: 100%;
}
form .form__bold {
  font-family: "Helvetica Neue LT W01_93 BlkEx", Helvetica, sans-serif;
  font-size: 12px;
}
.ru-ru form .form__bold, .pl-pl form .form__bold, .hu-hu form .form__bold, .cs-cz form .form__bold, .et-ee form .form__bold, .lv-lv form .form__bold, .lt-lt form .form__bold, [lang^=ru] form .form__bold, [lang^=pl] form .form__bold, [lang^=hu] form .form__bold, [lang^=cs] form .form__bold, [lang^=et] form .form__bold, [lang^=lv] form .form__bold, [lang^=lt] form .form__bold {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru form .form__bold strong, .pl-pl form .form__bold strong, .hu-hu form .form__bold strong, .cs-cz form .form__bold strong, .et-ee form .form__bold strong, .lv-lv form .form__bold strong, .lt-lt form .form__bold strong, [lang^=ru] form .form__bold strong, [lang^=pl] form .form__bold strong, [lang^=hu] form .form__bold strong, [lang^=cs] form .form__bold strong, [lang^=et] form .form__bold strong, [lang^=lv] form .form__bold strong, [lang^=lt] form .form__bold strong {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru form .form__bold b, .pl-pl form .form__bold b, .hu-hu form .form__bold b, .cs-cz form .form__bold b, .et-ee form .form__bold b, .lv-lv form .form__bold b, .lt-lt form .form__bold b, [lang^=ru] form .form__bold b, [lang^=pl] form .form__bold b, [lang^=hu] form .form__bold b, [lang^=cs] form .form__bold b, [lang^=et] form .form__bold b, [lang^=lv] form .form__bold b, [lang^=lt] form .form__bold b {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
form .form__sub {
  margin: 5px 0px;
  font-size: 12px;
  color: #818483;
}
form .form__row-wrapper {
  margin-bottom: 15px;
}
form .form__footer {
  text-align: center;
  margin-top: 20px;
}
form .form__footer .btn {
  margin-left: 40px;
}
form .form__footer .btn:first-child {
  margin-left: 0px;
}
form label {
  font-family: "Helvetica Neue LT W05_53 Ext", Helvetica, sans-serif;
  position: absolute;
  left: 8px;
  top: 10px;
  display: block;
  margin: 0;
  font-size: 11px;
  line-height: 20px;
  color: #383739;
  transition: opacity 0.3s ease, transform 0.3s ease;
  z-index: 1;
  user-select: none;
  opacity: 0;
  transform: translateY(0);
}
.ru-ru form label, .pl-pl form label, .hu-hu form label, .cs-cz form label, .et-ee form label, .lv-lv form label, .lt-lt form label, [lang^=ru] form label, [lang^=pl] form label, [lang^=hu] form label, [lang^=cs] form label, [lang^=et] form label, [lang^=lv] form label, [lang^=lt] form label {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru form label strong, .pl-pl form label strong, .hu-hu form label strong, .cs-cz form label strong, .et-ee form label strong, .lv-lv form label strong, .lt-lt form label strong, [lang^=ru] form label strong, [lang^=pl] form label strong, [lang^=hu] form label strong, [lang^=cs] form label strong, [lang^=et] form label strong, [lang^=lv] form label strong, [lang^=lt] form label strong {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru form label b, .pl-pl form label b, .hu-hu form label b, .cs-cz form label b, .et-ee form label b, .lv-lv form label b, .lt-lt form label b, [lang^=ru] form label b, [lang^=pl] form label b, [lang^=hu] form label b, [lang^=cs] form label b, [lang^=et] form label b, [lang^=lv] form label b, [lang^=lt] form label b {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
form label.is-active {
  opacity: 1;
  transform: translateY(-100%);
}
form label.no-anim {
  opacity: 1;
  transform: none;
}
form input[type=checkbox] {
  display: none;
}
form input[type=checkbox] + label {
  font-family: "Helvetica Neue LT W05_63 Md Ex", Helvetica, sans-serif;
  position: relative;
  top: 0;
  left: 0;
  opacity: 1;
  padding-left: 28px;
  color: #000;
  margin-bottom: 6px;
}
.ru-ru form input[type=checkbox] + label, .pl-pl form input[type=checkbox] + label, .hu-hu form input[type=checkbox] + label, .cs-cz form input[type=checkbox] + label, .et-ee form input[type=checkbox] + label, .lv-lv form input[type=checkbox] + label, .lt-lt form input[type=checkbox] + label, [lang^=ru] form input[type=checkbox] + label, [lang^=pl] form input[type=checkbox] + label, [lang^=hu] form input[type=checkbox] + label, [lang^=cs] form input[type=checkbox] + label, [lang^=et] form input[type=checkbox] + label, [lang^=lv] form input[type=checkbox] + label, [lang^=lt] form input[type=checkbox] + label {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru form input[type=checkbox] + label strong, .pl-pl form input[type=checkbox] + label strong, .hu-hu form input[type=checkbox] + label strong, .cs-cz form input[type=checkbox] + label strong, .et-ee form input[type=checkbox] + label strong, .lv-lv form input[type=checkbox] + label strong, .lt-lt form input[type=checkbox] + label strong, [lang^=ru] form input[type=checkbox] + label strong, [lang^=pl] form input[type=checkbox] + label strong, [lang^=hu] form input[type=checkbox] + label strong, [lang^=cs] form input[type=checkbox] + label strong, [lang^=et] form input[type=checkbox] + label strong, [lang^=lv] form input[type=checkbox] + label strong, [lang^=lt] form input[type=checkbox] + label strong {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru form input[type=checkbox] + label b, .pl-pl form input[type=checkbox] + label b, .hu-hu form input[type=checkbox] + label b, .cs-cz form input[type=checkbox] + label b, .et-ee form input[type=checkbox] + label b, .lv-lv form input[type=checkbox] + label b, .lt-lt form input[type=checkbox] + label b, [lang^=ru] form input[type=checkbox] + label b, [lang^=pl] form input[type=checkbox] + label b, [lang^=hu] form input[type=checkbox] + label b, [lang^=cs] form input[type=checkbox] + label b, [lang^=et] form input[type=checkbox] + label b, [lang^=lv] form input[type=checkbox] + label b, [lang^=lt] form input[type=checkbox] + label b {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
form input[type=checkbox] + label:before {
  cursor: pointer;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #383739;
  border-radius: 2px;
}
form input[type=checkbox]:checked + label:before {
  background: url("~img/svg/tick.svg") no-repeat center;
  background-size: 14px 14px;
}
form input[type=text]:not(:placeholder-shown) + label,
form textarea:not(:placeholder-shown) + label {
  opacity: 1;
  transform: translateY(-100%);
}
form input[type=text],
form textarea {
  display: block;
  height: 45px;
  width: 100%;
  padding: 10px 8px 9px;
  border: none;
  background-color: transparent;
  border-bottom: 1px solid #000;
  appearance: none;
  border-radius: 0;
  font-size: 14px;
  color: #000;
  transition: opacity 0.3s ease;
  position: relative;
  z-index: 2;
  font-family: "Helvetica Neue LT W05_63 Md Ex", Helvetica, sans-serif;
}
.ru-ru form input[type=text], .pl-pl form input[type=text], .hu-hu form input[type=text], .cs-cz form input[type=text], .et-ee form input[type=text], .lv-lv form input[type=text], .lt-lt form input[type=text], [lang^=ru] form input[type=text], [lang^=pl] form input[type=text], [lang^=hu] form input[type=text], [lang^=cs] form input[type=text], [lang^=et] form input[type=text], [lang^=lv] form input[type=text], [lang^=lt] form input[type=text],
.ru-ru form textarea,
.pl-pl form textarea,
.hu-hu form textarea,
.cs-cz form textarea,
.et-ee form textarea,
.lv-lv form textarea,
.lt-lt form textarea,
[lang^=ru] form textarea,
[lang^=pl] form textarea,
[lang^=hu] form textarea,
[lang^=cs] form textarea,
[lang^=et] form textarea,
[lang^=lv] form textarea,
[lang^=lt] form textarea {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru form input[type=text] strong, .pl-pl form input[type=text] strong, .hu-hu form input[type=text] strong, .cs-cz form input[type=text] strong, .et-ee form input[type=text] strong, .lv-lv form input[type=text] strong, .lt-lt form input[type=text] strong, [lang^=ru] form input[type=text] strong, [lang^=pl] form input[type=text] strong, [lang^=hu] form input[type=text] strong, [lang^=cs] form input[type=text] strong, [lang^=et] form input[type=text] strong, [lang^=lv] form input[type=text] strong, [lang^=lt] form input[type=text] strong,
.ru-ru form textarea strong,
.pl-pl form textarea strong,
.hu-hu form textarea strong,
.cs-cz form textarea strong,
.et-ee form textarea strong,
.lv-lv form textarea strong,
.lt-lt form textarea strong,
[lang^=ru] form textarea strong,
[lang^=pl] form textarea strong,
[lang^=hu] form textarea strong,
[lang^=cs] form textarea strong,
[lang^=et] form textarea strong,
[lang^=lv] form textarea strong,
[lang^=lt] form textarea strong {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru form input[type=text] b, .pl-pl form input[type=text] b, .hu-hu form input[type=text] b, .cs-cz form input[type=text] b, .et-ee form input[type=text] b, .lv-lv form input[type=text] b, .lt-lt form input[type=text] b, [lang^=ru] form input[type=text] b, [lang^=pl] form input[type=text] b, [lang^=hu] form input[type=text] b, [lang^=cs] form input[type=text] b, [lang^=et] form input[type=text] b, [lang^=lv] form input[type=text] b, [lang^=lt] form input[type=text] b,
.ru-ru form textarea b,
.pl-pl form textarea b,
.hu-hu form textarea b,
.cs-cz form textarea b,
.et-ee form textarea b,
.lv-lv form textarea b,
.lt-lt form textarea b,
[lang^=ru] form textarea b,
[lang^=pl] form textarea b,
[lang^=hu] form textarea b,
[lang^=cs] form textarea b,
[lang^=et] form textarea b,
[lang^=lv] form textarea b,
[lang^=lt] form textarea b {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
form input[type=text]::placeholder,
form textarea::placeholder {
  color: #818483;
}
form input[type=text]:focus,
form textarea:focus {
  border-bottom: 1px solid #000;
}
form input[type=text]:-webkit-autofill,
form textarea:-webkit-autofill {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #000;
}
form textarea {
  max-width: 100%;
  min-width: 100%;
}
form label .btn-group {
  margin-left: 8px;
}
form .btn-group {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
form .btn-group__btn {
  padding: 5px 10px;
  background-color: #a90000;
  color: #fff;
}
form .btn-group__btn.active {
  background-color: #db011c;
}

.form__inventory {
  max-width: 600px;
  margin: 0 auto;
}
.form__inventory-section {
  margin-bottom: 40px;
}
.form__inventory-section h3 + p {
  margin-top: -10px;
}
.form__left .form__title {
  text-align: left;
}

.flag {
  display: block;
  height: 25px;
  background: url("~img/flags.png") 0 0 no-repeat;
  background-size: 36px;
  width: 36px;
  min-width: 36px;
}
.flag.cs-CZ {
  background-position: 0 -30px;
}
.flag.da-DK {
  background-position: 0 -60px;
}
.flag.de-DE {
  background-position: 0 -90px;
}
.flag.en-GB {
  background-position: 0 -120px;
}
.flag.es-ES {
  background-position: 0 -150px;
}
.flag.fi-FI {
  background-position: 0 -180px;
}
.flag.fr-FR {
  background-position: 0 -210px;
}
.flag.hu-HU {
  background-position: 0 -240px;
}
.flag.it-IT {
  background-position: 0 -270px;
}
.flag.nl-NL {
  background-position: 0 -300px;
}
.flag.nn-NO {
  background-position: 0 -330px;
}
.flag.pl-PL {
  background-position: 0 -360px;
}
.flag.ru-RU {
  background-position: 0 -390px;
}
.flag.sv-SE {
  background-position: 0 -420px;
}
.flag.be-BE, .flag.nl-BE, .flag.fr-BE {
  background-position: 0 -450px;
}
.flag.lt-LT {
  background-position: 0 -480px;
}
.flag.lv-LV {
  background-position: 0 -510px;
}
.flag.et-EE {
  background-position: 0 -540px;
}
.flag.de-CH {
  background-position: 0 -569px;
}
.flag.ar-AE {
  background-position: 0 -599px;
}
.flag.en-ZA {
  background-position: 0 -629px;
}
.flag.pt-PT {
  background-position: 0 -659px;
}
.flag.sk-SK {
  background-position: 0px -688px;
}
.flag.tr-TR {
  background-position: 0 -721px;
}
.flag.de-AT {
  background-position: 0 -750px;
}
.flag.fr-LU {
  background-position: 0 -780px;
}
.flag.ro-RO {
  background-position: 0 -810px;
}

.legacy-warning {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.legacy-warning__inner {
  position: relative;
  z-index: 1;
  text-align: center;
  padding: 20px;
}
.legacy-warning a {
  color: #db011c;
}

.number--hidden {
  opacity: 0;
  pointer-events: none;
}
.number__wrapper {
  height: 38px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.number__inner {
  height: 100%;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.number__minus {
  width: 38px;
  height: 100%;
  outline: none;
  border: none;
  background: none;
  position: relative;
  padding: 0px;
}
.number__minus:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 12px;
  height: 2px;
  background-color: #000;
  transform: translate(-50%, -50%);
}
.number__plus {
  width: 38px;
  height: 100%;
  outline: none;
  border: none;
  background: none;
  position: relative;
  padding: 0px;
}
.number__plus:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 12px;
  height: 2px;
  background-color: #000;
  transform: translate(-50%, -50%);
}
.number__plus:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 12px;
  height: 2px;
  background-color: #000;
  transform: translate(-50%, -50%) rotate(90deg);
}
.number__value {
  font-family: "Helvetica Neue LT W05_53 Ext", Helvetica, sans-serif;
  background: none;
  border: none;
  outline: none;
  font-size: 14px;
  width: 28px;
  text-align: center;
  -moz-appearance: textfield;
  /* Chrome, Safari, Edge, Opera */
}
.ru-ru .number__value, .pl-pl .number__value, .hu-hu .number__value, .cs-cz .number__value, .et-ee .number__value, .lv-lv .number__value, .lt-lt .number__value, [lang^=ru] .number__value, [lang^=pl] .number__value, [lang^=hu] .number__value, [lang^=cs] .number__value, [lang^=et] .number__value, [lang^=lv] .number__value, [lang^=lt] .number__value {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru .number__value strong, .pl-pl .number__value strong, .hu-hu .number__value strong, .cs-cz .number__value strong, .et-ee .number__value strong, .lv-lv .number__value strong, .lt-lt .number__value strong, [lang^=ru] .number__value strong, [lang^=pl] .number__value strong, [lang^=hu] .number__value strong, [lang^=cs] .number__value strong, [lang^=et] .number__value strong, [lang^=lv] .number__value strong, [lang^=lt] .number__value strong {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru .number__value b, .pl-pl .number__value b, .hu-hu .number__value b, .cs-cz .number__value b, .et-ee .number__value b, .lv-lv .number__value b, .lt-lt .number__value b, [lang^=ru] .number__value b, [lang^=pl] .number__value b, [lang^=hu] .number__value b, [lang^=cs] .number__value b, [lang^=et] .number__value b, [lang^=lv] .number__value b, [lang^=lt] .number__value b {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.number__value::-webkit-outer-spin-button, .number__value::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.list {
  display: grid;
  grid-template-columns: 1fr;
  transition: opacity 175ms ease;
  opacity: 1;
}
.list--hidden {
  opacity: 0;
}
.list-item {
  text-align: left;
  padding: 12px 32px 12px 12px;
  position: relative;
}
.list-item:nth-child(even) {
  background-color: #f2f2f2;
}
.list-item__remove {
  width: 14px;
  height: 14px;
  background-image: url("~@img/svg/delete.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  opacity: 0;
  pointer-events: none;
  transition: opacity 175ms ease;
}
.list-item:hover .list-item__remove {
  opacity: 1;
  pointer-events: all;
}

.dropdown {
  padding: 5px;
  margin: 0px 10px;
}
.dropdown:focus {
  border: 1px solid black;
}

.close-subnav {
  width: 25px;
  padding-left: 5px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.close-subnav__icon {
  display: block;
  width: 100%;
  height: 25px;
  background-image: url("~img/svg/plus.svg");
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  transform: rotate(45deg);
}
.close-subnav__icon--black {
  background-image: url("~img/svg/plus--black.svg");
}
.close-subnav__icon:hover {
  cursor: pointer;
}

.navigation {
  width: 200px;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #383739;
  display: flex;
  flex-direction: column;
  z-index: 1000;
}
.navigation-logo {
  width: 100%;
  height: 60px;
  background-color: #db011c;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: nowrap;
}
.navigation-logo img {
  height: 30px;
  width: 70px;
  margin: 0 auto;
}
.navigation__content {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  padding-top: 5px;
  position: relative;
}
.navigation__content--bottom {
  margin-top: auto;
}
.navigation__flag {
  width: 100%;
  height: 50px;
  cursor: pointer;
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 10px;
}
.navigation__flag:hover button > p {
  opacity: 1;
}
.navigation__flag button {
  width: 100%;
}
.navigation__flag button p {
  transition: opacity 375ms ease;
  opacity: 0.3;
}
.navigation__flag p {
  width: 100%;
  padding-left: 50px;
  text-align: left;
  color: #fff;
  margin: 0;
  opacity: 0.3;
}
.navigation__icon {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 15px;
  width: 100%;
  height: 50px;
  transition: opacity 375ms ease;
  opacity: 0.3;
  cursor: pointer;
}
.navigation__icon:disabled {
  opacity: 0.2;
  cursor: not-allowed;
}
.navigation__icon:disabled:hover {
  opacity: 0.2;
}
.navigation__icon p {
  width: 100%;
  padding-left: 50px;
  text-align: left;
  color: #fff;
  margin: 0;
}
.navigation__icon:hover, .navigation__icon.active {
  opacity: 1;
}
.navigation__icon--plus {
  background-image: url("~@img/svg/plus.svg");
  background-size: 18px 18px;
}
.navigation__icon--list {
  background-image: url("~@img/svg/list.svg");
  background-size: 20px 14px;
}
.navigation__icon--custom-bars {
  background-image: url("~@img/svg/icon--custom-bars.svg");
  background-size: 27px;
}
.navigation__icon--background {
  background-image: url("~@img/svg/icon--bg-no.svg");
  background-size: 25px;
  background-position: 13px;
}
.navigation__icon--background:hover {
  opacity: 1 !important;
}
.navigation__icon--background.active {
  background-image: url("~@img/svg/icon--bg.svg");
  opacity: 0.3;
}
.navigation__icon--bin {
  background-image: url("~@img/svg/bin.svg");
  background-size: 15px 20px;
}
.navigation__icon--help {
  background-image: url("~@img/svg/help.svg");
  background-size: 15px 20px;
}
.navigation__icon--save {
  background-image: url("~@img/svg/save.svg");
  background-size: 20px 20px;
}
.navigation__icon--print {
  background-image: url("~@img/svg/icon--printer.svg");
  background-size: 26px 26px;
}
.navigation__icon--grid {
  background-image: url("~@img/svg/icon--grid.svg");
  background-size: 20px 20px;
}
.navigation__icon--grid.active {
  background-image: url("~@img/svg/icon--no-grid.svg");
}
.navigation__icon--pdf {
  background-image: url("~@img/svg/icon--pdf.svg");
  background-size: 20px 20px;
}
.navigation__icon--image {
  background-image: url("~@img/svg/icon--no-image.svg");
  background-size: 20px 20px;
}
.navigation__icon--image:hover {
  opacity: 1 !important;
}
.navigation__icon--image.active {
  background-image: url("~@img/svg/icon--image.svg");
  opacity: 0.3;
}
.navigation__icon--undo {
  background-image: url("~@img/svg/icon--undo.svg");
  background-size: 20px 20px;
}
.navigation__icon--redo {
  background-image: url("~@img/svg/icon--redo.svg");
  background-size: 20px 20px;
}
.navigation__icon--zoom-in {
  background-image: url("~@img/svg/icon--zoom-in.svg");
  background-size: 25px 25px;
}
.navigation__icon--zoom-out {
  background-image: url("~@img/svg/icon--zoom-out.svg");
  background-size: 25px 25px;
}
.navigation__icon--sign-out {
  background-image: url("~@img/svg/icon--sign-out.svg");
  background-size: 25px 25px;
}
.navigation__icon--modal {
  background-image: url("~@img/svg/help.svg");
  background-size: 26px 20px;
}
.navigation__tooltip {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
}
.navigation__tooltip:hover .navigation__tooltip-copy {
  border-left: 10px solid #1c1c1d;
  opacity: 1;
}
.navigation__tooltip-copy {
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translateY(-50%);
  padding: 0px 10px;
  color: #fff;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  font-size: 12px;
  height: 95%;
  display: flex;
  align-items: center;
  pointer-events: none;
  white-space: nowrap;
  z-index: -1;
  background-color: #383739;
  border-left: 5px solid #1c1c1d;
  opacity: 0.65;
  transition: border 350ms ease, opacity 350ms ease;
}

.sub-nav {
  position: fixed;
  left: 200px;
  z-index: 1000;
  width: 400px;
  box-shadow: 4px 0px 6px 0px rgba(0, 0, 0, 0.2);
}
.sub-nav > .nav-list {
  left: 0px;
}
.sub-nav__inner {
  height: 100vh;
  background-color: #fff;
}
.sub-nav__submit {
  width: 100%;
  height: 50px;
  position: absolute;
  bottom: 0px;
  background-color: #db011c;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.sub-nav__submit:disabled {
  cursor: not-allowed;
  background-color: #f199a4;
}
.sub-nav__submit-text {
  font-family: "Helvetica Neue LT W05_63 Md Ex", Helvetica, sans-serif;
  text-transform: uppercase;
  font-size: 14px;
}
.ru-ru .sub-nav__submit-text, .pl-pl .sub-nav__submit-text, .hu-hu .sub-nav__submit-text, .cs-cz .sub-nav__submit-text, .et-ee .sub-nav__submit-text, .lv-lv .sub-nav__submit-text, .lt-lt .sub-nav__submit-text, [lang^=ru] .sub-nav__submit-text, [lang^=pl] .sub-nav__submit-text, [lang^=hu] .sub-nav__submit-text, [lang^=cs] .sub-nav__submit-text, [lang^=et] .sub-nav__submit-text, [lang^=lv] .sub-nav__submit-text, [lang^=lt] .sub-nav__submit-text {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru .sub-nav__submit-text strong, .pl-pl .sub-nav__submit-text strong, .hu-hu .sub-nav__submit-text strong, .cs-cz .sub-nav__submit-text strong, .et-ee .sub-nav__submit-text strong, .lv-lv .sub-nav__submit-text strong, .lt-lt .sub-nav__submit-text strong, [lang^=ru] .sub-nav__submit-text strong, [lang^=pl] .sub-nav__submit-text strong, [lang^=hu] .sub-nav__submit-text strong, [lang^=cs] .sub-nav__submit-text strong, [lang^=et] .sub-nav__submit-text strong, [lang^=lv] .sub-nav__submit-text strong, [lang^=lt] .sub-nav__submit-text strong {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru .sub-nav__submit-text b, .pl-pl .sub-nav__submit-text b, .hu-hu .sub-nav__submit-text b, .cs-cz .sub-nav__submit-text b, .et-ee .sub-nav__submit-text b, .lv-lv .sub-nav__submit-text b, .lt-lt .sub-nav__submit-text b, [lang^=ru] .sub-nav__submit-text b, [lang^=pl] .sub-nav__submit-text b, [lang^=hu] .sub-nav__submit-text b, [lang^=cs] .sub-nav__submit-text b, [lang^=et] .sub-nav__submit-text b, [lang^=lv] .sub-nav__submit-text b, [lang^=lt] .sub-nav__submit-text b {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}

.nav-list {
  min-width: 375px;
  max-width: 425px;
  height: auto;
  max-height: calc(100vh - 60px - 60px);
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #383739;
  color: #fff;
  position: absolute;
  left: 200px;
  top: 0px;
}
.nav-list__header {
  padding: 20px 8px 8px 8px;
}
.nav-list__header h5 {
  margin-bottom: 20px;
}
.nav-list ul {
  margin: 0;
  padding: 5px;
  list-style: none;
}
.nav-list ul li {
  font-family: "Helvetica Neue LT W05_53 Ext", Helvetica, sans-serif;
  font-size: 12px;
  padding: 20px 40px 20px 20px;
  position: relative;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
  user-select: none;
}
.ru-ru .nav-list ul li, .pl-pl .nav-list ul li, .hu-hu .nav-list ul li, .cs-cz .nav-list ul li, .et-ee .nav-list ul li, .lv-lv .nav-list ul li, .lt-lt .nav-list ul li, [lang^=ru] .nav-list ul li, [lang^=pl] .nav-list ul li, [lang^=hu] .nav-list ul li, [lang^=cs] .nav-list ul li, [lang^=et] .nav-list ul li, [lang^=lv] .nav-list ul li, [lang^=lt] .nav-list ul li {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru .nav-list ul li strong, .pl-pl .nav-list ul li strong, .hu-hu .nav-list ul li strong, .cs-cz .nav-list ul li strong, .et-ee .nav-list ul li strong, .lv-lv .nav-list ul li strong, .lt-lt .nav-list ul li strong, [lang^=ru] .nav-list ul li strong, [lang^=pl] .nav-list ul li strong, [lang^=hu] .nav-list ul li strong, [lang^=cs] .nav-list ul li strong, [lang^=et] .nav-list ul li strong, [lang^=lv] .nav-list ul li strong, [lang^=lt] .nav-list ul li strong {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru .nav-list ul li b, .pl-pl .nav-list ul li b, .hu-hu .nav-list ul li b, .cs-cz .nav-list ul li b, .et-ee .nav-list ul li b, .lv-lv .nav-list ul li b, .lt-lt .nav-list ul li b, [lang^=ru] .nav-list ul li b, [lang^=pl] .nav-list ul li b, [lang^=hu] .nav-list ul li b, [lang^=cs] .nav-list ul li b, [lang^=et] .nav-list ul li b, [lang^=lv] .nav-list ul li b, [lang^=lt] .nav-list ul li b {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.nav-list ul li.has-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.nav-list ul li:hover {
  background-color: #000;
}
.nav-list ul li .icon {
  margin-right: 15px;
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}
.nav-list ul li .icon--inline {
  display: block;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  margin-right: 0px;
}
.nav-list ul li .icon--remove {
  background-image: url("~@img/svg/remove.svg");
  width: 14px;
  height: 14px;
}
.nav-list ul li .icon--plus {
  background-image: url("~@img/svg/plus.svg");
  width: 18px;
  height: 18px;
}
.nav-list ul li .icon--search {
  background-image: url("~@img/svg/search.svg");
  width: 20px;
  height: 20px;
}
.nav-list ul li .icon--page {
  background-image: url("~@img/svg/page.svg");
  width: 24px;
  height: 24px;
}
.nav-list ul li .icon--bars {
  background-image: url("~@img/svg/icon--bars.svg");
  width: 24px;
  height: 24px;
}
.nav-list ul li .icon--custom-bars {
  background-image: url("~@img/svg/icon--custom-bars.svg");
  width: 24px;
  height: 24px;
}
.nav-list ul li .icon--placeholder {
  background-image: url("~@img/svg/icon--aspect-ratio.svg");
  width: 24px;
  height: 24px;
}
.nav-list ul li .icon--chevron {
  background-image: url("~@img/svg/chevron.svg");
  width: 16px;
  height: 16px;
}
.nav-list ul li .icon--back {
  background-image: url("~@img/svg/chevron.svg");
  width: 16px;
  height: 16px;
  transform: rotate(180deg);
}

.an-import {
  height: 100vh;
  background-color: #fff;
  padding-bottom: 50px;
  overflow-y: auto;
}
.an-import__header {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.an-import__header h2 {
  margin: 0px;
  font-size: 14px;
  font-style: normal;
}
.an-import__inner {
  padding: 0px 15px;
}
.an-import__inner li:before {
  content: "- ";
  display: inline;
}
.an-import__input {
  width: 100%;
  resize: none;
  height: 500px;
  border-radius: 4px;
  padding: 15px;
  overflow-y: auto;
  overflow-x: hidden;
  border: 1px solid #b5b5b5;
}
.an-import__input.is-invalid {
  border: 1px solid #db011c;
}

.product-search {
  height: 100vh;
  position: relative;
}
.product-search__header {
  background: #383739;
  padding: 10px;
  height: 61px;
  display: flex;
}
.product-search__header input {
  border-radius: 4px;
  width: 95%;
  padding: 12px;
  font-family: "Helvetica Neue LT W05_53 Ext", Helvetica, sans-serif;
  font-size: 14px;
  color: #000;
  border: none;
  outline: none;
}
.ru-ru .product-search__header input, .pl-pl .product-search__header input, .hu-hu .product-search__header input, .cs-cz .product-search__header input, .et-ee .product-search__header input, .lv-lv .product-search__header input, .lt-lt .product-search__header input, [lang^=ru] .product-search__header input, [lang^=pl] .product-search__header input, [lang^=hu] .product-search__header input, [lang^=cs] .product-search__header input, [lang^=et] .product-search__header input, [lang^=lv] .product-search__header input, [lang^=lt] .product-search__header input {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru .product-search__header input strong, .pl-pl .product-search__header input strong, .hu-hu .product-search__header input strong, .cs-cz .product-search__header input strong, .et-ee .product-search__header input strong, .lv-lv .product-search__header input strong, .lt-lt .product-search__header input strong, [lang^=ru] .product-search__header input strong, [lang^=pl] .product-search__header input strong, [lang^=hu] .product-search__header input strong, [lang^=cs] .product-search__header input strong, [lang^=et] .product-search__header input strong, [lang^=lv] .product-search__header input strong, [lang^=lt] .product-search__header input strong {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru .product-search__header input b, .pl-pl .product-search__header input b, .hu-hu .product-search__header input b, .cs-cz .product-search__header input b, .et-ee .product-search__header input b, .lv-lv .product-search__header input b, .lt-lt .product-search__header input b, [lang^=ru] .product-search__header input b, [lang^=pl] .product-search__header input b, [lang^=hu] .product-search__header input b, [lang^=cs] .product-search__header input b, [lang^=et] .product-search__header input b, [lang^=lv] .product-search__header input b, [lang^=lt] .product-search__header input b {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.product-search__utils {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 10px;
}
.product-search__utils label {
  display: block;
}
.product-search__util {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #fff;
}
.product-search__message {
  padding: 20px;
  word-break: break-all;
}
.product-search__items {
  background-color: #fff;
  height: calc(100% - 61px - 50px);
  overflow-y: auto;
  overflow-x: hidden;
}
.product-search__item {
  text-align: left;
  padding: 20px;
  background-color: #f8f8f8;
  border-bottom: 2px solid #fff;
  user-select: none;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.product-search__item:hover, .product-search__item.active {
  background-color: #b5b5b5;
}
.product-search__item h2 {
  font-family: "Helvetica Neue LT W05_53 Ext", Helvetica, sans-serif;
  font-size: 12px;
  margin-bottom: 3px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
  font-style: normal;
}
.ru-ru .product-search__item h2, .pl-pl .product-search__item h2, .hu-hu .product-search__item h2, .cs-cz .product-search__item h2, .et-ee .product-search__item h2, .lv-lv .product-search__item h2, .lt-lt .product-search__item h2, [lang^=ru] .product-search__item h2, [lang^=pl] .product-search__item h2, [lang^=hu] .product-search__item h2, [lang^=cs] .product-search__item h2, [lang^=et] .product-search__item h2, [lang^=lv] .product-search__item h2, [lang^=lt] .product-search__item h2 {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru .product-search__item h2 strong, .pl-pl .product-search__item h2 strong, .hu-hu .product-search__item h2 strong, .cs-cz .product-search__item h2 strong, .et-ee .product-search__item h2 strong, .lv-lv .product-search__item h2 strong, .lt-lt .product-search__item h2 strong, [lang^=ru] .product-search__item h2 strong, [lang^=pl] .product-search__item h2 strong, [lang^=hu] .product-search__item h2 strong, [lang^=cs] .product-search__item h2 strong, [lang^=et] .product-search__item h2 strong, [lang^=lv] .product-search__item h2 strong, [lang^=lt] .product-search__item h2 strong {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru .product-search__item h2 b, .pl-pl .product-search__item h2 b, .hu-hu .product-search__item h2 b, .cs-cz .product-search__item h2 b, .et-ee .product-search__item h2 b, .lv-lv .product-search__item h2 b, .lt-lt .product-search__item h2 b, [lang^=ru] .product-search__item h2 b, [lang^=pl] .product-search__item h2 b, [lang^=hu] .product-search__item h2 b, [lang^=cs] .product-search__item h2 b, [lang^=et] .product-search__item h2 b, [lang^=lv] .product-search__item h2 b, [lang^=lt] .product-search__item h2 b {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.product-search__item h5 {
  color: #db011c;
}
.product-search__item div.product-search__details {
  flex-basis: 70%;
}
.product-search__item div.product-search__quantity {
  flex-basis: 30%;
}

.language-selector {
  position: relative;
}
.language-selector .current-language {
  cursor: pointer;
}
.language-selector .language-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  display: block;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 100;
}
.language-selector .language-grid {
  display: block;
  position: fixed;
  left: 50%;
  top: 50%;
  height: auto;
  transform: translate(-50%, -50%);
  z-index: 101;
}
.language-selector .language-grid-inner {
  display: flex;
  background-color: #fff;
  padding: 20px;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  width: 600px;
  box-shadow: 0px 0px 1px 1px #fff;
  border-radius: 2px;
}
.language-selector .language-grid-item {
  width: 50%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  padding: 20px;
  cursor: pointer;
}
.language-selector .language-grid-item:hover .flag-title {
  color: #db011c;
}
.language-selector .language-grid-close {
  position: absolute;
  top: 10px;
  right: 10px;
  display: block;
  z-index: 102;
  width: 15px;
  height: 15px;
  background-image: url("~img/svg/plus--black.svg");
  transform: rotate(45deg);
  background-position: center center;
  background-size: cover;
  cursor: pointer;
}
.language-selector .flag-title {
  font-family: "Helvetica Neue LT W01_55 Roman", Helvetica, sans-serif;
  display: block;
  font-size: 14px;
  text-align: left;
  padding: 0px 10px;
  color: #000;
}
.ru-ru .language-selector .flag-title, .pl-pl .language-selector .flag-title, .hu-hu .language-selector .flag-title, .cs-cz .language-selector .flag-title, .et-ee .language-selector .flag-title, .lv-lv .language-selector .flag-title, .lt-lt .language-selector .flag-title, [lang^=ru] .language-selector .flag-title, [lang^=pl] .language-selector .flag-title, [lang^=hu] .language-selector .flag-title, [lang^=cs] .language-selector .flag-title, [lang^=et] .language-selector .flag-title, [lang^=lv] .language-selector .flag-title, [lang^=lt] .language-selector .flag-title {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru .language-selector .flag-title strong, .pl-pl .language-selector .flag-title strong, .hu-hu .language-selector .flag-title strong, .cs-cz .language-selector .flag-title strong, .et-ee .language-selector .flag-title strong, .lv-lv .language-selector .flag-title strong, .lt-lt .language-selector .flag-title strong, [lang^=ru] .language-selector .flag-title strong, [lang^=pl] .language-selector .flag-title strong, [lang^=hu] .language-selector .flag-title strong, [lang^=cs] .language-selector .flag-title strong, [lang^=et] .language-selector .flag-title strong, [lang^=lv] .language-selector .flag-title strong, [lang^=lt] .language-selector .flag-title strong {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru .language-selector .flag-title b, .pl-pl .language-selector .flag-title b, .hu-hu .language-selector .flag-title b, .cs-cz .language-selector .flag-title b, .et-ee .language-selector .flag-title b, .lv-lv .language-selector .flag-title b, .lt-lt .language-selector .flag-title b, [lang^=ru] .language-selector .flag-title b, [lang^=pl] .language-selector .flag-title b, [lang^=hu] .language-selector .flag-title b, [lang^=cs] .language-selector .flag-title b, [lang^=et] .language-selector .flag-title b, [lang^=lv] .language-selector .flag-title b, [lang^=lt] .language-selector .flag-title b {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}

.alignment-modal {
  position: relative;
}
.alignment-modal .alignment-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  display: block;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 100;
}
.alignment-modal .alignment-grid {
  display: block;
  position: fixed;
  left: 50%;
  top: 50%;
  height: auto;
  transform: translate(-50%, -50%);
  z-index: 101;
}
.alignment-modal .alignment-grid-inner {
  display: flex;
  background-color: #fff;
  padding: 20px;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  max-width: 850px;
  min-width: 600px;
  width: 100%;
  box-shadow: 0px 0px 1px 1px #fff;
  border-radius: 2px;
  justify-content: center;
}
.alignment-modal .alignment-grid-inner .demo-section {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.alignment-modal .alignment-grid-inner .demo-section .alignment-demo {
  margin: 15px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  background-color: #f8f8f8;
  user-select: none;
  transition: background-color 0.2s ease-in-out;
  max-height: 500px;
}
.alignment-modal .alignment-grid-inner .demo-section .alignment-demo:hover {
  background-color: #ebe9e9;
}
.alignment-modal .alignment-grid-inner .demo-section .alignment-demo span {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  padding: 10px 0px;
  cursor: pointer;
}
.alignment-modal .alignment-grid-inner .demo-section .alignment-demo span svg {
  width: 1.25em;
  height: 1.25em;
}
.alignment-modal .alignment-grid-inner .demo-section .alignment-demo span h3 {
  text-align: center;
  padding: 10px 0px;
  font-size: 18px;
}
.alignment-modal .alignment-grid-inner .demo-section .alignment-demo--open .alignment-content {
  overflow: visible !important;
  opacity: 1 !important;
}
.alignment-modal .alignment-grid-inner .demo-section .alignment-demo .alignment-content {
  height: 0px;
  overflow: hidden;
  margin: auto;
  text-align: center;
  display: flex;
  gap: 10px;
  align-items: center;
  transition: height 0.5s ease-in-out, opacity 0.9s ease-in-out;
  opacity: 0;
}
.alignment-modal .alignment-grid-inner .demo-section .alignment-demo .alignment-content p {
  flex-basis: 50%;
}
.alignment-modal .alignment-grid-inner .demo-section .alignment-demo .alignment-content .alignment-content-image {
  height: 300px;
  flex-basis: 50%;
  align-items: center;
  display: flex;
}
.alignment-modal .alignment-grid-inner .demo-section .alignment-demo .alignment-content .alignment-content-image img {
  border: 1px solid #b5b5b5;
  border-radius: 5px;
  display: inline-block;
}
.alignment-modal .alignment-grid-close {
  position: absolute;
  top: 10px;
  right: 10px;
  display: block;
  z-index: 102;
  width: 15px;
  height: 15px;
  background-image: url("~img/svg/plus--black.svg");
  transform: rotate(45deg);
  background-position: center center;
  background-size: cover;
  cursor: pointer;
}

.grid-bar__modal {
  position: fixed;
  top: 0;
  left: 200px;
  width: calc(100% - 200px);
  height: 100vh;
  z-index: 999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.grid-bar__modal:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}
.grid-bar__form {
  background-color: #fff;
  padding: 20px;
}
.grid-bar__form input[type=text] {
  width: 380px;
}
.grid-bar__form--split {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.grid-bar__form-half {
  width: 50%;
}
.grid-bar__inner {
  display: inline-block;
  position: relative;
}
.grid-bar__tabs {
  position: absolute;
  top: 80px;
  left: 100%;
}
.grid-bar__tab {
  font-family: "Helvetica Neue LT W05_53 Ext", Helvetica, sans-serif;
  width: 36px;
  height: 36px;
  background: #a90000;
  color: #fff;
}
.ru-ru .grid-bar__tab, .pl-pl .grid-bar__tab, .hu-hu .grid-bar__tab, .cs-cz .grid-bar__tab, .et-ee .grid-bar__tab, .lv-lv .grid-bar__tab, .lt-lt .grid-bar__tab, [lang^=ru] .grid-bar__tab, [lang^=pl] .grid-bar__tab, [lang^=hu] .grid-bar__tab, [lang^=cs] .grid-bar__tab, [lang^=et] .grid-bar__tab, [lang^=lv] .grid-bar__tab, [lang^=lt] .grid-bar__tab {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru .grid-bar__tab strong, .pl-pl .grid-bar__tab strong, .hu-hu .grid-bar__tab strong, .cs-cz .grid-bar__tab strong, .et-ee .grid-bar__tab strong, .lv-lv .grid-bar__tab strong, .lt-lt .grid-bar__tab strong, [lang^=ru] .grid-bar__tab strong, [lang^=pl] .grid-bar__tab strong, [lang^=hu] .grid-bar__tab strong, [lang^=cs] .grid-bar__tab strong, [lang^=et] .grid-bar__tab strong, [lang^=lv] .grid-bar__tab strong, [lang^=lt] .grid-bar__tab strong {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru .grid-bar__tab b, .pl-pl .grid-bar__tab b, .hu-hu .grid-bar__tab b, .cs-cz .grid-bar__tab b, .et-ee .grid-bar__tab b, .lv-lv .grid-bar__tab b, .lt-lt .grid-bar__tab b, [lang^=ru] .grid-bar__tab b, [lang^=pl] .grid-bar__tab b, [lang^=hu] .grid-bar__tab b, [lang^=cs] .grid-bar__tab b, [lang^=et] .grid-bar__tab b, [lang^=lv] .grid-bar__tab b, [lang^=lt] .grid-bar__tab b {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.grid-bar__tab.active {
  background: #db011c;
}
.grid-bar__footer {
  background-color: #fff;
  position: relative;
  margin-top: -5px;
  padding: 5px;
  text-align: center;
}
.grid-bar__footer .form-errors {
  margin-bottom: 10px;
  color: #db011c;
}
.grid-bar__footer .btn {
  margin-left: 20px;
}
.grid-bar__footer .btn:nth-child(1) {
  margin-left: 0px;
}
.grid-bar .grid {
  display: block;
}
.grid-bar .grid__wrapper {
  text-align: center;
}
.grid-bar .grid__container {
  border: 1px solid #db011c;
}

.overflow-grid {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.overflow-grid .grid__wrapper {
  background-color: transparent;
}

.grids-wrapper {
  text-align: center;
  position: relative;
  opacity: 0;
  animation: fade-in 500ms 100ms forwards ease;
  min-width: 100%;
  display: inline-grid;
  grid-auto-flow: column;
  grid-gap: 250px;
  margin: 0px 250px 48px 48px;
}
.grids-wrapper[data-reduce-space=true] {
  grid-gap: 0px;
}
.grids-wrapper[data-reduce-space=true] .grid-outer:nth-child(4n) {
  margin-right: 150px;
}

.grid {
  position: relative;
  z-index: 2;
  display: inline-block;
  transform: translateZ(1px);
  /* Calcs to convert integers (used in JS so kept as int) to px. */
}
.grid--shadow {
  box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.35);
}
.grid__wrapper {
  padding: 10px;
  background-color: #fff;
}
.grid__name-wrapper {
  position: absolute;
  z-index: 100;
  top: -15px;
  transform: translateY(-80%);
  display: flex;
  padding: 0px 40px;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.grid__name {
  font-family: "Helvetica Neue LT W05_85 Heavy", Helvetica, sans-serif;
  margin: 0px 10px;
}
.ru-ru .grid__name, .pl-pl .grid__name, .hu-hu .grid__name, .cs-cz .grid__name, .et-ee .grid__name, .lv-lv .grid__name, .lt-lt .grid__name, [lang^=ru] .grid__name, [lang^=pl] .grid__name, [lang^=hu] .grid__name, [lang^=cs] .grid__name, [lang^=et] .grid__name, [lang^=lv] .grid__name, [lang^=lt] .grid__name {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru .grid__name strong, .pl-pl .grid__name strong, .hu-hu .grid__name strong, .cs-cz .grid__name strong, .et-ee .grid__name strong, .lv-lv .grid__name strong, .lt-lt .grid__name strong, [lang^=ru] .grid__name strong, [lang^=pl] .grid__name strong, [lang^=hu] .grid__name strong, [lang^=cs] .grid__name strong, [lang^=et] .grid__name strong, [lang^=lv] .grid__name strong, [lang^=lt] .grid__name strong {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru .grid__name b, .pl-pl .grid__name b, .hu-hu .grid__name b, .cs-cz .grid__name b, .et-ee .grid__name b, .lv-lv .grid__name b, .lt-lt .grid__name b, [lang^=ru] .grid__name b, [lang^=pl] .grid__name b, [lang^=hu] .grid__name b, [lang^=cs] .grid__name b, [lang^=et] .grid__name b, [lang^=lv] .grid__name b, [lang^=lt] .grid__name b {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.grid__name:before {
  content: "";
  width: 23px;
  height: 23px;
  background-image: url("~@img/svg/icon--edit.svg");
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  vertical-align: bottom;
  margin-right: 5px;
}
.grid__delete {
  width: 15px;
  height: 20px;
  background-image: url("~@img/svg/bin--black.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  position: absolute;
  right: 10px;
  opacity: 0.5;
  transition: opacity 275ms ease;
}
.grid__delete:hover {
  opacity: 1;
}
.grid__loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
}
.grid__spinner {
  position: sticky;
  top: 50%;
  left: 50%;
  transform: translateY(-50%);
}
.grid__container {
  display: inline-block;
  background-repeat: repeat;
  position: relative;
}
.grid__container.show-dots {
  background-image: url("~@img/svg/dot.svg");
  background-size: calc(var(--grid-pitch) * 1px);
}
.grid__cells {
  position: relative;
  z-index: 2;
  display: grid;
  grid-template-columns: repeat(var(--grid-steps-x), calc(var(--grid-pitch) * 1px));
  grid-template-rows: repeat(var(--grid-steps-y), calc(var(--grid-pitch) * 1px));
  grid-auto-flow: dense;
  grid-gap: calc(var(--grid-gap) * 1px);
}
.grid__cells--absolute {
  margin-top: calc(((var(--grid-pitch) * 1px) * 2));
  position: absolute;
  height: calc(100% - (((var(--grid-pitch) * 1px) * 2)));
  width: 100%;
  display: block;
  grid-template-columns: none;
  grid-template-rows: none;
  overflow: none;
}
.grid__cells--overflow {
  overflow-y: auto;
}
.grid__drop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.grid-header {
  position: relative;
  z-index: 100;
  display: flex;
  align-items: center;
  border-bottom: 2px solid #b5b5b5;
  height: 60px;
  max-width: calc(100% - (48px * 2));
  margin: 0 auto 60px auto;
  position: sticky;
  left: 48px;
}
.grid-header__back {
  color: #231f20;
  text-decoration: none;
  display: block;
  text-transform: uppercase;
  font-family: "Helvetica Neue LT W05_53 Ext", Helvetica, sans-serif;
  font-size: 12px;
}
.ru-ru .grid-header__back, .pl-pl .grid-header__back, .hu-hu .grid-header__back, .cs-cz .grid-header__back, .et-ee .grid-header__back, .lv-lv .grid-header__back, .lt-lt .grid-header__back, [lang^=ru] .grid-header__back, [lang^=pl] .grid-header__back, [lang^=hu] .grid-header__back, [lang^=cs] .grid-header__back, [lang^=et] .grid-header__back, [lang^=lv] .grid-header__back, [lang^=lt] .grid-header__back {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru .grid-header__back strong, .pl-pl .grid-header__back strong, .hu-hu .grid-header__back strong, .cs-cz .grid-header__back strong, .et-ee .grid-header__back strong, .lv-lv .grid-header__back strong, .lt-lt .grid-header__back strong, [lang^=ru] .grid-header__back strong, [lang^=pl] .grid-header__back strong, [lang^=hu] .grid-header__back strong, [lang^=cs] .grid-header__back strong, [lang^=et] .grid-header__back strong, [lang^=lv] .grid-header__back strong, [lang^=lt] .grid-header__back strong {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru .grid-header__back b, .pl-pl .grid-header__back b, .hu-hu .grid-header__back b, .cs-cz .grid-header__back b, .et-ee .grid-header__back b, .lv-lv .grid-header__back b, .lt-lt .grid-header__back b, [lang^=ru] .grid-header__back b, [lang^=pl] .grid-header__back b, [lang^=hu] .grid-header__back b, [lang^=cs] .grid-header__back b, [lang^=et] .grid-header__back b, [lang^=lv] .grid-header__back b, [lang^=lt] .grid-header__back b {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.grid-header__back-arrow {
  width: 16px;
  height: 9px;
  margin: 0 10px 5px auto;
  background-image: url("~@img/svg/arrow--back.svg");
  display: inline-block;
  vertical-align: middle;
}
.grid-header__inner {
  margin: 0;
  padding: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.grid-header__edit-icon {
  width: 23px;
  height: 23px;
  background-image: url("~@img/svg/icon--edit.svg");
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  vertical-align: bottom;
  margin-right: 5px;
}
.grid-header__project-name {
  font-family: "Helvetica Neue LT W05_85 Heavy", Helvetica, sans-serif;
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 18px;
  display: inline-block;
  width: auto;
  max-width: 500px;
  min-width: auto;
  position: relative;
}
.ru-ru .grid-header__project-name, .pl-pl .grid-header__project-name, .hu-hu .grid-header__project-name, .cs-cz .grid-header__project-name, .et-ee .grid-header__project-name, .lv-lv .grid-header__project-name, .lt-lt .grid-header__project-name, [lang^=ru] .grid-header__project-name, [lang^=pl] .grid-header__project-name, [lang^=hu] .grid-header__project-name, [lang^=cs] .grid-header__project-name, [lang^=et] .grid-header__project-name, [lang^=lv] .grid-header__project-name, [lang^=lt] .grid-header__project-name {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru .grid-header__project-name strong, .pl-pl .grid-header__project-name strong, .hu-hu .grid-header__project-name strong, .cs-cz .grid-header__project-name strong, .et-ee .grid-header__project-name strong, .lv-lv .grid-header__project-name strong, .lt-lt .grid-header__project-name strong, [lang^=ru] .grid-header__project-name strong, [lang^=pl] .grid-header__project-name strong, [lang^=hu] .grid-header__project-name strong, [lang^=cs] .grid-header__project-name strong, [lang^=et] .grid-header__project-name strong, [lang^=lv] .grid-header__project-name strong, [lang^=lt] .grid-header__project-name strong {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru .grid-header__project-name b, .pl-pl .grid-header__project-name b, .hu-hu .grid-header__project-name b, .cs-cz .grid-header__project-name b, .et-ee .grid-header__project-name b, .lv-lv .grid-header__project-name b, .lt-lt .grid-header__project-name b, [lang^=ru] .grid-header__project-name b, [lang^=pl] .grid-header__project-name b, [lang^=hu] .grid-header__project-name b, [lang^=cs] .grid-header__project-name b, [lang^=et] .grid-header__project-name b, [lang^=lv] .grid-header__project-name b, [lang^=lt] .grid-header__project-name b {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.grid-header__project-name:focus {
  color: #db011c;
}
.grid-header__status {
  font-family: "Helvetica Neue LT W05_53 Ext", Helvetica, sans-serif;
  color: #707070;
  user-select: none;
}
.ru-ru .grid-header__status, .pl-pl .grid-header__status, .hu-hu .grid-header__status, .cs-cz .grid-header__status, .et-ee .grid-header__status, .lv-lv .grid-header__status, .lt-lt .grid-header__status, [lang^=ru] .grid-header__status, [lang^=pl] .grid-header__status, [lang^=hu] .grid-header__status, [lang^=cs] .grid-header__status, [lang^=et] .grid-header__status, [lang^=lv] .grid-header__status, [lang^=lt] .grid-header__status {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru .grid-header__status strong, .pl-pl .grid-header__status strong, .hu-hu .grid-header__status strong, .cs-cz .grid-header__status strong, .et-ee .grid-header__status strong, .lv-lv .grid-header__status strong, .lt-lt .grid-header__status strong, [lang^=ru] .grid-header__status strong, [lang^=pl] .grid-header__status strong, [lang^=hu] .grid-header__status strong, [lang^=cs] .grid-header__status strong, [lang^=et] .grid-header__status strong, [lang^=lv] .grid-header__status strong, [lang^=lt] .grid-header__status strong {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru .grid-header__status b, .pl-pl .grid-header__status b, .hu-hu .grid-header__status b, .cs-cz .grid-header__status b, .et-ee .grid-header__status b, .lv-lv .grid-header__status b, .lt-lt .grid-header__status b, [lang^=ru] .grid-header__status b, [lang^=pl] .grid-header__status b, [lang^=hu] .grid-header__status b, [lang^=cs] .grid-header__status b, [lang^=et] .grid-header__status b, [lang^=lv] .grid-header__status b, [lang^=lt] .grid-header__status b {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}

.grid-bar-header {
  height: 40px;
  background-color: #db011c;
  padding: 5px;
  position: relative;
  display: grid;
  grid-template-columns: 70px 1fr 70px;
  align-items: center;
}
.grid-bar-header__logo {
  display: block;
  height: 100%;
}
.grid-bar-header__title {
  margin: 0;
  color: white;
  font-family: "Helvetica Neue LT W05_63 Md Ex", Helvetica, sans-serif;
  font-size: 22px;
  text-align: center;
}
.ru-ru .grid-bar-header__title, .pl-pl .grid-bar-header__title, .hu-hu .grid-bar-header__title, .cs-cz .grid-bar-header__title, .et-ee .grid-bar-header__title, .lv-lv .grid-bar-header__title, .lt-lt .grid-bar-header__title, [lang^=ru] .grid-bar-header__title, [lang^=pl] .grid-bar-header__title, [lang^=hu] .grid-bar-header__title, [lang^=cs] .grid-bar-header__title, [lang^=et] .grid-bar-header__title, [lang^=lv] .grid-bar-header__title, [lang^=lt] .grid-bar-header__title {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru .grid-bar-header__title strong, .pl-pl .grid-bar-header__title strong, .hu-hu .grid-bar-header__title strong, .cs-cz .grid-bar-header__title strong, .et-ee .grid-bar-header__title strong, .lv-lv .grid-bar-header__title strong, .lt-lt .grid-bar-header__title strong, [lang^=ru] .grid-bar-header__title strong, [lang^=pl] .grid-bar-header__title strong, [lang^=hu] .grid-bar-header__title strong, [lang^=cs] .grid-bar-header__title strong, [lang^=et] .grid-bar-header__title strong, [lang^=lv] .grid-bar-header__title strong, [lang^=lt] .grid-bar-header__title strong {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru .grid-bar-header__title b, .pl-pl .grid-bar-header__title b, .hu-hu .grid-bar-header__title b, .cs-cz .grid-bar-header__title b, .et-ee .grid-bar-header__title b, .lv-lv .grid-bar-header__title b, .lt-lt .grid-bar-header__title b, [lang^=ru] .grid-bar-header__title b, [lang^=pl] .grid-bar-header__title b, [lang^=hu] .grid-bar-header__title b, [lang^=cs] .grid-bar-header__title b, [lang^=et] .grid-bar-header__title b, [lang^=lv] .grid-bar-header__title b, [lang^=lt] .grid-bar-header__title b {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.grid-bar-header__close {
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  background-image: url("~@img/svg/plus.svg");
  background-repeat: no-repeat;
  background-size: contain;
  transform: rotate(45deg);
  width: 20px;
  height: 20px;
  z-index: 10;
}

.wb-cell {
  position: relative;
  grid-column: span 1;
  grid-row: span 1;
  cursor: grab;
  border: 1px solid white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1;
  font-size: 8px;
  background-color: rgba(219, 1, 28, 0.4);
  transition: opacity 275ms ease;
}
.wb-cell--placeholder {
  background-color: rgba(60, 60, 60, 0.4);
}
.wb-cell--placeholder .wb-cell__an {
  color: black;
}
.wb-cell--bar {
  background-color: rgba(156, 119, 119, 0.2);
  transform-style: preserve-3d;
  perspective: 400px;
}
.wb-cell--bar > .wb-cell__bg {
  background-image: none;
}
.wb-cell--bar.level--0 {
  border: none;
}
.wb-cell--bar.level--1 {
  border: none;
}
.wb-cell--bar.level--1 .grid__cells--absolute {
  transform: translateZ(15px);
  filter: drop-shadow(2px 4px 4px black);
}
.wb-cell--bar.level--2 {
  border: none;
}
.wb-cell--bar.level--2 .grid__cells--absolute {
  transform: translateZ(25px);
  filter: drop-shadow(2px 4px 6px black);
}
.wb-cell__bg {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 90%;
  height: 90%;
  transform: translate(-50%, -50%);
  background-image: url("~@img/svg/logo.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 0;
}
.wb-cell__bg img {
  object-fit: contain;
  object-position: center top;
  width: 100%;
  height: 100%;
}
.wb-cell__inner {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1 !important;
}
.wb-cell__an {
  position: absolute;
  text-align: center;
  width: 100%;
  bottom: 6px;
  letter-spacing: 1.2px;
}
.wb-cell__controls {
  position: absolute;
  left: 100%;
  top: 0px;
  z-index: 100;
  pointer-events: all;
  z-index: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.wb-cell__controls__1 {
  top: 20px;
}
.wb-cell__controls__2 {
  top: 52px;
}
.wb-cell__control {
  padding: 4px;
  background-color: #c5c5c5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.wb-cell__control--red {
  background-color: #db011c;
}
.wb-cell__corners {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.wb-cell__corners span {
  position: absolute;
  background-image: url("~@img/svg/icon--corner.svg");
  width: 20px;
  height: 20px;
}
.wb-cell__corners span:nth-child(1) {
  top: 0;
  left: 0;
}
.wb-cell__corners span:nth-child(2) {
  top: 0;
  right: 0;
  transform: rotate(90deg);
}
.wb-cell__corners span:nth-child(3) {
  bottom: 0;
  right: 0;
  transform: rotate(180deg);
}
.wb-cell__corners span:nth-child(4) {
  bottom: 0;
  left: 0;
  transform: rotate(-90deg);
}
.wb-cell__tag {
  font-family: "Helvetica Neue LT W01_55 Roman", Helvetica, sans-serif;
  color: #fff;
  font-size: 14px;
}
.ru-ru .wb-cell__tag, .pl-pl .wb-cell__tag, .hu-hu .wb-cell__tag, .cs-cz .wb-cell__tag, .et-ee .wb-cell__tag, .lv-lv .wb-cell__tag, .lt-lt .wb-cell__tag, [lang^=ru] .wb-cell__tag, [lang^=pl] .wb-cell__tag, [lang^=hu] .wb-cell__tag, [lang^=cs] .wb-cell__tag, [lang^=et] .wb-cell__tag, [lang^=lv] .wb-cell__tag, [lang^=lt] .wb-cell__tag {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru .wb-cell__tag strong, .pl-pl .wb-cell__tag strong, .hu-hu .wb-cell__tag strong, .cs-cz .wb-cell__tag strong, .et-ee .wb-cell__tag strong, .lv-lv .wb-cell__tag strong, .lt-lt .wb-cell__tag strong, [lang^=ru] .wb-cell__tag strong, [lang^=pl] .wb-cell__tag strong, [lang^=hu] .wb-cell__tag strong, [lang^=cs] .wb-cell__tag strong, [lang^=et] .wb-cell__tag strong, [lang^=lv] .wb-cell__tag strong, [lang^=lt] .wb-cell__tag strong {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru .wb-cell__tag b, .pl-pl .wb-cell__tag b, .hu-hu .wb-cell__tag b, .cs-cz .wb-cell__tag b, .et-ee .wb-cell__tag b, .lv-lv .wb-cell__tag b, .lt-lt .wb-cell__tag b, [lang^=ru] .wb-cell__tag b, [lang^=pl] .wb-cell__tag b, [lang^=hu] .wb-cell__tag b, [lang^=cs] .wb-cell__tag b, [lang^=et] .wb-cell__tag b, [lang^=lv] .wb-cell__tag b, [lang^=lt] .wb-cell__tag b {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.wb-cell--drop {
  cursor: grab;
  background-color: transparent;
}
.wb-cell.is-selected::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: cyan;
  opacity: 0.2;
}
.wb-cell.has-product-image .wb-cell__bg {
  width: 100%;
  height: 100%;
}
.wb-cell.no-border {
  border: none;
}
.wb-cell.no-bg {
  background: none;
}
.wb-cell.no-bg .wb-cell__bg {
  background-image: none;
}
.wb-cell.faded {
  opacity: 0.3;
  pointer-events: none;
}
.wb-cell.faded .grid__cells {
  opacity: 0;
}
.wb-cell--drop.hover {
  background-color: rgba(70, 170, 218, 0.2);
}

.wb-cell--selection {
  background: none;
}
.wb-cell--selection:after {
  content: "";
  border: 2px solid cyan;
  position: absolute;
  top: 50%;
  left: 50%;
  width: calc(100% + (var(--grid-gap) * 1px) / 2);
  height: calc(100% + (var(--grid-gap) * 1px) / 2);
  transform: translate(-50%, -50%);
}

.cell-bar-header {
  height: calc(((var(--grid-pitch) * 1px) * 2));
  position: absolute;
  top: 0px;
  width: 100%;
  pointer-events: all;
  cursor: pointer;
  z-index: 3;
}
.cell-bar-header:before {
  content: "";
}
.cell-bar-header__left, .cell-bar-header__right {
  position: absolute;
  background-color: #a90000;
  height: 100%;
  top: 0px;
  z-index: -1;
}
.cell-bar-header__left {
  left: 0px;
  transform-origin: top left;
}
.level--0 .cell-bar-header__left {
  transform: translateZ(25px) rotateY(90deg);
  width: 16px;
}
.level--1 .cell-bar-header__left {
  transform: translateZ(35px) rotateY(90deg);
  width: 26px;
}
.level--2 .cell-bar-header__left {
  transform: translateZ(45px) rotateY(90deg);
  width: 36px;
}
.cell-bar-header__right {
  right: 0px;
  transform-origin: top right;
}
.level--0 .cell-bar-header__right {
  transform: translateZ(25px) rotateY(-90deg);
  width: 16px;
}
.level--1 .cell-bar-header__right {
  transform: translateZ(35px) rotateY(-90deg);
  width: 26px;
}
.level--2 .cell-bar-header__right {
  transform: translateZ(45px) rotateY(-90deg);
  width: 36px;
}
.cell-bar-header__main {
  height: 100%;
  background-color: #db011c;
  width: 100%;
  padding: 2px;
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.5));
}
.level--0 .cell-bar-header__main {
  transform: translateZ(25px);
}
.level--1 .cell-bar-header__main {
  transform: translateZ(35px);
  filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.75));
}
.level--2 .cell-bar-header__main {
  transform: translateZ(45px);
  filter: drop-shadow(2px 4px 6px black);
}
.cell-bar-header .wb-cell__controls {
  margin-left: 20px;
}
.level--0 .cell-bar-header .wb-cell__controls {
  left: 103.3%;
  top: -12px;
}
.level--1 .cell-bar-header .wb-cell__controls {
  left: 104.6%;
  top: -17px;
}
.level--2 .cell-bar-header .wb-cell__controls {
  left: 106.5%;
  top: -24px;
}
.cell-bar-header .wb-cell__controls .icon {
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-size: contain;
  margin: 0 auto;
}
.cell-bar-header .wb-cell__controls .icon--visibility {
  background-image: url("~@img/svg/icon--visibility_off.svg");
}
.cell-bar-header .wb-cell__controls .icon--visibility.active {
  background-image: url("~@img/svg/icon--visibility.svg");
}
.cell-bar-header .wb-cell__controls .icon--edit {
  background-image: url("~@img/svg/icon--edit--black.svg");
}
.cell-bar-header__logo {
  max-height: 100%;
  width: 47px;
}
.cell-bar-header__title {
  font-family: "Helvetica Neue LT W01_55 Roman", Helvetica, sans-serif;
  font-size: 12px;
  color: #fff;
  text-align: center;
  width: 100%;
}
.ru-ru .cell-bar-header__title, .pl-pl .cell-bar-header__title, .hu-hu .cell-bar-header__title, .cs-cz .cell-bar-header__title, .et-ee .cell-bar-header__title, .lv-lv .cell-bar-header__title, .lt-lt .cell-bar-header__title, [lang^=ru] .cell-bar-header__title, [lang^=pl] .cell-bar-header__title, [lang^=hu] .cell-bar-header__title, [lang^=cs] .cell-bar-header__title, [lang^=et] .cell-bar-header__title, [lang^=lv] .cell-bar-header__title, [lang^=lt] .cell-bar-header__title {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru .cell-bar-header__title strong, .pl-pl .cell-bar-header__title strong, .hu-hu .cell-bar-header__title strong, .cs-cz .cell-bar-header__title strong, .et-ee .cell-bar-header__title strong, .lv-lv .cell-bar-header__title strong, .lt-lt .cell-bar-header__title strong, [lang^=ru] .cell-bar-header__title strong, [lang^=pl] .cell-bar-header__title strong, [lang^=hu] .cell-bar-header__title strong, [lang^=cs] .cell-bar-header__title strong, [lang^=et] .cell-bar-header__title strong, [lang^=lv] .cell-bar-header__title strong, [lang^=lt] .cell-bar-header__title strong {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru .cell-bar-header__title b, .pl-pl .cell-bar-header__title b, .hu-hu .cell-bar-header__title b, .cs-cz .cell-bar-header__title b, .et-ee .cell-bar-header__title b, .lv-lv .cell-bar-header__title b, .lt-lt .cell-bar-header__title b, [lang^=ru] .cell-bar-header__title b, [lang^=pl] .cell-bar-header__title b, [lang^=hu] .cell-bar-header__title b, [lang^=cs] .cell-bar-header__title b, [lang^=et] .cell-bar-header__title b, [lang^=lv] .cell-bar-header__title b, [lang^=lt] .cell-bar-header__title b {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.cell-bar-header__spacer {
  width: 47px;
}

.inventory {
  padding-bottom: 50px;
}
.inventory .form .form__title {
  text-align: left;
}
.inventory h2 {
  text-transform: uppercase;
  margin-top: 0px;
  margin-bottom: 0px;
}
.inventory h3 {
  margin-bottom: 25px;
}
.inventory ul {
  margin-left: 10px;
  margin-bottom: 25px;
}
.inventory ul li {
  margin-bottom: 5px;
}
.inventory label {
  padding-right: 5px;
}
.inventory input {
  border: none;
  padding: 5px;
}

.pdf-scaleable-grid {
  transform-origin: 0 0;
  position: absolute;
  top: 50%;
  left: 50%;
  visibility: hidden;
}
.pdf-scaleable-grid.visible {
  visibility: visible;
}

.pdf-grid {
  background-color: #a90000;
  padding: 5px;
  border-radius: 4px;
}
.pdf-grid__border {
  border-right: 4px dashed rgba(0, 0, 0, 0.28);
  border-left: 4px dashed rgba(0, 0, 0, 0.28);
}
.pdf-grid__border-inner {
  background-color: #a90000;
  padding: 5px;
}
.pdf-grid__cells {
  background-color: #db011c;
  background-image: url("~@img/svg/dot.svg");
  background-size: calc(var(--grid-pitch) * 1px);
}
.pdf-grid__bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.pdf-grid__bar .pdf-cell {
  background: none;
}

.pdf-cell {
  position: relative;
  grid-column: span 1;
  grid-row: span 1;
  cursor: grab;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1;
  font-size: 8px;
  perspective: 400px;
  transform-style: preserve-3d;
}
.pdf-cell__bg {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  background-image: url("~@img/svg/logo.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 0;
}
.pdf-cell__bg--border {
  border: 1px solid white;
}
.pdf-cell--bar {
  background: none;
}
.pdf-cell--placeholder {
  background-color: rgba(60, 60, 60, 0.2) !important;
}
.pdf-cell__corners {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.pdf-cell__corners span {
  position: absolute;
  background-image: url("~@img/svg/icon--corner.svg");
  width: 20px;
  height: 20px;
}
.pdf-cell__corners span:nth-child(1) {
  top: 0;
  left: 0;
}
.pdf-cell__corners span:nth-child(2) {
  top: 0;
  right: 0;
  transform: rotate(90deg);
}
.pdf-cell__corners span:nth-child(3) {
  bottom: 0;
  right: 0;
  transform: rotate(180deg);
}
.pdf-cell__corners span:nth-child(4) {
  bottom: 0;
  left: 0;
  transform: rotate(-90deg);
}
.pdf-cell.level--0 {
  border: none;
}

.tooltip {
  position: fixed;
  z-index: 1000;
  transform: translate(-60px, 10px);
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 2px;
  border: 1px solid black;
  width: 300px;
  text-align: left;
  will-change: left, top;
  z-index: 100;
}
.tooltip__inner {
  padding: 15px;
}
.tooltip__inner h3 {
  font-size: 10px;
  margin-bottom: 10px;
}
.tooltip__inner h4 {
  font-size: 10px;
}
.tooltip__inner p {
  font-size: 10px;
  margin-bottom: 0px;
}

.bay__header {
  height: 200px;
  background-color: #db011c;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
}
.bay__header--shadow {
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.64);
}
.bay__header img {
  width: 300px;
  position: relative;
  display: block;
}
.bay__footer {
  height: 100px;
  background-color: #db011c;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bay__footer--shadow {
  box-shadow: 0px -3px 4px 0px rgba(0, 0, 0, 0.64);
}
.bay__footer img {
  max-width: 500px;
}
.bay__border {
  border-right: 4px dashed rgba(0, 0, 0, 0.28);
  border-left: 4px dashed rgba(0, 0, 0, 0.28);
}
.bay__border-inner {
  background-color: #a90000;
  padding: 5px;
}

.product-info {
  position: fixed;
  bottom: 0;
  right: 0;
  border-top-left-radius: 3px;
  background-color: #db011c;
  padding: 16px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 315px;
  min-height: 80px;
  z-index: 10;
  transform: translateY(100%);
  transition: transform 275ms 0ms ease;
}
.product-info.active {
  transform: translateY(0%);
}
.product-info__details {
  display: flex;
  flex-direction: column;
}
.product-info__media {
  margin-left: auto;
  width: 100px;
  flex: 0 0 100px;
  margin-left: 12px;
  margin-right: 25px;
}
.product-info__media img {
  width: 100px;
  height: 100px;
  object-fit: contain;
  border-radius: 3px;
}
.product-info__title {
  color: #fff;
  font-family: "Helvetica Neue LT W05_63 Md Ex", Helvetica, sans-serif;
  font-size: 14px;
  margin-bottom: 12px;
}
.ru-ru .product-info__title, .pl-pl .product-info__title, .hu-hu .product-info__title, .cs-cz .product-info__title, .et-ee .product-info__title, .lv-lv .product-info__title, .lt-lt .product-info__title, [lang^=ru] .product-info__title, [lang^=pl] .product-info__title, [lang^=hu] .product-info__title, [lang^=cs] .product-info__title, [lang^=et] .product-info__title, [lang^=lv] .product-info__title, [lang^=lt] .product-info__title {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru .product-info__title strong, .pl-pl .product-info__title strong, .hu-hu .product-info__title strong, .cs-cz .product-info__title strong, .et-ee .product-info__title strong, .lv-lv .product-info__title strong, .lt-lt .product-info__title strong, [lang^=ru] .product-info__title strong, [lang^=pl] .product-info__title strong, [lang^=hu] .product-info__title strong, [lang^=cs] .product-info__title strong, [lang^=et] .product-info__title strong, [lang^=lv] .product-info__title strong, [lang^=lt] .product-info__title strong {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru .product-info__title b, .pl-pl .product-info__title b, .hu-hu .product-info__title b, .cs-cz .product-info__title b, .et-ee .product-info__title b, .lv-lv .product-info__title b, .lt-lt .product-info__title b, [lang^=ru] .product-info__title b, [lang^=pl] .product-info__title b, [lang^=hu] .product-info__title b, [lang^=cs] .product-info__title b, [lang^=et] .product-info__title b, [lang^=lv] .product-info__title b, [lang^=lt] .product-info__title b {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.product-info__an {
  color: #fff;
  margin-top: auto;
  font-family: "Helvetica Neue LT W05_63 Md Ex", Helvetica, sans-serif;
  font-size: 12px;
}
.ru-ru .product-info__an, .pl-pl .product-info__an, .hu-hu .product-info__an, .cs-cz .product-info__an, .et-ee .product-info__an, .lv-lv .product-info__an, .lt-lt .product-info__an, [lang^=ru] .product-info__an, [lang^=pl] .product-info__an, [lang^=hu] .product-info__an, [lang^=cs] .product-info__an, [lang^=et] .product-info__an, [lang^=lv] .product-info__an, [lang^=lt] .product-info__an {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru .product-info__an strong, .pl-pl .product-info__an strong, .hu-hu .product-info__an strong, .cs-cz .product-info__an strong, .et-ee .product-info__an strong, .lv-lv .product-info__an strong, .lt-lt .product-info__an strong, [lang^=ru] .product-info__an strong, [lang^=pl] .product-info__an strong, [lang^=hu] .product-info__an strong, [lang^=cs] .product-info__an strong, [lang^=et] .product-info__an strong, [lang^=lv] .product-info__an strong, [lang^=lt] .product-info__an strong {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru .product-info__an b, .pl-pl .product-info__an b, .hu-hu .product-info__an b, .cs-cz .product-info__an b, .et-ee .product-info__an b, .lv-lv .product-info__an b, .lt-lt .product-info__an b, [lang^=ru] .product-info__an b, [lang^=pl] .product-info__an b, [lang^=hu] .product-info__an b, [lang^=cs] .product-info__an b, [lang^=et] .product-info__an b, [lang^=lv] .product-info__an b, [lang^=lt] .product-info__an b {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.product-info__close {
  position: absolute;
  background-image: url("~img/svg/plus.svg");
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  transform: rotate(45deg);
  height: 15px;
  width: 15px;
  right: 15px;
}
.product-info__close:hover {
  cursor: pointer;
}
.product-info__dimensions {
  font-family: "Helvetica Neue LT W05_63 Md Ex", Helvetica, sans-serif;
  color: #fff;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  margin-bottom: 12px;
}
.ru-ru .product-info__dimensions, .pl-pl .product-info__dimensions, .hu-hu .product-info__dimensions, .cs-cz .product-info__dimensions, .et-ee .product-info__dimensions, .lv-lv .product-info__dimensions, .lt-lt .product-info__dimensions, [lang^=ru] .product-info__dimensions, [lang^=pl] .product-info__dimensions, [lang^=hu] .product-info__dimensions, [lang^=cs] .product-info__dimensions, [lang^=et] .product-info__dimensions, [lang^=lv] .product-info__dimensions, [lang^=lt] .product-info__dimensions {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru .product-info__dimensions strong, .pl-pl .product-info__dimensions strong, .hu-hu .product-info__dimensions strong, .cs-cz .product-info__dimensions strong, .et-ee .product-info__dimensions strong, .lv-lv .product-info__dimensions strong, .lt-lt .product-info__dimensions strong, [lang^=ru] .product-info__dimensions strong, [lang^=pl] .product-info__dimensions strong, [lang^=hu] .product-info__dimensions strong, [lang^=cs] .product-info__dimensions strong, [lang^=et] .product-info__dimensions strong, [lang^=lv] .product-info__dimensions strong, [lang^=lt] .product-info__dimensions strong {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru .product-info__dimensions b, .pl-pl .product-info__dimensions b, .hu-hu .product-info__dimensions b, .cs-cz .product-info__dimensions b, .et-ee .product-info__dimensions b, .lv-lv .product-info__dimensions b, .lt-lt .product-info__dimensions b, [lang^=ru] .product-info__dimensions b, [lang^=pl] .product-info__dimensions b, [lang^=hu] .product-info__dimensions b, [lang^=cs] .product-info__dimensions b, [lang^=et] .product-info__dimensions b, [lang^=lv] .product-info__dimensions b, [lang^=lt] .product-info__dimensions b {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}

.spinner,
.spinner:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}

.spinner {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.4);
  border-right: 1.1em solid rgba(255, 255, 255, 0.4);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.4);
  border-left: 1.1em solid #ffffff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.react-contextmenu {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  color: #373a3c;
  font-size: 16px;
  margin: 2px 0 0;
  min-width: 160px;
  outline: none;
  opacity: 0;
  padding: 5px 0;
  pointer-events: none;
  text-align: left;
  transition: opacity 250ms ease !important;
}

.react-contextmenu.react-contextmenu--visible {
  opacity: 1;
  pointer-events: auto;
  z-index: 9999;
}

.react-contextmenu-item {
  background: 0 0;
  border: 0;
  color: #373a3c;
  cursor: pointer;
  font-weight: 400;
  line-height: 1.5;
  padding: 3px 20px;
  text-align: inherit;
  white-space: nowrap;
}

.react-contextmenu-item.react-contextmenu-item--active,
.react-contextmenu-item.react-contextmenu-item--selected {
  color: #fff;
  background-color: #20a0ff;
  border-color: #20a0ff;
  text-decoration: none;
}

.react-contextmenu-item.react-contextmenu-item--disabled,
.react-contextmenu-item.react-contextmenu-item--disabled:hover {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.15);
  color: #878a8c;
}

.react-contextmenu-item--divider {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  cursor: inherit;
  margin-bottom: 3px;
  padding: 2px 0;
}

.react-contextmenu-item--divider:hover {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.15);
}

.react-contextmenu-item.react-contextmenu-submenu {
  padding: 0;
}

.react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item:after {
  content: "▶";
  display: inline-block;
  position: absolute;
  right: 7px;
}

.example-multiple-targets::after {
  content: attr(data-count);
  display: block;
}

.react-contextmenu-wrapper {
  position: static;
  display: block;
  width: 100%;
  height: 100%;
}

.populate-results {
  max-width: 500px;
}
.populate-results__title {
  margin-bottom: 18px;
}
.populate-results__list {
  max-height: 500px;
  overflow: auto;
  margin-bottom: 18px;
}
.populate-results__buttons {
  gap: 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.populate-results textarea {
  display: none;
}

.zoom-bar {
  position: fixed;
  bottom: 20px;
  width: 800px;
  height: 50px;
  background-color: #fff;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.4);
  z-index: 1000;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
}
.zoom-bar__bar {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 1px;
  background-color: #000;
  width: 90%;
}
.zoom-bar__thumb {
  display: block;
  position: absolute;
  height: 20px;
  width: 4px;
  background-color: #000;
  transform: translate(-50%, -50%);
  left: 25%;
  top: 50%;
}

.project-list {
  display: grid;
  grid-template-columns: repeat(5, minmax(100px, 1fr));
  grid-gap: 20px;
  margin-bottom: 40px;
}
.project-list a {
  text-decoration: none;
  color: inherit;
}
.project-list__loader {
  position: relative;
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.project-item {
  text-align: left;
  align-self: flex-start;
  cursor: pointer;
}
.project-item__img-wrapper {
  background-color: rgba(0, 0, 0, 0.05);
  height: 0;
  padding-bottom: 100%;
  position: relative;
  justify-content: center;
  align-items: center;
  display: flex;
}
.project-item--square .project-item__img-wrapper {
  height: 0;
  padding-bottom: 100%;
}
.project-item__placeholder, .project-item__img {
  width: 60px;
  height: 60px;
  background-image: url("~@img/svg/logo.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #db011c;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.project-item__img {
  width: 100%;
  height: 100%;
  background-color: transparent;
}
.project-item__new {
  background-color: rgba(0, 0, 0, 0.05);
  height: 0;
  padding-bottom: 100%;
  position: relative;
  cursor: pointer;
}
.project-item__new-inner {
  width: 150px;
  min-height: 60px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.project-item__new-text {
  font-family: "Helvetica Neue LT W05_63 Md Ex", Helvetica, sans-serif;
  font-size: 14px;
  text-align: center;
}
.ru-ru .project-item__new-text, .pl-pl .project-item__new-text, .hu-hu .project-item__new-text, .cs-cz .project-item__new-text, .et-ee .project-item__new-text, .lv-lv .project-item__new-text, .lt-lt .project-item__new-text, [lang^=ru] .project-item__new-text, [lang^=pl] .project-item__new-text, [lang^=hu] .project-item__new-text, [lang^=cs] .project-item__new-text, [lang^=et] .project-item__new-text, [lang^=lv] .project-item__new-text, [lang^=lt] .project-item__new-text {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru .project-item__new-text strong, .pl-pl .project-item__new-text strong, .hu-hu .project-item__new-text strong, .cs-cz .project-item__new-text strong, .et-ee .project-item__new-text strong, .lv-lv .project-item__new-text strong, .lt-lt .project-item__new-text strong, [lang^=ru] .project-item__new-text strong, [lang^=pl] .project-item__new-text strong, [lang^=hu] .project-item__new-text strong, [lang^=cs] .project-item__new-text strong, [lang^=et] .project-item__new-text strong, [lang^=lv] .project-item__new-text strong, [lang^=lt] .project-item__new-text strong {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru .project-item__new-text b, .pl-pl .project-item__new-text b, .hu-hu .project-item__new-text b, .cs-cz .project-item__new-text b, .et-ee .project-item__new-text b, .lv-lv .project-item__new-text b, .lt-lt .project-item__new-text b, [lang^=ru] .project-item__new-text b, [lang^=pl] .project-item__new-text b, [lang^=hu] .project-item__new-text b, [lang^=cs] .project-item__new-text b, [lang^=et] .project-item__new-text b, [lang^=lv] .project-item__new-text b, [lang^=lt] .project-item__new-text b {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.project-item__new-plus {
  width: 20px;
  height: 20px;
  margin: 0 auto 5px auto;
  background-image: url("~@img/svg/plus--black.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}
.project-item__details {
  padding: 10px 0px;
}
.project-item__title {
  font-family: "Helvetica Neue LT W05_63 Md Ex", Helvetica, sans-serif;
  font-size: 14px;
  color: #000;
  margin-bottom: 5px;
  margin-top: 0px;
  text-transform: unset;
}
.ru-ru .project-item__title, .pl-pl .project-item__title, .hu-hu .project-item__title, .cs-cz .project-item__title, .et-ee .project-item__title, .lv-lv .project-item__title, .lt-lt .project-item__title, [lang^=ru] .project-item__title, [lang^=pl] .project-item__title, [lang^=hu] .project-item__title, [lang^=cs] .project-item__title, [lang^=et] .project-item__title, [lang^=lv] .project-item__title, [lang^=lt] .project-item__title {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru .project-item__title strong, .pl-pl .project-item__title strong, .hu-hu .project-item__title strong, .cs-cz .project-item__title strong, .et-ee .project-item__title strong, .lv-lv .project-item__title strong, .lt-lt .project-item__title strong, [lang^=ru] .project-item__title strong, [lang^=pl] .project-item__title strong, [lang^=hu] .project-item__title strong, [lang^=cs] .project-item__title strong, [lang^=et] .project-item__title strong, [lang^=lv] .project-item__title strong, [lang^=lt] .project-item__title strong {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru .project-item__title b, .pl-pl .project-item__title b, .hu-hu .project-item__title b, .cs-cz .project-item__title b, .et-ee .project-item__title b, .lv-lv .project-item__title b, .lt-lt .project-item__title b, [lang^=ru] .project-item__title b, [lang^=pl] .project-item__title b, [lang^=hu] .project-item__title b, [lang^=cs] .project-item__title b, [lang^=et] .project-item__title b, [lang^=lv] .project-item__title b, [lang^=lt] .project-item__title b {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.project-item__date {
  margin-bottom: 10px;
}
.project-item__remove {
  font-family: "Helvetica Neue LT W05_53 Ext", Helvetica, sans-serif;
  color: #db011c;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}
.ru-ru .project-item__remove, .pl-pl .project-item__remove, .hu-hu .project-item__remove, .cs-cz .project-item__remove, .et-ee .project-item__remove, .lv-lv .project-item__remove, .lt-lt .project-item__remove, [lang^=ru] .project-item__remove, [lang^=pl] .project-item__remove, [lang^=hu] .project-item__remove, [lang^=cs] .project-item__remove, [lang^=et] .project-item__remove, [lang^=lv] .project-item__remove, [lang^=lt] .project-item__remove {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru .project-item__remove strong, .pl-pl .project-item__remove strong, .hu-hu .project-item__remove strong, .cs-cz .project-item__remove strong, .et-ee .project-item__remove strong, .lv-lv .project-item__remove strong, .lt-lt .project-item__remove strong, [lang^=ru] .project-item__remove strong, [lang^=pl] .project-item__remove strong, [lang^=hu] .project-item__remove strong, [lang^=cs] .project-item__remove strong, [lang^=et] .project-item__remove strong, [lang^=lv] .project-item__remove strong, [lang^=lt] .project-item__remove strong {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru .project-item__remove b, .pl-pl .project-item__remove b, .hu-hu .project-item__remove b, .cs-cz .project-item__remove b, .et-ee .project-item__remove b, .lv-lv .project-item__remove b, .lt-lt .project-item__remove b, [lang^=ru] .project-item__remove b, [lang^=pl] .project-item__remove b, [lang^=hu] .project-item__remove b, [lang^=cs] .project-item__remove b, [lang^=et] .project-item__remove b, [lang^=lv] .project-item__remove b, [lang^=lt] .project-item__remove b {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.project-item__remove-icon {
  width: 14px;
  height: 14px;
  background-image: url("~@img/svg/delete.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  margin-right: 7px;
}

.customer-details {
  padding-top: 1.524cm;
}
.customer-details__split {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0.508cm;
}
.customer-details__half {
  width: 50%;
}
.customer-details__title {
  margin-bottom: 0 !important;
}
.customer-details__order {
  margin-bottom: 0.3048cm !important;
}
.customer-details__number {
  font-family: "Helvetica Neue LT W05_53 Ext", Helvetica, sans-serif;
  font-size: 9px;
  color: #818483;
}
.ru-ru .customer-details__number, .pl-pl .customer-details__number, .hu-hu .customer-details__number, .cs-cz .customer-details__number, .et-ee .customer-details__number, .lv-lv .customer-details__number, .lt-lt .customer-details__number, [lang^=ru] .customer-details__number, [lang^=pl] .customer-details__number, [lang^=hu] .customer-details__number, [lang^=cs] .customer-details__number, [lang^=et] .customer-details__number, [lang^=lv] .customer-details__number, [lang^=lt] .customer-details__number {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru .customer-details__number strong, .pl-pl .customer-details__number strong, .hu-hu .customer-details__number strong, .cs-cz .customer-details__number strong, .et-ee .customer-details__number strong, .lv-lv .customer-details__number strong, .lt-lt .customer-details__number strong, [lang^=ru] .customer-details__number strong, [lang^=pl] .customer-details__number strong, [lang^=hu] .customer-details__number strong, [lang^=cs] .customer-details__number strong, [lang^=et] .customer-details__number strong, [lang^=lv] .customer-details__number strong, [lang^=lt] .customer-details__number strong {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru .customer-details__number b, .pl-pl .customer-details__number b, .hu-hu .customer-details__number b, .cs-cz .customer-details__number b, .et-ee .customer-details__number b, .lv-lv .customer-details__number b, .lt-lt .customer-details__number b, [lang^=ru] .customer-details__number b, [lang^=pl] .customer-details__number b, [lang^=hu] .customer-details__number b, [lang^=cs] .customer-details__number b, [lang^=et] .customer-details__number b, [lang^=lv] .customer-details__number b, [lang^=lt] .customer-details__number b {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.customer-details .customer__label {
  font-family: "Helvetica Neue LT W01_93 BlkEx", Helvetica, sans-serif;
  font-size: 9px;
  text-transform: uppercase;
  margin-right: 0.254cm;
  line-height: 9px;
}
.ru-ru .customer-details .customer__label, .pl-pl .customer-details .customer__label, .hu-hu .customer-details .customer__label, .cs-cz .customer-details .customer__label, .et-ee .customer-details .customer__label, .lv-lv .customer-details .customer__label, .lt-lt .customer-details .customer__label, [lang^=ru] .customer-details .customer__label, [lang^=pl] .customer-details .customer__label, [lang^=hu] .customer-details .customer__label, [lang^=cs] .customer-details .customer__label, [lang^=et] .customer-details .customer__label, [lang^=lv] .customer-details .customer__label, [lang^=lt] .customer-details .customer__label {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru .customer-details .customer__label strong, .pl-pl .customer-details .customer__label strong, .hu-hu .customer-details .customer__label strong, .cs-cz .customer-details .customer__label strong, .et-ee .customer-details .customer__label strong, .lv-lv .customer-details .customer__label strong, .lt-lt .customer-details .customer__label strong, [lang^=ru] .customer-details .customer__label strong, [lang^=pl] .customer-details .customer__label strong, [lang^=hu] .customer-details .customer__label strong, [lang^=cs] .customer-details .customer__label strong, [lang^=et] .customer-details .customer__label strong, [lang^=lv] .customer-details .customer__label strong, [lang^=lt] .customer-details .customer__label strong {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru .customer-details .customer__label b, .pl-pl .customer-details .customer__label b, .hu-hu .customer-details .customer__label b, .cs-cz .customer-details .customer__label b, .et-ee .customer-details .customer__label b, .lv-lv .customer-details .customer__label b, .lt-lt .customer-details .customer__label b, [lang^=ru] .customer-details .customer__label b, [lang^=pl] .customer-details .customer__label b, [lang^=hu] .customer-details .customer__label b, [lang^=cs] .customer-details .customer__label b, [lang^=et] .customer-details .customer__label b, [lang^=lv] .customer-details .customer__label b, [lang^=lt] .customer-details .customer__label b {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.customer-details .customer__value {
  font-family: "Helvetica Neue LT W05_53 Ext", Helvetica, sans-serif;
  font-size: 9px;
  color: #818483;
  line-height: 9px;
}
.ru-ru .customer-details .customer__value, .pl-pl .customer-details .customer__value, .hu-hu .customer-details .customer__value, .cs-cz .customer-details .customer__value, .et-ee .customer-details .customer__value, .lv-lv .customer-details .customer__value, .lt-lt .customer-details .customer__value, [lang^=ru] .customer-details .customer__value, [lang^=pl] .customer-details .customer__value, [lang^=hu] .customer-details .customer__value, [lang^=cs] .customer-details .customer__value, [lang^=et] .customer-details .customer__value, [lang^=lv] .customer-details .customer__value, [lang^=lt] .customer-details .customer__value {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru .customer-details .customer__value strong, .pl-pl .customer-details .customer__value strong, .hu-hu .customer-details .customer__value strong, .cs-cz .customer-details .customer__value strong, .et-ee .customer-details .customer__value strong, .lv-lv .customer-details .customer__value strong, .lt-lt .customer-details .customer__value strong, [lang^=ru] .customer-details .customer__value strong, [lang^=pl] .customer-details .customer__value strong, [lang^=hu] .customer-details .customer__value strong, [lang^=cs] .customer-details .customer__value strong, [lang^=et] .customer-details .customer__value strong, [lang^=lv] .customer-details .customer__value strong, [lang^=lt] .customer-details .customer__value strong {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru .customer-details .customer__value b, .pl-pl .customer-details .customer__value b, .hu-hu .customer-details .customer__value b, .cs-cz .customer-details .customer__value b, .et-ee .customer-details .customer__value b, .lv-lv .customer-details .customer__value b, .lt-lt .customer-details .customer__value b, [lang^=ru] .customer-details .customer__value b, [lang^=pl] .customer-details .customer__value b, [lang^=hu] .customer-details .customer__value b, [lang^=cs] .customer-details .customer__value b, [lang^=et] .customer-details .customer__value b, [lang^=lv] .customer-details .customer__value b, [lang^=lt] .customer-details .customer__value b {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.customer-details li {
  margin-bottom: 0.508cm;
}

.product-item {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  padding-bottom: 0.762cm;
}
.product-item__article {
  font-family: "Helvetica Neue LT W05_53 Ext", Helvetica, sans-serif;
  margin-right: 0.127cm;
  font-size: 9px;
  line-height: 0.3048cm;
  color: #818483;
}
.ru-ru .product-item__article, .pl-pl .product-item__article, .hu-hu .product-item__article, .cs-cz .product-item__article, .et-ee .product-item__article, .lv-lv .product-item__article, .lt-lt .product-item__article, [lang^=ru] .product-item__article, [lang^=pl] .product-item__article, [lang^=hu] .product-item__article, [lang^=cs] .product-item__article, [lang^=et] .product-item__article, [lang^=lv] .product-item__article, [lang^=lt] .product-item__article {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru .product-item__article strong, .pl-pl .product-item__article strong, .hu-hu .product-item__article strong, .cs-cz .product-item__article strong, .et-ee .product-item__article strong, .lv-lv .product-item__article strong, .lt-lt .product-item__article strong, [lang^=ru] .product-item__article strong, [lang^=pl] .product-item__article strong, [lang^=hu] .product-item__article strong, [lang^=cs] .product-item__article strong, [lang^=et] .product-item__article strong, [lang^=lv] .product-item__article strong, [lang^=lt] .product-item__article strong {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru .product-item__article b, .pl-pl .product-item__article b, .hu-hu .product-item__article b, .cs-cz .product-item__article b, .et-ee .product-item__article b, .lv-lv .product-item__article b, .lt-lt .product-item__article b, [lang^=ru] .product-item__article b, [lang^=pl] .product-item__article b, [lang^=hu] .product-item__article b, [lang^=cs] .product-item__article b, [lang^=et] .product-item__article b, [lang^=lv] .product-item__article b, [lang^=lt] .product-item__article b {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.product-item__name {
  font-family: "Helvetica Neue LT W01_93 BlkEx", Helvetica, sans-serif;
  font-size: 9px;
  text-transform: uppercase;
  margin-bottom: 0.254cm;
  line-height: 0.3048cm;
}
.ru-ru .product-item__name, .pl-pl .product-item__name, .hu-hu .product-item__name, .cs-cz .product-item__name, .et-ee .product-item__name, .lv-lv .product-item__name, .lt-lt .product-item__name, [lang^=ru] .product-item__name, [lang^=pl] .product-item__name, [lang^=hu] .product-item__name, [lang^=cs] .product-item__name, [lang^=et] .product-item__name, [lang^=lv] .product-item__name, [lang^=lt] .product-item__name {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru .product-item__name strong, .pl-pl .product-item__name strong, .hu-hu .product-item__name strong, .cs-cz .product-item__name strong, .et-ee .product-item__name strong, .lv-lv .product-item__name strong, .lt-lt .product-item__name strong, [lang^=ru] .product-item__name strong, [lang^=pl] .product-item__name strong, [lang^=hu] .product-item__name strong, [lang^=cs] .product-item__name strong, [lang^=et] .product-item__name strong, [lang^=lv] .product-item__name strong, [lang^=lt] .product-item__name strong {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru .product-item__name b, .pl-pl .product-item__name b, .hu-hu .product-item__name b, .cs-cz .product-item__name b, .et-ee .product-item__name b, .lv-lv .product-item__name b, .lt-lt .product-item__name b, [lang^=ru] .product-item__name b, [lang^=pl] .product-item__name b, [lang^=hu] .product-item__name b, [lang^=cs] .product-item__name b, [lang^=et] .product-item__name b, [lang^=lv] .product-item__name b, [lang^=lt] .product-item__name b {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.product-item__quantity {
  font-family: "Helvetica Neue LT W05_53 Ext", Helvetica, sans-serif;
  font-size: 9px;
  color: #818483;
}
.ru-ru .product-item__quantity, .pl-pl .product-item__quantity, .hu-hu .product-item__quantity, .cs-cz .product-item__quantity, .et-ee .product-item__quantity, .lv-lv .product-item__quantity, .lt-lt .product-item__quantity, [lang^=ru] .product-item__quantity, [lang^=pl] .product-item__quantity, [lang^=hu] .product-item__quantity, [lang^=cs] .product-item__quantity, [lang^=et] .product-item__quantity, [lang^=lv] .product-item__quantity, [lang^=lt] .product-item__quantity {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru .product-item__quantity strong, .pl-pl .product-item__quantity strong, .hu-hu .product-item__quantity strong, .cs-cz .product-item__quantity strong, .et-ee .product-item__quantity strong, .lv-lv .product-item__quantity strong, .lt-lt .product-item__quantity strong, [lang^=ru] .product-item__quantity strong, [lang^=pl] .product-item__quantity strong, [lang^=hu] .product-item__quantity strong, [lang^=cs] .product-item__quantity strong, [lang^=et] .product-item__quantity strong, [lang^=lv] .product-item__quantity strong, [lang^=lt] .product-item__quantity strong {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru .product-item__quantity b, .pl-pl .product-item__quantity b, .hu-hu .product-item__quantity b, .cs-cz .product-item__quantity b, .et-ee .product-item__quantity b, .lv-lv .product-item__quantity b, .lt-lt .product-item__quantity b, [lang^=ru] .product-item__quantity b, [lang^=pl] .product-item__quantity b, [lang^=hu] .product-item__quantity b, [lang^=cs] .product-item__quantity b, [lang^=et] .product-item__quantity b, [lang^=lv] .product-item__quantity b, [lang^=lt] .product-item__quantity b {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}

.pdf-product {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 100%;
}
.pdf-product__name {
  font-family: "Helvetica Neue LT W01_93 BlkEx", Helvetica, sans-serif;
  font-size: 15px;
  text-transform: uppercase;
}
.ru-ru .pdf-product__name, .pl-pl .pdf-product__name, .hu-hu .pdf-product__name, .cs-cz .pdf-product__name, .et-ee .pdf-product__name, .lv-lv .pdf-product__name, .lt-lt .pdf-product__name, [lang^=ru] .pdf-product__name, [lang^=pl] .pdf-product__name, [lang^=hu] .pdf-product__name, [lang^=cs] .pdf-product__name, [lang^=et] .pdf-product__name, [lang^=lv] .pdf-product__name, [lang^=lt] .pdf-product__name {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru .pdf-product__name strong, .pl-pl .pdf-product__name strong, .hu-hu .pdf-product__name strong, .cs-cz .pdf-product__name strong, .et-ee .pdf-product__name strong, .lv-lv .pdf-product__name strong, .lt-lt .pdf-product__name strong, [lang^=ru] .pdf-product__name strong, [lang^=pl] .pdf-product__name strong, [lang^=hu] .pdf-product__name strong, [lang^=cs] .pdf-product__name strong, [lang^=et] .pdf-product__name strong, [lang^=lv] .pdf-product__name strong, [lang^=lt] .pdf-product__name strong {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru .pdf-product__name b, .pl-pl .pdf-product__name b, .hu-hu .pdf-product__name b, .cs-cz .pdf-product__name b, .et-ee .pdf-product__name b, .lv-lv .pdf-product__name b, .lt-lt .pdf-product__name b, [lang^=ru] .pdf-product__name b, [lang^=pl] .pdf-product__name b, [lang^=hu] .pdf-product__name b, [lang^=cs] .pdf-product__name b, [lang^=et] .pdf-product__name b, [lang^=lv] .pdf-product__name b, [lang^=lt] .pdf-product__name b {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.pdf-product__article {
  font-family: "Helvetica Neue LT W05_53 Ext", Helvetica, sans-serif;
  font-size: 9px;
  color: #db011c;
  margin-bottom: 0.635cm;
}
.ru-ru .pdf-product__article, .pl-pl .pdf-product__article, .hu-hu .pdf-product__article, .cs-cz .pdf-product__article, .et-ee .pdf-product__article, .lv-lv .pdf-product__article, .lt-lt .pdf-product__article, [lang^=ru] .pdf-product__article, [lang^=pl] .pdf-product__article, [lang^=hu] .pdf-product__article, [lang^=cs] .pdf-product__article, [lang^=et] .pdf-product__article, [lang^=lv] .pdf-product__article, [lang^=lt] .pdf-product__article {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru .pdf-product__article strong, .pl-pl .pdf-product__article strong, .hu-hu .pdf-product__article strong, .cs-cz .pdf-product__article strong, .et-ee .pdf-product__article strong, .lv-lv .pdf-product__article strong, .lt-lt .pdf-product__article strong, [lang^=ru] .pdf-product__article strong, [lang^=pl] .pdf-product__article strong, [lang^=hu] .pdf-product__article strong, [lang^=cs] .pdf-product__article strong, [lang^=et] .pdf-product__article strong, [lang^=lv] .pdf-product__article strong, [lang^=lt] .pdf-product__article strong {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru .pdf-product__article b, .pl-pl .pdf-product__article b, .hu-hu .pdf-product__article b, .cs-cz .pdf-product__article b, .et-ee .pdf-product__article b, .lv-lv .pdf-product__article b, .lt-lt .pdf-product__article b, [lang^=ru] .pdf-product__article b, [lang^=pl] .pdf-product__article b, [lang^=hu] .pdf-product__article b, [lang^=cs] .pdf-product__article b, [lang^=et] .pdf-product__article b, [lang^=lv] .pdf-product__article b, [lang^=lt] .pdf-product__article b {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.pdf-product__hero {
  margin-bottom: 0.635cm;
}
.pdf-product__promo {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.pdf-product__info {
  width: 50%;
  height: 100%;
  background-color: #e4e4e4;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.pdf-product__features-title {
  font-family: "Helvetica Neue LT W01_93 BlkEx", Helvetica, sans-serif;
  font-size: 9px;
  text-transform: uppercase;
  margin-bottom: 0.508cm;
}
.ru-ru .pdf-product__features-title, .pl-pl .pdf-product__features-title, .hu-hu .pdf-product__features-title, .cs-cz .pdf-product__features-title, .et-ee .pdf-product__features-title, .lv-lv .pdf-product__features-title, .lt-lt .pdf-product__features-title, [lang^=ru] .pdf-product__features-title, [lang^=pl] .pdf-product__features-title, [lang^=hu] .pdf-product__features-title, [lang^=cs] .pdf-product__features-title, [lang^=et] .pdf-product__features-title, [lang^=lv] .pdf-product__features-title, [lang^=lt] .pdf-product__features-title {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru .pdf-product__features-title strong, .pl-pl .pdf-product__features-title strong, .hu-hu .pdf-product__features-title strong, .cs-cz .pdf-product__features-title strong, .et-ee .pdf-product__features-title strong, .lv-lv .pdf-product__features-title strong, .lt-lt .pdf-product__features-title strong, [lang^=ru] .pdf-product__features-title strong, [lang^=pl] .pdf-product__features-title strong, [lang^=hu] .pdf-product__features-title strong, [lang^=cs] .pdf-product__features-title strong, [lang^=et] .pdf-product__features-title strong, [lang^=lv] .pdf-product__features-title strong, [lang^=lt] .pdf-product__features-title strong {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru .pdf-product__features-title b, .pl-pl .pdf-product__features-title b, .hu-hu .pdf-product__features-title b, .cs-cz .pdf-product__features-title b, .et-ee .pdf-product__features-title b, .lv-lv .pdf-product__features-title b, .lt-lt .pdf-product__features-title b, [lang^=ru] .pdf-product__features-title b, [lang^=pl] .pdf-product__features-title b, [lang^=hu] .pdf-product__features-title b, [lang^=cs] .pdf-product__features-title b, [lang^=et] .pdf-product__features-title b, [lang^=lv] .pdf-product__features-title b, [lang^=lt] .pdf-product__features-title b {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.pdf-product__features li {
  font-size: 9px;
  margin-bottom: 0.508cm;
}

.a4 {
  background-color: #fff;
  margin: 0 auto;
  width: 21cm;
  height: 29.7cm;
  position: relative;
}
.a4--break-after {
  page-break-after: always;
}
.a4--break-before {
  page-break-before: always;
}
.a4--break-avoid {
  page-break-inside: avoid;
}
.a4--min {
  height: auto;
  min-height: 29.7cm;
}

.pdf-iframe {
  position: fixed;
  left: 2000px;
  width: 100vw;
  height: 100vh;
}

.pdf h1 {
  font-family: "Helvetica Neue LT W01_93 BlkEx", Helvetica, sans-serif;
  font-size: 16px;
  text-transform: uppercase;
  margin-bottom: 0.762cm;
  font-style: normal;
}
.ru-ru .pdf h1, .pl-pl .pdf h1, .hu-hu .pdf h1, .cs-cz .pdf h1, .et-ee .pdf h1, .lv-lv .pdf h1, .lt-lt .pdf h1, [lang^=ru] .pdf h1, [lang^=pl] .pdf h1, [lang^=hu] .pdf h1, [lang^=cs] .pdf h1, [lang^=et] .pdf h1, [lang^=lv] .pdf h1, [lang^=lt] .pdf h1 {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru .pdf h1 strong, .pl-pl .pdf h1 strong, .hu-hu .pdf h1 strong, .cs-cz .pdf h1 strong, .et-ee .pdf h1 strong, .lv-lv .pdf h1 strong, .lt-lt .pdf h1 strong, [lang^=ru] .pdf h1 strong, [lang^=pl] .pdf h1 strong, [lang^=hu] .pdf h1 strong, [lang^=cs] .pdf h1 strong, [lang^=et] .pdf h1 strong, [lang^=lv] .pdf h1 strong, [lang^=lt] .pdf h1 strong {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru .pdf h1 b, .pl-pl .pdf h1 b, .hu-hu .pdf h1 b, .cs-cz .pdf h1 b, .et-ee .pdf h1 b, .lv-lv .pdf h1 b, .lt-lt .pdf h1 b, [lang^=ru] .pdf h1 b, [lang^=pl] .pdf h1 b, [lang^=hu] .pdf h1 b, [lang^=cs] .pdf h1 b, [lang^=et] .pdf h1 b, [lang^=lv] .pdf h1 b, [lang^=lt] .pdf h1 b {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.pdf h2 {
  font-family: "Helvetica Neue LT W01_93 BlkEx", Helvetica, sans-serif;
  font-size: 12px;
  text-transform: uppercase;
  margin-bottom: 0.762cm;
}
.ru-ru .pdf h2, .pl-pl .pdf h2, .hu-hu .pdf h2, .cs-cz .pdf h2, .et-ee .pdf h2, .lv-lv .pdf h2, .lt-lt .pdf h2, [lang^=ru] .pdf h2, [lang^=pl] .pdf h2, [lang^=hu] .pdf h2, [lang^=cs] .pdf h2, [lang^=et] .pdf h2, [lang^=lv] .pdf h2, [lang^=lt] .pdf h2 {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru .pdf h2 strong, .pl-pl .pdf h2 strong, .hu-hu .pdf h2 strong, .cs-cz .pdf h2 strong, .et-ee .pdf h2 strong, .lv-lv .pdf h2 strong, .lt-lt .pdf h2 strong, [lang^=ru] .pdf h2 strong, [lang^=pl] .pdf h2 strong, [lang^=hu] .pdf h2 strong, [lang^=cs] .pdf h2 strong, [lang^=et] .pdf h2 strong, [lang^=lv] .pdf h2 strong, [lang^=lt] .pdf h2 strong {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru .pdf h2 b, .pl-pl .pdf h2 b, .hu-hu .pdf h2 b, .cs-cz .pdf h2 b, .et-ee .pdf h2 b, .lv-lv .pdf h2 b, .lt-lt .pdf h2 b, [lang^=ru] .pdf h2 b, [lang^=pl] .pdf h2 b, [lang^=hu] .pdf h2 b, [lang^=cs] .pdf h2 b, [lang^=et] .pdf h2 b, [lang^=lv] .pdf h2 b, [lang^=lt] .pdf h2 b {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.pdf h3 {
  font-family: "Helvetica Neue LT W05_63 Md Ex", Helvetica, sans-serif;
  font-size: 12px;
}
.ru-ru .pdf h3, .pl-pl .pdf h3, .hu-hu .pdf h3, .cs-cz .pdf h3, .et-ee .pdf h3, .lv-lv .pdf h3, .lt-lt .pdf h3, [lang^=ru] .pdf h3, [lang^=pl] .pdf h3, [lang^=hu] .pdf h3, [lang^=cs] .pdf h3, [lang^=et] .pdf h3, [lang^=lv] .pdf h3, [lang^=lt] .pdf h3 {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru .pdf h3 strong, .pl-pl .pdf h3 strong, .hu-hu .pdf h3 strong, .cs-cz .pdf h3 strong, .et-ee .pdf h3 strong, .lv-lv .pdf h3 strong, .lt-lt .pdf h3 strong, [lang^=ru] .pdf h3 strong, [lang^=pl] .pdf h3 strong, [lang^=hu] .pdf h3 strong, [lang^=cs] .pdf h3 strong, [lang^=et] .pdf h3 strong, [lang^=lv] .pdf h3 strong, [lang^=lt] .pdf h3 strong {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.ru-ru .pdf h3 b, .pl-pl .pdf h3 b, .hu-hu .pdf h3 b, .cs-cz .pdf h3 b, .et-ee .pdf h3 b, .lv-lv .pdf h3 b, .lt-lt .pdf h3 b, [lang^=ru] .pdf h3 b, [lang^=pl] .pdf h3 b, [lang^=hu] .pdf h3 b, [lang^=cs] .pdf h3 b, [lang^=et] .pdf h3 b, [lang^=lv] .pdf h3 b, [lang^=lt] .pdf h3 b {
  font-weight: bold;
  font-family: "Arimo", sans-serif !important;
}
.pdf hr {
  height: 0.0508cm;
  background-color: #db011c;
  margin: 0.889cm 0;
  border: none;
  outline: none;
}
.pdf .page-break {
  page-break-after: always;
}
.pdf .pdf-wide {
  padding-left: 1.27cm;
  padding-right: 1.27cm;
}
.pdf .pdf-inner {
  padding-top: 1.27cm;
  padding-bottom: 1.27cm;
}
.pdf .pdf-inner-fixed {
  height: calc(100% - 2.921cm);
}
.pdf .pdf-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: #db011c;
  height: 2.921cm;
}
.pdf .pdf-header__logo {
  width: 3.175cm;
  align-self: center;
}