.an-import {
  height: 100vh;
  background-color: $brand-white;
  padding-bottom: 50px;
  overflow-y: auto;

  &__header {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
      margin: 0px;
      font-size: 14px;
      font-style: normal;
    }
  }

  &__inner {
    padding: 0px 15px;

    li {
      &:before {
        content: '- ';
        display: inline;
      }
    }
  }

  &__input {
    width: 100%;
    resize: none;
    height: 500px;
    border-radius: 4px;
    padding: 15px;
    overflow-y: auto;
    overflow-x: hidden;
    border: 1px solid $brand-grey--light;

    &.is-invalid {
      border: 1px solid $brand-primary;
    }
  }
}
