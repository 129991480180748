.wb-cell {
  position: relative;
  grid-column: span 1;
  grid-row: span 1;
  cursor: grab;
  border: 1px solid white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1;
  font-size: 8px;
  background-color: rgba($brand-primary, 0.4);
  transition: opacity 275ms ease;

  &--placeholder {
    background-color: rgba(60, 60, 60, 0.4);

    .wb-cell__an {
      color: black;
    }
  }

  &--bar {
    background-color: rgba(156, 119, 119, 0.2);
    transform-style: preserve-3d;
    perspective: 400px;

    > .wb-cell__bg {
      background-image: none;
    }

    &.level--0 {
      border: none;
    }

    &.level--1 {
      border: none;

      .grid__cells--absolute {
        transform: translateZ(15px);
        filter: drop-shadow(2px 4px 4px black);
      }
    }

    &.level--2 {
      border: none;

      .grid__cells--absolute {
        transform: translateZ(25px);
        filter: drop-shadow(2px 4px 6px black);
      }
    }
  }

  &__bg {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90%;
    height: 90%;
    transform: translate(-50%, -50%);
    background-image: url('~@img/svg/logo.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 0;

    img {
      object-fit: contain;
      object-position: center top;
      width: 100%;
      height: 100%;
    }
  }

  &__inner {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1 !important;
  }

  &__an {
    position: absolute;
    text-align: center;
    width: 100%;
    bottom: 6px;
    letter-spacing: 1.2px;
  }

  &__controls {
    position: absolute;
    left: 100%;
    top: 0px;
    z-index: 100;
    pointer-events: all;
    z-index: 1;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    &__1 {
      top: 20px;
    }

    &__2 {
      top: 52px;
    }
  }

  &__control {
    padding: 4px;
    background-color: #c5c5c5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &--red {
      background-color: $brand-primary;
    }
  }

  &__corners {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    span {
      position: absolute;
      background-image: url('~@img/svg/icon--corner.svg');

      width: 20px;
      height: 20px;

      &:nth-child(1) {
        top: 0;
        left: 0;
      }
      &:nth-child(2) {
        top: 0;
        right: 0;
        transform: rotate(90deg);
      }
      &:nth-child(3) {
        bottom: 0;
        right: 0;
        transform: rotate(180deg);
      }
      &:nth-child(4) {
        bottom: 0;
        left: 0;
        transform: rotate(-90deg);
      }
    }
  }

  &__tag {
    @include font-normal();
    color: $brand-white;
    font-size: 14px;
  }

  &--drop {
    cursor: grab;
    background-color: transparent;
  }

  &.is-selected::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: cyan;
    opacity: 0.2;
  }

  &.has-product-image {
    .wb-cell__bg {
      width: 100%;
      height: 100%;
    }
  }

  &.no-border {
    border: none;
  }

  &.no-bg {
    background: none;

    .wb-cell__bg {
      background-image: none;
    }
  }

  &.faded {
    opacity: 0.3;
    pointer-events: none;

    .grid__cells {
      opacity: 0;
    }
  }

  &.is-dragging {
  }
}

.wb-cell--drop {
  &.hover {
    // background-color: rgba($brand-grey, 0.8);
    background-color: rgba(70, 170, 218, 0.2);
  }
}

.wb-cell--selection {
  background: none;

  &:after {
    content: '';
    border: 2px solid cyan;
    position: absolute;
    top: 50%;
    left: 50%;
    width: calc(100% + (var(--grid-gap) * 1px) / 2);
    height: calc(100% + (var(--grid-gap) * 1px) / 2);
    transform: translate(-50%, -50%);
  }
}

.cell-bar-header {
  // 25px being the grid pitch
  height: calc(((var(--grid-pitch) * 1px) * 2));

  position: absolute;
  top: 0px;
  width: 100%;
  pointer-events: all;
  cursor: pointer;
  z-index: 3;

  &:before {
    content: '';
  }

  &__left,
  &__right {
    position: absolute;
    background-color: $brand-primary--dark;
    height: 100%;
    top: 0px;
    z-index: -1;
  }

  &__left {
    left: 0px;
    transform-origin: top left;

    .level--0 & {
      transform: translateZ(25px) rotateY(90deg);
      width: 16px;
    }

    .level--1 & {
      transform: translateZ(35px) rotateY(90deg);
      width: 26px;
    }

    .level--2 & {
      transform: translateZ(45px) rotateY(90deg);
      width: 36px;
    }
  }

  &__right {
    right: 0px;
    transform-origin: top right;

    .level--0 & {
      transform: translateZ(25px) rotateY(-90deg);
      width: 16px;
    }

    .level--1 & {
      transform: translateZ(35px) rotateY(-90deg);
      width: 26px;
    }

    .level--2 & {
      transform: translateZ(45px) rotateY(-90deg);
      width: 36px;
    }
  }

  &__main {
    height: 100%;
    background-color: $brand-primary;
    width: 100%;
    padding: 2px;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.5));

    .level--0 & {
      transform: translateZ(25px);
    }

    .level--1 & {
      transform: translateZ(35px);
      filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.75));
    }

    .level--2 & {
      transform: translateZ(45px);
      filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 1));
    }
  }

  .wb-cell__controls {
    margin-left: 20px;

    .level--0 & {
      left: 103.3%;
      top: -12px;
    }

    .level--1 & {
      left: 104.6%;
      top: -17px;
    }

    .level--2 & {
      left: 106.5%;
      top: -24px;
    }

    .icon {
      width: 20px;
      height: 20px;
      background-repeat: no-repeat;
      background-size: contain;
      margin: 0 auto;

      &--visibility {
        background-image: url('~@img/svg/icon--visibility_off.svg');

        &.active {
          background-image: url('~@img/svg/icon--visibility.svg');
        }
      }

      &--edit {
        background-image: url('~@img/svg/icon--edit--black.svg');
      }
    }
  }

  &__logo {
    max-height: 100%;
    width: 47px;
  }

  &__title {
    @include font-normal;
    font-size: 12px;
    color: $brand-white;
    text-align: center;
    width: 100%;
  }

  &__spacer {
    width: 47px;
  }
}
