.zoom-bar {
  position: fixed;

  bottom: 20px;
  width: 800px;
  height: 50px;
  background-color: #fff;
  box-shadow: 0 0 5px 1px rgb(0 0 0 / 40%);
  z-index: 1000;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;

  &__bar {
    position: absolute;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 1px;
    background-color: $brand-black;
    width: 90%;
  }

  &__thumb {
    display: block;
    position: absolute;
    height: 20px;
    width: 4px;
    background-color: $brand-black;
    transform: translate(-50%, -50%);
    left: 25%;
    top: 50%;
  }
}
