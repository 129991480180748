.pdf-scaleable-grid {
  transform-origin: 0 0;
  position: absolute;
  top: 50%;
  left: 50%;
  visibility: hidden;

  &.visible {
    visibility: visible;
  }
}

.pdf-grid {
  background-color: $brand-primary--dark;
  padding: 5px;
  border-radius: 4px;

  &__border {
    border-right: 4px dashed rgba($brand-black, 0.28);
    border-left: 4px dashed rgba($brand-black, 0.28);
  }

  &__border-inner {
    background-color: $brand-primary--dark;
    padding: 5px;
  }

  &__cells {
    background-color: $brand-primary;
    background-image: url('~@img/svg/dot.svg');
    background-size: calc(var(--grid-pitch) * 1px);
  }

  &__bar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .pdf-cell {
      background: none;
    }
  }
}
