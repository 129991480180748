$brand-white: #fff;
$brand-black: #000;

$brand-primary: #db011c;
$brand-primary--dark: #a90000;
$brand-grey: #e4e4e4;
$brand-grey--dark: #383739;
$brand-grey--darker: #231f20;
$brand-grey--medium: #818483;
$brand-grey--light: #b5b5b5;
$brand-grey--lightest: #f1f1f1;
$brand-grey--near-white: #f8f8f8;
$brand-green: #16c50b;

$navigation-width: 74px;
$navigation-logo-height: 74px;
$site-wide: 1525px;
$padding: 30px;
$easing: cubic-bezier(0.645, 0.045, 0.355, 1);

$screen-xs: 375px;
$screen-sm: 768px;
$screen-md: 996px;
$screen-xmd: 1200px;
$screen-lg: 1366px;
$screen-xl: 2560px;
