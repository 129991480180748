.btn {
  @include font-header();
  text-transform: uppercase;
  font-size: 12px;
  padding: 10px 20px;
  color: $brand-white;
  background-color: $brand-black;
  border-radius: 4px;
  letter-spacing: 0.7px;
  cursor: pointer;
  user-select: none;
  text-decoration: none;

  &[disabled] {
    background-color: grey;
    pointer-events: none;
  }

  &--inline {
    display: inline-flex;
    align-self: flex-start;
  }

  &--primary {
    background-color: $brand-primary;
  }

  &--grey {
    background-color: $brand-grey--light;

    &:active,
    &.active {
      background-color: $brand-grey--dark;
    }
  }

  &--download {
    padding-right: 46px;
    background-image: url('~@img/svg/download.svg');
    background-repeat: no-repeat;
    background-position: right 16px center;
  }

  &--link {
    text-decoration: none;
  }
}
