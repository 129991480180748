.number {
  &--hidden {
    opacity: 0;
    pointer-events: none;
  }

  &__wrapper {
    height: 38px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__inner {
    height: 100%;
    // background-color: ${brand.grey.fieldBackground};
    display: inline-flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  &__minus {
    width: 38px;
    height: 100%;
    outline: none;
    border: none;
    background: none;
    position: relative;
    padding: 0px;

    &:hover {
      // background-color: ${brand.grey['95']};
    }

    &:active {
      // background-color: ${brand.grey['90']};
    }

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 12px;
      height: 2px;
      background-color: $brand-black;
      transform: translate(-50%, -50%);
    }
  }

  &__plus {
    width: 38px;
    height: 100%;
    outline: none;
    border: none;
    background: none;
    position: relative;
    padding: 0px;

    &:hover {
      // background-color: ${brand.grey['95']};
    }

    &:active {
      // background-color: ${brand.grey['90']};
    }

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 12px;
      height: 2px;
      background-color: $brand-black;
      transform: translate(-50%, -50%);
    }

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 12px;
      height: 2px;
      background-color: $brand-black;
      transform: translate(-50%, -50%) rotate(90deg);
    }
  }

  &__value {
    @include font-sub-title();
    background: none;
    border: none;
    outline: none;
    font-size: 14px;
    width: 28px;
    text-align: center;
    -moz-appearance: textfield;

    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}
