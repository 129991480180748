@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@mixin fade-in($duration, $delay, $ease: ease) {
  opacity: 0;
  animation: fade-in $duration $delay forwards $ease;
}

@mixin scrollbar() {
  /* width */
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $brand-grey--light;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: $brand-grey--medium;
  }
}
