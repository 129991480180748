.close-subnav {
  width: 25px;
  padding-left: 5px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  &__icon {
    display: block;
    width: 100%;
    height: 25px;
    background-image: url('~img/svg/plus.svg');
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    transform: rotate(45deg);

    &--black {
      background-image: url('~img/svg/plus--black.svg');
    }

    &:hover {
      cursor: pointer;
    }
  }
}
