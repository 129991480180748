.populate-results {
  max-width: 500px;

  &__title {
    margin-bottom: 18px;
  }

  &__list {
    max-height: 500px;
    overflow: auto;
    margin-bottom: 18px;
  }

  &__buttons {
    gap: 12px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  textarea {
    display: none;
  }
}
