.product-info {
  position: fixed;
  bottom: 0;
  right: 0;
  border-top-left-radius: 3px;
  background-color: $brand-primary;
  padding: 16px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 315px;
  min-height: 80px;
  z-index: 10;
  transform: translateY(100%);
  transition: transform 275ms 0ms ease;

  &.active {
    transform: translateY(0%);
  }

  &__details {
    display: flex;
    flex-direction: column;
  }

  &__media {
    margin-left: auto;
    width: 100px;
    flex: 0 0 100px;
    margin-left: 12px;
    margin-right: 25px;

    img {
      width: 100px;
      height: 100px;
      object-fit: contain;
      border-radius: 3px;
    }
  }

  &__title {
    color: #fff;
    @include font-title();
    font-size: 14px;
    margin-bottom: 12px;
  }

  &__an {
    color: #fff;
    margin-top: auto;
    @include font-title();
    font-size: 12px;
  }

  &__close {
    position: absolute;
    background-image: url('~img/svg/plus.svg');
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    transform: rotate(45deg);
    height: 15px;
    width: 15px;
    right: 15px;

    &:hover {
      cursor: pointer;
    }
  }

  &__dimensions {
    @include font-title();
    color: #fff;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    margin-bottom: 12px;
  }
}
