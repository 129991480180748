.modal {
  position: fixed;
  z-index: 10001;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba($brand-black, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__close {
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 30px;
    background-color: $brand-primary;
    cursor: pointer;

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      background-image: url('~@img/svg/plus.svg');
      width: 18px;
      height: 18px;
      transform: translate(-50%, -50%) rotate(45deg);
    }
  }

  &__inner {
    background-color: $brand-white;
    border-radius: 4px;
    padding: 50px;
    min-width: 300px;
    max-height: 90vh;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;

    h5 {
      margin-bottom: 20px;
    }
  }
  &__min {
    min-width: 800px;
  }
}
