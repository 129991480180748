.grid-header {
  position: relative;
  z-index: 100;
  display: flex;
  align-items: center;
  border-bottom: 2px solid $brand-grey--light;
  height: 60px;
  max-width: calc(100% - (48px * 2));
  margin: 0 auto 60px auto;
  position: sticky;
  left: 48px;

  &__back {
    color: $brand-grey--darker;
    text-decoration: none;
    display: block;
    text-transform: uppercase;
    @include font-sub-title();
    font-size: 12px;

    &-arrow {
      width: 16px;
      height: 9px;
      margin: 0 10px 5px auto;
      background-image: url('~@img/svg/arrow--back.svg');
      display: inline-block;
      vertical-align: middle;
    }
  }

  &__inner {
    margin: 0;
    padding: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__edit-icon {
    width: 23px;
    height: 23px;
    background-image: url('~@img/svg/icon--edit.svg');
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    vertical-align: bottom;
    margin-right: 5px;
  }

  &__project-name {
    @include font-header-heavy();
    border: none;
    outline: none;
    background-color: transparent;
    font-size: 18px;
    display: inline-block;
    width: auto;
    max-width: 500px;
    min-width: auto;
    position: relative;

    &:focus {
      color: $brand-primary;
    }
  }

  &__status {
    @include font-sub-title();
    color: #707070;
    user-select: none;
  }
}

.grid-bar-header {
  height: 40px;
  background-color: $brand-primary;
  padding: 5px;
  position: relative;
  display: grid;
  grid-template-columns: 70px 1fr 70px;
  align-items: center;

  &__logo {
    display: block;
    height: 100%;
  }

  &__title {
    margin: 0;
    color: white;
    @include font-title;
    font-size: 22px;
    text-align: center;
  }

  &__close {
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    background-image: url('~@img/svg/plus.svg');
    background-repeat: no-repeat;
    background-size: contain;
    transform: rotate(45deg);
    width: 20px;
    height: 20px;
    z-index: 10;
  }
}
