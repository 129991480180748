.passport-btn {
  @include font-sub-title();
  text-transform: uppercase;
  font-size: 16px;
  padding: 14px 20px;
  color: $brand-white;
  background-color: $brand-primary;
  display: block;
  width: 100%;
  text-decoration: none;

  .tabs__create & {
    font-size: 14px;
    padding: 8px 15px;
  }

  &--link {
    @include font-normal();
    font-style: normal;
    font-weight: normal;
    color: $brand-primary;
    margin-left: 5px;
    padding: 0;
    font-size: 14px;
    background-color: transparent;
    text-decoration: underline;
    text-transform: none;
    display: inline;
    width: auto;
    margin: 0;
  }

  &--inline {
    display: inline-block;
    width: auto;
  }

  &--black {
    background-color: $brand-black;
  }

  &--dark-grey {
    background-color: $brand-grey--dark;
  }

  &--grey {
    background-color: $brand-grey--light;

    &:active,
    &.active {
      background-color: $brand-primary;
    }
  }

  &--disabled {
    cursor: not-allowed;
    background-color: $brand-grey--light;

    &:active,
    &.active {
      background-color: $brand-grey--light;
    }
  }
}
