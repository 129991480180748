// Preferred Headline
@mixin font-header() {
  font-family: 'Helvetica Neue LT W01_93 BlkEx', Helvetica, sans-serif;
  @include culture-fallbacks();
}

// Preferred Sub-Headline
@mixin font-title() {
  font-family: 'Helvetica Neue LT W05_83 Hv Ex', Helvetica, sans-serif;
  @include culture-fallbacks();
}

@mixin font-sub-title() {
  font-family: 'Helvetica Neue LT W05_83 Hv Ex', Helvetica, sans-serif;
  @include culture-fallbacks();
}

@mixin font-63-medium-extended() {
  font-family: 'Helvetica Neue LT W05_63 Md Ex', sans-serif;
  font-style: normal;
  font-weight: normal;
  @include culture-fallbacks();
}

@mixin font-53-extended() {
  font-family: 'Helvetica Neue LT W05_53 Ext', sans-serif;
  font-stretch: semi-expanded;
  font-style: normal;
  font-weight: 400;
}

// Preferred Small Body
@mixin font-normal() {
  font-family: 'Helvetica Neue LT W01_55 Roman', Helvetica, sans-serif;
  @include culture-fallbacks();
}

@mixin culture-fallbacks() {
  .ru-ru &,
  .pl-pl &,
  .hu-hu &,
  .cs-cz &,
  .et-ee &,
  .lv-lv &,
  .lt-lt &,
  [lang^='ru'] &,
  [lang^='pl'] &,
  [lang^='hu'] &,
  [lang^='cs'] &,
  [lang^='et'] &,
  [lang^='lv'] &,
  [lang^='lt'] & {
    font-weight: bold;
    font-family: 'Arimo', sans-serif !important;

    strong {
      font-weight: bold;
      font-family: 'Arimo', sans-serif !important;
    }
    b {
      font-weight: bold;
      font-family: 'Arimo', sans-serif !important;
    }
  }
}

.passport {
  h1 {
    @include font-header();
    text-transform: uppercase;
    font-size: 26px;
    font-style: italic;
    margin: 0px 0px 20px 0px;
  }

  h2 {
    @include font-title();
    text-transform: uppercase;
    font-size: 16px;
    margin: 0px 0px 20px 0px;
  }

  h3,
  h4,
  h5 {
    @include font-sub-title();
    font-size: 15px;
    margin: 0px 0px 20px 0px;
    text-transform: uppercase;
  }

  p {
    margin: 0px 0px 20px 0px;
  }

  p,
  body {
    @include font-normal();
    font-size: 14px;
  }

  .align--left {
    text-align: left;
  }

  .align--right {
    text-align: right;
  }

  .align--center {
    text-align: center;
  }
}
