@import './functions';
@import './inventory';
@import './product';

.a4 {
  background-color: $brand-white;
  margin: 0 auto;
  width: 21cm;
  height: 29.7cm;
  position: relative;

  &--break-after {
    page-break-after: always;
  }

  &--break-before {
    page-break-before: always;
  }

  &--break-avoid {
    page-break-inside: avoid;
  }

  &--min {
    height: auto;
    min-height: 29.7cm;
  }
}

.pdf-iframe {
  position: fixed;
  left: 2000px;
  width: 100vw;
  height: 100vh;
}

.pdf {
  h1 {
    @include font-header();
    font-size: 16px;
    text-transform: uppercase;
    margin-bottom: cm(0.3);
    font-style: normal;
  }

  h2 {
    @include font-header();
    font-size: 12px;
    text-transform: uppercase;
    margin-bottom: cm(0.3);
  }

  h3 {
    @include font-title();
    font-size: 12px;
  }

  hr {
    height: cm(0.02);
    background-color: $brand-primary;
    margin: cm(0.35) 0;
    border: none;
    outline: none;
  }

  .page-break {
    page-break-after: always;
  }

  .pdf-wide {
    padding-left: cm(0.5);
    padding-right: cm(0.5);
  }

  .pdf-inner {
    padding-top: cm(0.5);
    padding-bottom: cm(0.5);
  }

  .pdf-inner-fixed {
    height: calc(100% - #{cm(1.15)});
  }

  .pdf-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    background-color: $brand-primary;
    height: cm(1.15);

    &__logo {
      width: cm(1.25);
      align-self: center;
    }
  }
}
