/** 400-Regular */

/* latin */
@font-face {
  font-family: 'Andika';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Andika'), url('~@fonts/andika/latin/andika-v10-subset.woff2') format('woff2'),
    url('~@fonts/andika/latin/andika-v10-subset.woff') format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074,
    U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

/* latin-ext */
@font-face {
  font-family: 'Andika';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Andika'), url('~@fonts/andika/latin-ext/andika-v10-subset.woff2') format('woff2'),
    url('~@fonts/andika/latin-ext/andika-v10-subset.woff') format('woff');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}

/* cyrillic */
@font-face {
  font-family: 'Andika';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Andika'), url('~@fonts/andika/cyrillic/andika-v10-subset.woff2') format('woff2'),
    url('~@fonts/andika/cyrillic/andika-v10-subset.woff') format('woff');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Andika';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Andika'), url('~@fonts/andika/cyrillic-ext/andika-v10-subset.woff2') format('woff2'),
    url('~@fonts/andika/cyrillic-ext/andika-v10-subset.woff') format('woff');
  unicode-range: U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F;
}

/* vietnamese */
@font-face {
  font-family: 'Andika';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Andika'), url('~@fonts/andika/vietnamese/andika-v10-subset.woff2') format('woff2'),
    url('~@fonts/andika/vietnamese/andika-v10-subset.woff') format('woff');
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB;
}
