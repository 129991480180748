.legacy-warning {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  &__inner {
    position: relative;
    z-index: 1;
    text-align: center;
    padding: 20px;
  }

  a {
    color: $brand-primary;
  }
}
