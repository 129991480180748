.passport-form {
  width: 100%;
  margin: 0 auto;
  position: relative;
  min-width: 300px;

  @media (min-width: $screen-sm) {
    width: 475px;
    min-width: auto;
  }

  &__close {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 0;
    right: 0;
    color: $brand-white;
    background-color: $brand-primary;
    z-index: 1;
    cursor: pointer;
    user-select: none;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__inner {
    padding: 30px;
    background-color: $brand-white;

    &--confirmation {
      border-bottom: 4px solid $brand-primary;
    }

    .confirmation {
      text-align: center;

      &__title {
        margin-top: 10px;
      }

      &__text {
        padding: 0 30px;

        @media (min-width: $screen-sm) {
          padding: 0 60px;
        }
      }
    }
  }

  &__row {
    width: 100%;
    position: relative;
    display: inline-block;
    margin-top: 20px;
    margin-bottom: 10px;

    p.error {
      margin-top: 15px;
    }
  }

  &__label {
    position: absolute;
    left: 20px;
    top: 0;
    display: block;
    margin: 0;
    font-size: 11px;
    line-height: 20px;
    opacity: 1;
    transform: translateY(-100%);
    transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
      transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    z-index: 1;
    user-select: none;

    .passport-form__control:not([type='checkbox']):not([type='radio']):placeholder-shown + & {
      opacity: 0;
      transform: translateY(0);
    }

    .passport-form__control:not([type='checkbox']):not([type='radio']):not(:placeholder-shown) + & {
      opacity: 1;
      transform: translateY(-100%);
    }
  }

  &__control {
    width: 100%;
    position: relative;
    display: block;
    height: 40px;
    padding: 10px 20px 9px;
    border: 0;
    border-bottom: 2px solid #231f20;
    background: $brand-white;
    box-shadow: none;
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 20px;
    outline: 0;
    transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    z-index: 2;

    & &:focus {
      background: mix(#231f20, $brand-white, 2%);
      box-shadow: none;
      outline: 0;
    }

    .invalid & {
      border-bottom-color: $brand-primary;

      &::placeholder {
        color: $brand-primary;
      }
    }

    input[type='checkbox'] &,
    input[type='radio'] & {
      display: none;
    }

    .passport-form__select-wrapper {
      position: relative;

      &:after {
        content: '';
        right: 10px;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }

    select & {
      // reset select appearance to that of a text box (hides the default arrow)
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }

    textarea & {
      height: auto;
      padding: 10px 19px 9px;
      border: 1px solid fadeout(#323232, 50%);
      border-radius: 5px;
      min-height: 120px;

      .invalid & {
        border-color: $brand-primary;
      }
    }

    input[type='text']:read-only & {
      cursor: not-allowed;
      user-select: none;
      color: fade($brand-black, 50%);
    }
  }

  &__dropdown {
    cursor: pointer;
    z-index: 3;

    &-values {
      display: none;
      background-color: mix(#231f20, $brand-white, 2%);
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      z-index: 2;
      max-height: 280px;
      overflow-y: auto;
    }

    &-selected {
      position: relative;
      z-index: 1;

      .invalid & {
        color: $brand-primary;
      }

      &:after {
        content: '';
        z-index: 2;
        top: 50%;
        right: 0px;
        width: 12px;
        height: 6px;
        position: absolute;
        // Dropdown arrow
        background-image: url('data:image/svg;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSI2IiB2aWV3Qm94PSIwIDAgMTIgNiI+PHBhdGggZD0iTTAsMEgxMkw2LDZaIi8+PC9zdmc+');
        background-size: contain;
        background-repeat: no-repeat;
      }
    }

    &-value {
      padding: 10px 20px 9px;
      user-select: none;

      &:hover {
        background-color: mix(#231f20, $brand-white, 5%);
      }
    }

    &.active {
      background-color: mix(#231f20, $brand-white, 2%);
      z-index: 5;

      .passport-form__dropdown-values {
        display: block;
      }
    }
  }

  &__checkbox {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    cursor: pointer;
    user-select: none;

    &.active:before {
      // Checked
      background-image: url('data:image/svg;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMCIgaGVpZ2h0PSIxMCIgdmlld0JveD0iMCAwIDEwIDEwIj48cGF0aCBkPSJNMi43MzQsMy45MzIsNC40NTMsNS42NTEsOS4yMTkuODg1LDEwLDEuNjY3LDQuNDUzLDcuMjE0bC0yLjUtMi41Wk04Ljg4LDguODhWNC40NTNIMTBWOC44OEExLjE1OCwxLjE1OCwwLDAsMSw4Ljg4LDEwSDEuMTJhMS4wNzMsMS4wNzMsMCwwLDEtLjc4MS0uMzM5QTEuMDczLDEuMDczLDAsMCwxLDAsOC44OFYxLjEyQTEuMDczLDEuMDczLDAsMCwxLC4zMzkuMzM5LDEuMDczLDEuMDczLDAsMCwxLDEuMTIsMEg2LjY2N1YxLjEySDEuMTJWOC44OFpNMTAsMTAiIGZpbGw9IiMzMjMyMzIiLz48L3N2Zz4=');
    }

    &:before {
      content: '';
      position: relative;
      width: 20px;
      height: 20px;
      margin-right: 10px;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      // Unchecked
      background-image: url('data:image/svg;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMCIgaGVpZ2h0PSIxMCIgdmlld0JveD0iMCAwIDEwIDEwIj48ZyBmaWxsPSJub25lIiBzdHJva2U9IiMzMjMyMzIiIHN0cm9rZS13aWR0aD0iMSI+PHJlY3Qgd2lkdGg9IjEwIiBoZWlnaHQ9IjEwIiByeD0iMiIgc3Ryb2tlPSJub25lIi8+PHJlY3QgeD0iMC41IiB5PSIwLjUiIHdpZHRoPSI5IiBoZWlnaHQ9IjkiIHJ4PSIxLjUiIGZpbGw9Im5vbmUiLz48L2c+PC9zdmc+');
    }
  }

  &__forgotten-password {
    @include font-normal();
    margin: 5px 0;
    font-size: 13px;

    a {
      @include font-63-medium-extended();
      color: $brand-primary;
      margin-left: 5px;
    }
  }
}
