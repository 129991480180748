$brand-white: #fff;
$brand-black: #000;

$brand-primary: #db011c;
$brand-primary--dark: #a90000;
$brand-grey: #e4e4e4;
$brand-grey--dark: #383739;
$brand-grey--darker: #231f20;
$brand-grey--medium: #818483;
$brand-grey--light: #b5b5b5;
$brand-grey--near-white: #f8f8f8;

$navigation-width: 200px;
$navigation-logo-height: 60px;
$site-wide: 1525px;
